import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Container } from '@mui/material';
import api from '../../../api/api';
import Table from '../../../components/Table';
import PageTitle from '../../../components/PageTitle';
import { columns as submittedColumns } from './submitted/columns';
import { columns as incompleteColumns } from './incomplete/columns'
import TabPanel from './tabPanel';
import NotFound from './notFound';

interface IntApplications {
	id: number
}

interface IntTabs {
	label: string
	id: string
}

const applicationTabs: Array<IntTabs> = [
	{
		label: 'Submitted Applications',
		id: 'submittedApplications'
	},
	{
		label: 'Incomplete Applications',
		id: 'incompleteApplications'
	}
]

const Applications = () => {
	const [isLoading, setLoading] = React.useState<boolean>(true);
	const [submittedApplications, setSubmittedApplications] = useState<IntApplications[]>([]);
	const [draftApplications, setDraftApplications] = useState<IntApplications[]>([]);
	const [value, setValue] = useState<number>(0);

	const groupRequests = (data: any) => {
		let list = [...data];
		list.sort((o1, o2) => {
			return o1 < o2 ? -1 : 1;
		})
		let listMap = new Map();
		list.forEach((item) => {
			if (!listMap.has(item.requestId)) {
				item.forms = [];
				listMap.set(item.requestId, item);
			}
			listMap.get(item.requestId).forms.push(item);
		});

		return [...listMap.values()];
	}

	const fetchSubmittedApplications = async () => {
		try {
			setLoading(true);
			const { data } = await api.get('applications/submitted');

			setSubmittedApplications(groupRequests(data));
		}
		catch (err) {
			console.log({ err });
		}
		finally {
			setLoading(false);
		}
	};

	const fetchDraftApplications = async () => {
		try {
			setLoading(true);
			const { data } = await api.get(`applications/draft`, {
				data: {}
			});

			setDraftApplications(data);
		}
		catch (err) {
			console.log({ err });
		}
		finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchSubmittedApplications();
	}, []);

	useEffect(() => {
		fetchDraftApplications();
	}, []);

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Container>
			<PageTitle>Applications</PageTitle>
			<Tabs value={value} onChange={handleChange} aria-label="Applications" sx={{ borderBottom: 1, borderColor: 'divider' }}>
				{applicationTabs.map((tab) => {
					return <Tab key={tab.id} label={tab.label} id={tab.id} sx={{ textTransform: 'none', fontSize: '16px' }} />
				})}
			</Tabs>
			<TabPanel value={value} index={0}>
				<Table data={submittedApplications} columns={submittedColumns} loading={isLoading} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Table data={draftApplications} columns={incompleteColumns} loading={isLoading} />
			</TabPanel>
		</Container>
	)
}

export default Applications;