import {Link} from "@mui/material";
import config from "../../../../config";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React from "react";
import api from "../../../../api/api";

const DownloadLink = (props: { id: number }) => {
	const getPdf = async () => {
		let response:any = await api.get(`/forminstances/${props.id}/pdf`, {
			responseType: 'blob'
		});
		let a = document.createElement("a");
		a.setAttribute("target", "_blank");
		var file = new Blob([response.data], {type: 'application/pdf'});
		var fileURL = URL.createObjectURL(file);
		a.href = fileURL;
		a.download = 'application.pdf';
		a.click();
	}

	return (
  <Link
    underline="none"
    component="button"
    variant="body2"
    onClick={getPdf}
    sx={{display: 'flex', alignItems: 'center'}}
  >
    <PictureAsPdfIcon fontSize="small" sx={{mr:1}} />
    <span>Download Application</span>
  </Link>
)
};

export default DownloadLink;