import {Box, Button, Popover, Typography} from "@mui/material";
import Table from "../../../../../components/Table";
import {formColumns} from "./columns";
import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface IFormsProps {
  forms: []
}

const Forms = (props: IFormsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button variant="text" onClick={handleClick} endIcon={!!anchorEl ? <ArrowDropUpIcon sx={{marginLeft: '0px'}} /> : <ArrowDropDownIcon sx={{marginLeft: '0px'}} />} {...props}>
        Multiple Forms
      </Button>

      <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box sx={{minWidth: '500px', minHeight: '75px', display: 'flex', alignItems: 'center'}}>
        <Table data={props.forms} columns={formColumns} />
      </Box>
    </Popover>
    </>
  )
}

export default Forms;