import {Authenticator, Text, ThemeProvider, useAuthenticator, useTheme, View} from "@aws-amplify/ui-react";
import loginTheme from "../../components/Login/loginTheme";
import loginFormFields from "../../components/Login/loginFormFields";
import React, {useEffect, useState} from "react";
import {Box, Container, Link} from "@mui/material";
import SignUp from "../../components/Login/signUp";
import axios from "axios";
import config from "../../config";
import {Auth} from "aws-amplify";
import {useLocation, useNavigate} from "react-router";
import useQueryParams from "../../hooks/useQuery";
import Logo from "../../assets/gp-logo.png";

type IInvite = {
	id: number,
	key: string,
	first: string,
	last: string,
	address: string,
}

const Invite = () => {
	const { route } = useAuthenticator((context) => [context.route]);
	const location = useLocation();
	const navigate = useNavigate();

	const [busy, setBusy] = useState(true);
	const [invite, setInvite] = useState<IInvite>();

	let { key } = useQueryParams();

	const _fetchInvite = async () => {
		try {
			let invite = await axios.get(`users/invite?key=${key}`, {
				baseURL: config.apiHost,
				headers: {
					'Accept': 'application/vnd.api+json',
					'Content-Type': 'application/vnd.api+json'
				}
			});
			setInvite(invite.data);
			setBusy(false);
		} catch (err) {
			console.debug("invite error", err);
		}
	}

	useEffect(() => {
		_fetchInvite();
	}, []);

	useEffect(() => {
		if (route === 'authenticated') {
			navigate("/home");
		}
	}, [route, navigate]);

	const inviteComponents = {
		Header() {
			return (
				<Box textAlign="left" mb={2}>
					<img src={Logo} style={{maxHeight: 80}}/>
				</Box>
			);
		},
		Footer() {
			const {tokens} = useTheme();

			return (
				<View textAlign="center" padding={tokens.space.large}>
					<Text color={tokens.colors.white}>
						&copy; All Rights Reserved
					</Text>
				</View>
			);
		},
		SignUp: {
			Header() {
				const { toSignIn } = useAuthenticator()
				return (
					<Box mx={5} lineHeight="40px">
						<h1>You have been invited to register for a free GivingHub account.</h1>
					</Box>
				)
			},
			FormFields() {
				return <SignUp invite={invite}/>
			}
		}
	}

	const inviteServices = {
		handleSignUp: async (formData:any) => {
			let { username, password, attributes } = formData;
			username = invite?.address.toLowerCase();
			attributes.email = invite?.address.toLowerCase(); // use the email from the invite, avoiding any form manipulation shenanigans

			let response;
			try {
				response = await Auth.signUp({username, password, attributes, autoSignIn: {enabled: true}});
				// add user to Giving Hub database
				await addInvitedUser(attributes, response.userSub);

				return Promise.resolve(response);
			} catch (reason) {
				return Promise.reject(reason);
			}
		},
	}

	const addInvitedUser = async (attributes: any, cognitoUsername: string) => {

		let head = {
			baseURL: config.apiHost,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		}
		let payload = {
			cognitoUsername,
			key: invite?.key,
			first: attributes.given_name,
			last: attributes.family_name,
			address: invite?.address,
			phone: attributes.phone_number
		}

		try {
			return await axios.post('users/invite/accept', payload, head);
		} catch (reason) {
			return Promise.reject(reason);
		}
	}

	return (
		<ThemeProvider theme={loginTheme}>
			{busy ? (
				<></>
			) : (
				<Container className="invite-wrapper" style={{width: "610px"}}>
					<Authenticator initialState="signUp" components={inviteComponents} formFields={loginFormFields} services={inviteServices}></Authenticator>
				</Container>
			)}
		</ThemeProvider>
	);
}

export default Invite;