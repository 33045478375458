const globals = {
    styleOverrides: {
        root: {
            fontSize: 20,
            fontFamily: ['Source Sans Pro, Helvetica, Arial, sans-serif'].join(', '),
            '& h1, & h2, & h3, & h4, & h5': {
                fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(', ')
            },
            '& strong, & b': { fontWeight: 600 }
        }
    }
}

export default globals;