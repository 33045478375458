import { Pagination, styled, TableRow, TableHead, TableCell } from "@mui/material";

export const StyledTableHead = styled(TableHead)`
  .MuiTableCell-head {
    font-weight: bold;
    border-right: 1px solid #fff;
    cursor: Pointer;
    background-color: #d8dce0;

    &:hover {
      background-color: #b2b8c2;
    }

    &:first-child{
      border-radius: 8px 0px 0px 0px;
    }

    &:last-child{
    border-radius: 0px 8px 0px 0px;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
&:nth-of-type(odd) {
    background-color: #f1f1f1;
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }
  :hover {
    background-color: #d9d9d9;
  }
`;

export const StyledTableCell = styled(TableCell)`
padding: 5px 16px;
`;




export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
