import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material';

const ProgressSpinner_root = styled('div')(() => ({
	flex: 1,
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}))

const ProgressSpinner = () => {
	return (
		<ProgressSpinner_root>
			<CircularProgress />
		</ProgressSpinner_root>
	)
}

export default ProgressSpinner;
