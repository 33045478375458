import {
	Box, Button,
	Container,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	TextField
} from "@mui/material";
import Logo from '../../assets/gp-logo.png';
import ProtectedRoute from "../../components/ProtectedRoute";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import useQueryParams from "../../hooks/useQuery";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Session from "../../models/data/Session";
import api from "../../api/api";
import { clearStoredSession, getStoredSessionKey } from "../../util/storage";

interface InviteModel {
	data : {
	'first-name': string
	'last-name': string
	'organization-name': string
	'tax-id': string
	}
}

const schema = yup.object({
	accountType: yup.number(),
	first: yup.string()
		.required()
		.max(255),
	last: yup.string()
		.required()
		.max(255),
	phone: yup.string()
		.required(),
	email: yup.string()
		.required(),
	organization: yup.string()
		.max(255),
	taxId: yup.string()
		.matches(/(0[1-9])|([1-9][0-9])-?[0-9]{7}/, { message: 'Tax ID must be exactly 9 digits, and cannot start with 00', excludeEmptyString: true })
})
type IFormInputs = yup.InferType<typeof schema>;

const Register = () => {
	const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		mode: 'onTouched'
	});
	const { getSession } = useContext(AuthContext);
	const navigate = useNavigate();

	const { defId } = useQueryParams();

	const handleSubmitForm = async (data: IFormInputs) => {
		try {
			let attributes = {
				accountType: data.accountType,
				contact: {
					first: data.first,
					last: data.last,
					phones: [{
						number: data.phone,
						type: 1 // mobile
					}],
					emails: [{
						address: data.email,
						type: 1 // primary
					}],
				}
			}

			// add organization attributes only if account type is 1 (organization)
			if (data.accountType === 1) {
				attributes = Object.assign(attributes, {
					organization: {
						name: data.organization,
						'tax-id': data.taxId ? data.taxId.replaceAll(/[^0-9]*/g, '') : undefined // tax ID should only contain numbers
					}
				});
			}

			// put the attributes in a JsonAPI response
			let payload = {
				data: {
					type: 'user',
					attributes
				}
			}

			await api.post('users', payload);
			clearStoredSession(true);
			await getSession()

			if (defId) {
				navigate(`/form?defId=${defId}`);
			} else {
				navigate('/home');
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		(async () => {
			try{
				const sess:Session = await getSession();
				if (sess.authId) {
					if (defId) {
						navigate(`/form?defId=${defId}`);
					} else {
						navigate('/home');
					}
				} else {
					const response = await api.get(`invite-status?email=${sess.username}`, {method: "GET"})
					setValue('phone', sess.phone || '');
					setValue('email', sess.username || '');
					if (response.data) {
						let invite = response.data;
						setValue('first', invite['first-name']);
						setValue('last', invite['last-name']);
						setValue('organization', invite['organization-name']);
						setValue('taxId', invite['tax-id']);
					}
					setValue('accountType', 1); // default to organization
				}
			} catch(error){
				console.log(error);
			}
		})()
	}, [setValue]);

	type InputProps = {
		name: "first" | "last" | "phone" | "email" | "organization" | "taxId",
		label: string,
		required?: boolean,
		disabled?: boolean
	};

	const Input = ({ name, label, required, disabled }: InputProps) => {
		let err: FieldError | undefined = errors[name];

		return (
			<Stack>
				<FormLabel required={required} error={!!err}>{label}</FormLabel>
				<Controller
					name={name}
					control={control}
					render={({ field }) =>
						<TextField
							{...field}
							fullWidth
							required={required}
							disabled={disabled}
							error={!!err}
							helperText={err?.message}
						/>
					} />
			</Stack>
		)
	};

	return (
		<ProtectedRoute>
			<Container sx={{ textAlign: "center" }}>
				<Box>
					<img style={{ height: 50 }} src={Logo} alt="logo" />
				</Box>
				<Box>
					<h1>Create your account to submit applications.</h1>
					<h3>The information you provide may be found and updated in your profile.</h3>
				</Box>
				<form onSubmit={handleSubmit(handleSubmitForm)}>
					<Container maxWidth="sm" sx={{ textAlign: "left" }}>
						<h2>Please select one. I'll be submitting applications...</h2>
						<RadioGroup
							defaultValue={1}
							row
							name="account-type">
							<FormControlLabel value="1" control={<Radio />} label="For An Organization" onClick={() => setValue('accountType', 1)} />
							<FormControlLabel value="2" control={<Radio />} label="For Myself" onClick={() => setValue('accountType', 2)} />
						</RadioGroup>

						<h2>Personal Info</h2>

						<Stack spacing={2}>
							<Input name="first" label="First Name" required />
							<Input name="last" label="Last Name" required />
							<Input name="phone" label="Phone" required disabled />
							<Input name="email" label="Email Address" required disabled />
						</Stack>

						{watch('accountType') === 1 ? (
							<div>
								<h2>Organization Info</h2>

								<Stack spacing={2}>
									<Input name="organization" label="Organization Name" />
									<Stack>
										<FormLabel error={!!errors.taxId}>Tax ID / EIN</FormLabel>
										<Controller
											name='taxId'
											control={control}
											render={({ field }) =>
												<InputMask
													value={field.value}
													onChange={field.onChange}
													mask="99-9999999">
													<TextField
														value={field.value}
														error={!!errors.taxId}
														helperText={errors.taxId?.message}
													/>
												</InputMask>
											} />
									</Stack>
								</Stack>
							</div>
						) : ''}
					</Container>

					<Box sx={{ m: 4 }}>
						<Button type="submit" variant="contained">Create Account and Start Application</Button>
					</Box>
				</form>
			</Container>
		</ProtectedRoute>
	)
}

export default Register;