import { ColumnDef, flexRender,  } from "@tanstack/react-table";
import GrantStatusCell from "./grantStatusCell";
import { GrantStatuses } from "./grantStatuses";
import { currencyFormate } from "../../../../../components/CurrencyFormater";

export const grantColumns: ColumnDef<any, any>[] = [
  {
    id: "amount",
    accessorKey: "grant-amount",
    header: "Amount",
    accessorFn: (originalRow:any) => currencyFormate(originalRow.grantAmount)

  },
  {
    id: "status",
    accessorKey: "grant-status",
    header: "Status",
    cell: ({row}) => flexRender(<GrantStatusCell status={row.original.grantStatus}> {GrantStatuses[row.original.grantStatus]} </GrantStatusCell>, {row})
  },
  {
    id: "dateSubmitted",
    accessorKey: "date-submitted",
    header: "Date Submitted",
    accessorFn: (originalRow:any) => originalRow.dateSubmitted
  }
 
];