import { ColumnDef, flexRender,  } from "@tanstack/react-table";
import DownloadLink from "../downloadLink";
import OpenLink from "../openLink";
import FormStatusCell from "./formStatusCell";

export const formColumns: ColumnDef<any, any>[] = [
  {
    id: "formName",
    accessorKey: "formName",
    header: "Form Name",
    accessorFn: (originalRow:any) => originalRow.formName
  },
  {
    id: "formStatus",
    accessorKey: "formStatus",
    header: "Form Status",
    cell: ({row}) => flexRender(<FormStatusCell status={row.original.formStatus}/>, {row})
  },
  {
    id: "formDateSubmitted",
    accessorKey: "formDateSubmitted",
    header: "Date Submitted",
    accessorFn: (originalRow:any) => originalRow.formDateSubmitted
  },
  {
    header: "Actions",
    cell: ({row}) => {
      if (row.original.formStatus === 1) {
        return flexRender(<DownloadLink id={row.original.id}/>, {row})
      } else {
        return flexRender(<OpenLink id={row.original.id} accessUrl={row.original.accessUrl}/>, {row})
      }
    }
  }
 
];