import React, { Fragment, useState, useEffect, useContext } from 'react'
import Table from '../../../../components/Table';
import getColumns from './columns';
import { Box, Button, Typography } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import api from '../../../../api/api';
import InviteMemberDialog from '../inviteMemberDialog';
import ConfirmationDialog from '../memberConfirmationDialog';
import {getStoredSession} from "../../../../util/storage";

interface Props {
	// accountType: number | null | undefined
	// userRoleType: number | null | undefined
	setAlertMessage: React.Dispatch<React.SetStateAction<string>>
 }

 type MemberIfo = {
	id: string
	type: string
	firstname: string
	lastname: string
	inviteDate: string
	email: string
	status: string
  }

const InvitedMembers = (props: Props) => {
	const session = getStoredSession();
	const username = session.username;
	const [isLoading, setLoading] = React.useState<boolean>(true);
	const [members, setMembers] = useState<any>([]);
	const [openInviteDialog, setOpenInviteDialog] = useState<boolean>(false)
	const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false)
	const [userStatus, setUserStatus] = useState<string>()
	const [address, setAddress] = useState<string | undefined>()
	const [user, setUser] = useState<string | undefined>()
	
	const fetchMembers = async () => {
		try {
			setLoading(true);
			const { data } = await api.get('users');
			setMembers(data);
		}
		catch (err) {
			console.log({ err });
		}
		finally {
			setLoading(false);
		}
	};

	const handleShowModal = () => {
		setOpenInviteDialog(true);
	}

	useEffect(() => {
		fetchMembers();
	}, [openInviteDialog])

	const columns = getColumns({fetchMembers, setOpenConfirmDialog, setUserStatus, setAddress, username, setUser})
	const visibleColumns = (session?.userRoleType === 2 || session?.accountType === 2) ? columns.slice(0, columns.length - 1) : columns;
	return (
		<Fragment>
			<Box display='flex' justifyContent='space-between' my={2}>
				<Typography variant='h3' sx={{ fontWeight: 600 }} display='flex' alignItems='center'>
					<GroupsIcon fontSize='large' sx={{ marginRight: '5px' }} />
					Members
				</Typography>
				{session?.accountType === 1 &&
					<Button variant="contained" onClick={handleShowModal}>Invite Member</Button>
				}
			</Box>
			<Table data={members} columns={visibleColumns} loading={isLoading} />
			<InviteMemberDialog open={openInviteDialog} setOpen={setOpenInviteDialog} setAlertMessage={props.setAlertMessage} />
			<ConfirmationDialog 
				confirmDialog={openConfirmDialog}
				setConfirmDialog={setOpenConfirmDialog}
				status={userStatus}
				address={address}
				user={user}
				onFetch={fetchMembers}
				setAlertMessage={props.setAlertMessage}
			/>

		</Fragment>
	)
}

export default InvitedMembers;