import { AxiosRequestConfig } from 'axios';
// import { getStoredSessionKey } from '../../util/storage';
import {Auth} from "aws-amplify";

const request = async (config: AxiosRequestConfig) => {
  let secure = config.headers?.key !== false;

	if (!!config.headers) {
		if (secure) {
      let user = await Auth.currentAuthenticatedUser();
      let token = user.getSignInUserSession().getAccessToken().getJwtToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

			/*let key = getStoredSessionKey();
			// only include key if there's a valid key in the current session
			// public requests shouldn't include it.
			if (key && key !== 'invalid_session') {
				config.headers.key = key;
			}*/
		} else {
			delete config.headers.Authorization;
			// delete config.headers.key;
		}
	}
	return config;
};

export default request;
