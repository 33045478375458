import { ColumnDef, flexRender,  } from "@tanstack/react-table";
import ActionCell from "./actionCell";
import ApplicationStatusCell from "./applicationStatusCell";
import GrantsCell from "./grants";
import { GrantReqStatuses } from "../grantRequestStatuses";
import { currencyFormate } from "../../../../components/CurrencyFormater";
import { Typography } from "@mui/material";

export const columns: ColumnDef<any, any>[] = [
  {
    id: "foundationName",
    header: "Grantor",
    accessorFn: (originalRow:any) => originalRow.foundationName

  },
  {
    id: "requestTitle",
    header: "Application Title",
    accessorFn: (originalRow:any) => originalRow.requestTitle
  },
  {
    id: "requestId",
    header: "Application Id",
    accessorFn: (originalRow:any) => originalRow.requestId
  },
  {
    id: "requestDate",
    header: "Date Submitted",
    accessorFn: (originalRow:any) => originalRow.requestDate
  },
  {
    id: "requestStatus",
    accessorKey: "requestStatus",
    header: "Application Status",
    cell: ({row}) => flexRender(<ApplicationStatusCell status={row.original.requestStatus}>{GrantReqStatuses[row.original.requestStatus]}</ApplicationStatusCell>, {row})
  },
  {
    id: "requestAmount",
    accessorKey: "requestAmount",
    header: "Amount Requested",
    cell: ({row}) => (<Typography sx={{ textAlign: "right" }}>{currencyFormate(row.original.requestAmount)}</Typography>)
  },
  {
    id: "grantStatus",
    accessorKey: "grantStatus",
    header: "Grant Status",
    cell: ({row}) => flexRender(<GrantsCell grantId={row.original.requestId}> Grants </GrantsCell>, {row})
  },
  {
    header: "Actions",
    cell: ({row}) => flexRender(<ActionCell id={row.original.id} forms={row.original.forms}> Download Application </ActionCell>, {row})
  }
];