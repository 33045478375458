import React from 'react'

const CreateAccountIcon = () => {
    return (
        <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.273 25.625v-7.688h-7.637v-5.125h7.637V5.125h5.09v7.688H56v5.124h-7.636v7.688h-5.091zM20.363 20.5c-2.8 0-5.196-1.004-7.19-3.01-1.994-2.008-2.991-4.421-2.991-7.24s.997-5.232 2.99-7.24C15.168 1.005 17.565 0 20.365 0s5.197 1.004 7.19 3.01c1.994 2.008 2.991 4.421 2.991 7.24s-.997 5.232-2.99 7.24c-1.994 2.006-4.391 3.01-7.191 3.01zM0 41v-7.175c0-1.452.371-2.787 1.114-4.004.742-1.217 1.728-2.146 2.959-2.787 2.63-1.324 5.303-2.317 8.018-2.979a34.8 34.8 0 0 1 8.273-.992c2.8 0 5.557.33 8.272.992 2.716.662 5.388 1.655 8.019 2.98 1.23.64 2.216 1.569 2.959 2.786a7.544 7.544 0 0 1 1.113 4.004V41H0z" fill="#FFF" fill-rule="nonzero" />
        </svg>
    )
}

export default CreateAccountIcon