type SiteBuilder = {
	id: number | null,
	applicationId: number | null,
	template: string | null,
	name: string | null,
	url: string | null,
	description: string | null,
	hasWelcome: boolean | null,
	hasEligibilityQuiz: boolean | null,
	hasOutcomes: boolean | null,
	welcomeLogo: string | null,
	welcomeBody: string | null,
	gpDisable: boolean | null,
	showStart: boolean | null,
	secure?: boolean | null
}

export const defaultSiteBuilder: SiteBuilder = {
	id: null,
	applicationId: null,
	template: null,
	name: null,
	url: null,
	description:null,
	hasWelcome: null,
	hasEligibilityQuiz: null,
	hasOutcomes: null,
	welcomeLogo: null,
	welcomeBody: null,
	showStart: null,
	gpDisable: null
};

export default SiteBuilder;