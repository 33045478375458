import { useNavigate, useParams } from 'react-router-dom';
import {useEffect, useState} from "react";
import Footer from '../../components/Footer';
import {Box, Button, Paper, Stack, TextField} from "@mui/material";
import config from "../../config";
import SiteBuilder, {defaultSiteBuilder} from "../../models/data/SiteBuilder";
import api from "../../api/api";
import axios from "axios";
import responseError from "../../api/interceptors/responseError";
import response from "../../api/interceptors/response";
import siteBuilder from "../../models/data/SiteBuilder";
import CircularProgress from "@mui/material/CircularProgress";
import {styled} from "@mui/material/styles";

const StyledLoader = styled('div')(() => ({
	display: 'flex',
	width: '100%',
	height: '100vh',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '10px',
	fontSize: '14px'
}));

const Welcome = () => {
	const { foundationId, url } = useParams();
	const navigate = useNavigate();
	const [ siteBuilder, setSiteBuilder ] = useState<SiteBuilder>(defaultSiteBuilder);
	const [password, setPassword] = useState('');
	const [invalid, setInvalid] = useState(false);
	const [loading, setLoading] = useState(true);

	const startApp = () => {
		_start(siteBuilder);
	}

	const fetchSiteBuilder = async () => {
		try {
			const { data } = await api.get(`sites/builder?foundation-id=${foundationId}&url=${url}`, {
				headers: {
					key: false
				}
			});

			setSiteBuilder(data);
			if (!data.hasWelcome && data.showStart) {
				_start(data);
			} else {
				setLoading(false);
			}
		} catch (error) {
			console.log({error});
			setLoading(false);
		}
	}

	const _start = (sb: siteBuilder) => {
		if (sb.hasEligibilityQuiz) {
			// if grantee portal is disabled for the foundation, open the EQ in FSOL.
			if (sb.gpDisable) {
				window.location.assign(`${config.rtlHost}/eligibility-quiz?siteId=${sb.id}`);
			} else {
				navigate(`/${foundationId}/${url}/eq?siteId=${sb.id}`);
			}
		} else {
			let defId = sb.applicationId;
			// if grantee portal is disabled for the foundation, open the form in FSOL.
			if (sb.gpDisable) {
				window.location.assign(`${config.rtlHost}/forms/view?defId=${sb.applicationId}`);
			} else {
				navigate(`/login?defId=${defId}`);
			}
		}
	}
	const handlePassword = async () => {
		setInvalid(false);

		const rest = axios.create();
		rest.interceptors.response.use(response, responseError);

		let data = { id: siteBuilder.id, password: password };
		try {
			const response = await rest.post(`/sites/auth`, data, {	baseURL: config.apiHost	});
			setSiteBuilder(response.data);
		} catch (error: any) {
			setInvalid(true);
		}
	}

	useEffect(() => {
		fetchSiteBuilder()
	}, []);

	return loading ?
		(<StyledLoader>
			<CircularProgress />
			<Box>Loading</Box>
		</StyledLoader>)
		:
		!!siteBuilder.id ?
		(
		<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: '100vh', backgroundColor: '#edf0f1'}}>
			<Box sx={{width: '100%', maxWidth: '1100px', backgroundColor: '#ffffff', padding: '50px 100px !important', minHeight: 'calc(100vh - 85px)'}}>
					<Box textAlign="center">
						{siteBuilder.welcomeLogo ? (
							<img style={{maxWidth: 1000, maxHeight: 300, objectFit: 'scale-down'}} src={siteBuilder.welcomeLogo}/>
						) : ''}
					</Box>
				{siteBuilder.secure ? (
					<Paper elevation={3} sx={{padding: '40px 90px', marginTop: '50px'}}>
						<Stack gap={2}>
							<Box sx={{fontSize: '28px', fontWeight: 'bold'}} >Protected Content</Box>
							<Box>
								This Application Process is password protected. You must enter a valid password in order to
								enter the application process.
							</Box>
							<TextField
								value={password}
								fullWidth
								type="password"
								variant="filled"
								label="Password"
								placeholder="Enter password"
								error={invalid}
								helperText={invalid ? "Invalid password" : null}
								onChange={(e) => setPassword(e.target.value)}
								onKeyDown={(e) => (
									e.key === 'Enter' ? handlePassword() : null
								)}
							/>
							<Box textAlign="center">
								<Button
									sx={{paddingX: '60px', marginTop: '10px'}}
									variant="contained"
									color="primary"
									onClick={handlePassword}
								>
									Verify password
								</Button>
								</Box>
						</Stack>
					</Paper>
					) : (
					<>
						<Box sx={{padding: '20px 0 30px 0', lineHeight: 1.5}}>
							<div dangerouslySetInnerHTML={{__html:siteBuilder.welcomeBody || ''}}></div>
						</Box>
						{siteBuilder.showStart &&	(
							<Box textAlign="center" mb={6}>
								<Button variant="contained" onClick={startApp}>
									Start Application
								</Button>
							</Box>
						)}
					</>
			)}
			</Box>
			<Footer/>
		</Box>
	)
			:
	(
		<Box p={2}>
			<h2>Error</h2>
			<ul>
				<li>Page does not exist</li>
			</ul>
		</Box>
	)

}

export default Welcome;