import React, { useState } from 'react';
import * as Yup from 'yup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormLabel,
  Alert
} from '@mui/material';
import api from '../../../api/api';



interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>
}

interface IFormValues {
  first: string;
  last: string;
  address: string;
}

const InviteMemberDialog = (props: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    first: Yup.string().required('First name is required'),
    last: Yup.string().required('Last name is required'),
    address: Yup.string().required('Email is required').email('Email is invalid')
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm<IFormValues>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit: SubmitHandler<IFormValues> = async (data: IFormValues) => {
    try {
      setLoading(true);

      const response = await api.post('/users/invite', data);
      if (response.status === 201) {
        setLoading(false);
        reset();
        handleClose();
        props.setAlertMessage('User has been Invited.');
      }
    }
    catch (error: any) {
      setLoading(false);
      setError('address', {
        type: "server",
        message: error,
      });
    }
  }

  const handleClose = () => {
    props.setOpen(false);
    reset();
  };

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      onClose={handleClose}
      open={props.open}
    >
      <DialogTitle variant="h3" fontWeight='bold'>Invite Team Member</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Alert severity="warning">
          Team members will have access to all applications and outcomes. There is currently no way to set permissions to what your members can view and do.
        </Alert>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid item xs={12} sx={{ my: '10px' }}>
              <FormLabel>First Name <Typography component='span' color='red'>*</Typography></FormLabel>
              <Controller
                name="first"
                control={control}
                render={({ field }) => <TextField
                  fullWidth
                  placeholder="Enter your First Name"
                  error={!!errors.first}
                  helperText={errors.first?.message}
                  {...field} />}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: '10px' }}>
              <FormLabel>Last Name <Typography component='span' color='red'>*</Typography></FormLabel>
              <Controller
                name="last"
                control={control}
                render={({ field }) => <TextField
                  fullWidth
                  placeholder="Enter your Last Name"
                  error={!!errors.last}
                  helperText={errors.last?.message}
                  {...field}
                />}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: '10px' }}>
              <FormLabel>Email <Typography component='span' color='red'>*</Typography></FormLabel>
              <Controller
                name="address"
                control={control}
                render={({ field }) => <TextField
                  fullWidth
                  placeholder="Enter your Email"
                  error={!!errors.address}
                  helperText={errors.address?.message}
                  {...field} />}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '20px' }}>
              <Button
                type="submit"
                className="submit"
                variant="contained"
                fullWidth
              >
                {isLoading ? <CircularProgress color="inherit" /> : 'Invite'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default InviteMemberDialog;