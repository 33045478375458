import config from "../../config";
import {Auth} from "aws-amplify";
import axios from "axios";

const loginServices = {
  async validateCustomSignUp(formData: any) {
    if (formData['custom:org_type'] === '1') {
      // is organization

      let taxId = formData[config.cognitoTaxIdField];
      let numbers = taxId.replaceAll(/[^0-9]/g, ''); // strip out all but numbers
      if (numbers.length !== 9 && taxId.length !== 0) {
        return {
	        [config.cognitoTaxIdField]: 'Tax ID is invalid'
        }
      }
    }
  },

  handleSignUp: async (formData:any) => {
    let { username, password, attributes } = formData;
    username = username.toLowerCase();
    attributes.email = attributes.email.toLowerCase();

    let response;
    try {
      response = await Auth.signUp({username, password, attributes, autoSignIn: {enabled: true}});
	    // add user to Giving Hub database
	    await addUser(attributes, response.userSub);

	    return Promise.resolve(response);
    } catch (reason) {
      return Promise.reject(reason);
    }
  },

	handleConfirmSignIn: async (formData:any) => {
		let remember = (document.getElementById("remember") as HTMLInputElement).checked;
		try {
			let signInResponse = await Auth.confirmSignIn(formData.user, formData.code, formData.mfaType);
			if (remember) await Auth.rememberDevice();
			return Promise.resolve(signInResponse);
		} catch (reason) {
			return Promise.reject(reason);
		}
	}
}

const addUser = async (attributes: any, cognitoUsername: string) => {
  let head = {
    baseURL: config.apiHost,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  let payload = {
    cognitoUsername,
    organization: {
      // id: 0,
      name: attributes["custom:organization"],
      "tax-id": attributes[config.cognitoTaxIdField] ? attributes[config.cognitoTaxIdField].replaceAll(/[^0-9]*/g, '') : undefined // tax ID should only contain numbers
    },
    contact: {
      first: attributes.given_name,
      // middle: string,
      last: attributes.family_name,
      // salutation: string,
      // suffix: string,
      // ssn: string,
      // dob: 2023-10-16T17:16:22.640Z,
      emails: [
        {
          type: 1,
          address: attributes.email
        }
      ],
      phones: [
        {
          type: 1,
          number: attributes.phone_number
        }
      ],
      // addresses: [
      //   {
      //     type: OTHER,
      //     address1: string,
      //     address2: string,
      //     city: string,
      //     state: string,
      //     postal: string,
      //     country: st
      //   }
      // ],
      // emailAddress: string,
      // emailAddressList: [
      //   {
      //     type: OTHER,
      //     address: string
      //   }
      // ],
      // secondaryEmailAddress: string
    },
    accountType: parseInt(attributes["custom:org_type"])
  }

  try {
    return await axios.post('users', payload, head);
  } catch (reason) {
    return Promise.reject(reason);
  }
}

export default loginServices;