const MuiFormLabel = {
	styleOverrides: {
		root: (): any => ({
			fontWeight: 600,
			fontSize: '16px',
			color: '#1f2733'
		}),
		asterisk: {
			color: '#F9564E',
			fontWeight: 'bold'
		}
	}
}

export default MuiFormLabel;