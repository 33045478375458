import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckIcon from '@mui/icons-material/Check';
import fileExclamation from "../../../assets/file-exclamation.svg";
import React from "react";

interface ReqStatuses {
    [key: number]: string
}
export const GrantReqStatuses: ReqStatuses = {
    1: 'Active', // Active
    2: 'Incomplete', // Draft
    3: 'Pending Review', // Pending Review
    4: 'Pending Review', // Pending Approval
    5: 'Pending Review', // Pending Approved
    6: 'Pending Review', // Pending Declined
    7: 'Declined', // Declined
    8: 'Approved', // Complete
    21: 'Grant Processing'
    // 86: 'Deleted'
}

export const renderStatusIcon = (status: number): React.ReactNode => {
	switch (status) {
		case 3:
		case 4:
		case 5:
		case 6:
		case 21:
			return <HourglassTopIcon fontSize="small" sx={{color: '#BD9048', mr: 1}}/>;
		case 8:
			return <CheckIcon fontSize="small" sx={{color: 'green', mr: 1}}/>;
		case 2:
			return <img src={fileExclamation} style={{height: '20px', marginRight: '8px'}}/>
		default:
			return;
	}
}