import React from "react";
import MultipleForms from "./multipleForms";
import DownloadLink from "./downloadLink";

interface IActionCellProps {
    children?: React.ReactNode;
    props?: any;
    onClick?: any;
    id: number,
    forms: []
}

const ActionCell: React.FC<IActionCellProps> = ({ onClick, children, ...props }) => {

    return (
      props.forms.length > 1 ? (
        <MultipleForms forms={props.forms}/>
      ) : (
        <DownloadLink id={props.id}/>
      )
    );
};

export default ActionCell;