import React from 'react'

const ApplicationStartIcon = () => {
    return (
        <svg width="50" height="60.625" viewBox="0 0 50 60.625" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 60.625V42.109c0-1.041.247-2.03.742-2.968a5.977 5.977 0 0 1 2.07-2.266l3.438-2.266c.365 4.375.938 8.1 1.719 11.172.781 3.073 2.005 6.485 3.672 10.235L0 60.625zm16.328-6.25a44.509 44.509 0 0 1-4.062-10.938c-.886-3.854-1.329-7.838-1.329-11.953 0-6.51 1.29-12.643 3.868-18.398C17.383 7.33 20.78 2.969 25 0c4.219 2.969 7.617 7.33 10.195 13.086 2.578 5.755 3.867 11.888 3.867 18.398 0 4.063-.442 8.008-1.328 11.836a47.422 47.422 0 0 1-4.062 11.055H16.328zM25 32.5c1.719 0 3.19-.612 4.414-1.836 1.224-1.224 1.836-2.695 1.836-4.414 0-1.719-.612-3.19-1.836-4.414C28.19 20.612 26.72 20 25 20c-1.719 0-3.19.612-4.414 1.836-1.224 1.224-1.836 2.695-1.836 4.414 0 1.719.612 3.19 1.836 4.414C21.81 31.888 23.28 32.5 25 32.5zm25 28.125-11.64-4.61c1.666-3.75 2.89-7.16 3.671-10.234.782-3.073 1.354-6.797 1.719-11.172l3.438 2.266a5.977 5.977 0 0 1 2.07 2.266c.495.937.742 1.927.742 2.968v18.516z" fill="#FFF" fill-rule="nonzero" />
        </svg>
    )
}

export default ApplicationStartIcon