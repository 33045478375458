import { Avatar } from "@mui/material";
import {getStoredSession} from "../util/storage";

const getInitials = (name: string): string => {
    const parts = name.split(" ");
    let initials = parts[0].substring(0,1).toUpperCase();
    if (parts.length > 1) {
        initials += parts[parts.length-1].substring(0,1).toUpperCase();
    }
    return initials;
}

const getColor = (name: string): string => {
    // try to get a unique value based off the entire name, so people with the same initials can vary.
    // Add up the char code of all the characters, and mod by number of color options.
    let code = 0;
    for (let i = 0; i < name.length; i++) { // ignore spaces in the calculation, since we can't rely on how many there may be
      if (name.charCodeAt(i) !== 32) {
        code += name.charCodeAt(i);
      }
    }
    return colors[code % 9];
}

const colors = [ '#4ACCC2', '#11229B', '#4557D8', '#890A31', '#C53D67', '#BB413B', '#F9564E', '#BD9048', '#FFBA57' ];

const AvatarInitials = (props: { name?: string, size?: number; showName?: boolean, stacked?:boolean }) => {
	let name = props?.name ? props.name : null;
	let size = props?.size ? props.size : 40;
  let session = getStoredSession();

	if (name === null) {
		name = session.firstName + ' ' + session.lastName;
	}

	const nameText = <div>{name}</div>;

	return (
		<div style={{display: 'flex', alignItems: 'center', gap: 10, flexDirection: props.stacked ? 'column' : 'row'}}>
			<Avatar sx={{backgroundColor: getColor(name), width: size, height: size, fontSize: size * .5, lineHeight: `${size}px`, fontWeight: 'bold', fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif'}}>
				{getInitials(name)}
			</Avatar>
			{props.showName ? nameText : ''}
		</div>
	)
};

export default AvatarInitials;