import React from 'react'

const BestPracticesIcon = () => {
    return (
        <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.95 45.6h15.675a4.36 4.36 0 0 0 2.244-.606c.69-.403 1.176-.962 1.461-1.674l5.985-13.965c.095-.238.166-.475.214-.713.047-.237.071-.474.071-.712v-2.28c0-.807-.273-1.484-.82-2.03-.546-.547-1.223-.82-2.03-.82H29.64l1.71-9.69a2.6 2.6 0 0 0-.071-1.354 2.888 2.888 0 0 0-.713-1.14L28.5 8.55 15.39 22.8c-.38.38-.665.808-.855 1.282-.19.476-.285.998-.285 1.568V39.9c0 1.568.558 2.91 1.674 4.026 1.117 1.116 2.459 1.674 4.026 1.674zM28.5 57c-3.942 0-7.648-.748-11.115-2.244-3.468-1.497-6.484-3.527-9.049-6.092-2.565-2.565-4.595-5.581-6.092-9.049C.748 36.147 0 32.443 0 28.5c0-3.942.748-7.648 2.244-11.115 1.497-3.468 3.527-6.484 6.092-9.049 2.565-2.565 5.582-4.595 9.049-6.092C20.852.748 24.557 0 28.5 0c3.943 0 7.648.748 11.115 2.244 3.468 1.497 6.484 3.527 9.049 6.092 2.565 2.565 4.595 5.582 6.092 9.049C56.252 20.852 57 24.557 57 28.5c0 3.943-.748 7.648-2.244 11.115-1.497 3.468-3.527 6.484-6.092 9.049-2.565 2.565-5.581 4.595-9.049 6.092C36.147 56.252 32.443 57 28.5 57z" fill="#FFF" fill-rule="nonzero" />
        </svg>
    )
}

export default BestPracticesIcon