import fileExclamation from '../../../assets/file-exclamation.svg';
import CheckIcon from '@mui/icons-material/Check';

interface Statuses {
    [key: number]: string
}
export const OutcomeStatuses: Statuses = {
    12: 'Received', // Submitted
    13: 'Canceled',
    14: 'Declined',
    90: 'Pending', // Send Failed
    5: 'To Be Sent', // Will Send
    11: 'Past Due',
    4: 'Need to Create',
    3: 'Waiting for Payment',
    15: 'Reviewed',
    10: 'Ready to Complete', // Sent
    20: 'On Hold'
}

export const renderStatusIcon = (status: number): React.ReactNode => {
    return status === 12 ? <CheckIcon fontSize="small" sx={{ color: 'green', mr: 1 }} /> : <img src={fileExclamation} style={{height: '20px', marginRight: '8px'}}/>
}