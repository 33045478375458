import styled from "@emotion/styled";
import {
  Autocomplete,
  Box, Button, Dialog, DialogActions, DialogContent,
  FormControlLabel,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField
} from "@mui/material";
import React, {useState} from "react";
import CountryCodes from "./countryCodes";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import OrgTypeHelpDialog from "./orgTypeHelpDialog";
import config from "../../config";

type IInvite = {
	id: number,
	key: string,
	first: string,
	last: string,
	address: string,
}

const SignUp = (props: { invite?: IInvite }) => {
	const { invite } = props;

  const RadioLabel = styled(FormControlLabel)(({theme}) => ({
    alignItems: "flex-start"
  }));

  const [orgType, setOrgType] = useState(!!invite ? 2 : 1); // default to Self for invites, Org for new signups
  const [fieldErrors, setFieldErrors] = useState<Map<string, string>>(new Map());
  const [passwordError, setPasswordError] = useState<string[] | null>(null);
  const [passwordConfirmError, setPasswordConfirmError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const OrgTypeBox = styled(Box)({
    fontSize: "14px",
    color: "rgba(31, 39, 51)",
    "& > div": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "1f2733"
    }
  })

  const getCountryCode = (name:string | undefined) => {
    return name ? CountryCodes.find((code) => code.name === name) : undefined;
  }

  const Flag = (props: {width: number, abbr: string}) => {return (<img
    loading="lazy"
    width={props.width}
    srcSet={`https://flagcdn.com/w40/${props.abbr.toLowerCase()}.png 2x`}
    src={`https://flagcdn.com/w20/${props.abbr.toLowerCase()}.png`}
    alt=""
  />)}

  const validateField = (e: React.FocusEvent, rule: { required?: boolean, maxLen?: number, matches?: string }) => {
    let {value, name} = e.target as HTMLInputElement;
    let errs = fieldErrors;

    // ignore all rules if field is not required and empty
    if (!rule.required && value.length === 0) {
      errs.delete(name);
      setFieldErrors(errs);
      return;
    }

    if (rule.required && value.length === 0) {
      errs.set(name, "Entry is Required");
    } else if (rule.maxLen && value.length > rule.maxLen) {
      errs.set(name, "Maximum number of characters is " + rule.maxLen);
    } else if (rule.matches && !new RegExp(rule.matches).test(value)) {
      errs.set(name, "Invalid format");
    } else {
      errs.delete(name);
    }
    setFieldErrors(errs);
  }

  const validatePassword = (e: React.ChangeEvent | React.FocusEvent) => {
    let { value } = e.target as HTMLInputElement;
    if (value.length === 0) {
      setPasswordError([]);
      return;
    }
    let errs = [];
    if (value.length < 8) errs.push("Password must be at least 8 characters long.")
    if (!/[0-9]+/.test(value)) errs.push("Password must include at least one number.");
    if (!/[a-z]+/.test(value)) errs.push("Password must include at least one lower-case letter.");
    if (!/[A-Z]+/.test(value)) errs.push("Password must include at least one upper-case letter.");
    if (/^[0-9a-zA-Z]+$/.test(value)) errs.push("Password must include at least one special character.");

    setPasswordError( errs.length === 0 ? null : errs);
  }

  const validateConfirmPassword = (e: React.ChangeEvent | React.FocusEvent) => {
    let { value } = e.target as HTMLInputElement;
    let pwd = document.forms[0].password.value;
    if (value.length === 0) {
      setPasswordConfirmError("Entry is required");
    } else if (value !== pwd) {
      setPasswordConfirmError("Passwords do not match");
    } else {
      setPasswordConfirmError(null);
    }
  }

  const PasswordError = () => {
    if (passwordError === null) {
      return <></>;
    }
    if (passwordError.length === 0) {
      return <>Entry is required</>
    } else {
      return (<ul style={{paddingLeft: "15px"}}>
        {passwordError.map((error) => <li>{error}</li>)}
      </ul>)
    }
  }

  return (
    <>
      <Stack spacing={2}>
	      {!invite ? (
					<>
		        <h3 style={{margin: 0}}>I will be submitting applications to receive funds for:</h3>
		        <RadioGroup name="custom:org_type" row sx={{gap: "20px", flexWrap: "nowrap"}} defaultValue="1">
		          <Box className={"orgType" + (orgType === 1 ? " active" : "")}>
		            <RadioLabel value="1" control={<Radio onClick={() => setOrgType(1)}/>} label={
		              <OrgTypeBox>
		                <Box>An Organization</Box>
		                A legal entity that is organized and operated for public or social benefit.
		              </OrgTypeBox>
		            }/>
		          </Box>
		          <Box className={"orgType" + (orgType === 2 ? " active" : "")}>
		            <RadioLabel value="2" control={<Radio onClick={() => setOrgType(2)}/>} label={
		              <OrgTypeBox>
		                <Box>Myself</Box>
		                A single person or family requesting grant funds for personal use.
		              </OrgTypeBox>
		            }/>
		          </Box>
		        </RadioGroup>
		        <Box textAlign="center">
		          <Link sx={{fontSize: "14px", cursor: "pointer"}} onClick={() => setShowHelpDialog(true)}>Not sure which to select?</Link>
		        </Box>
					</>
			  ) : ''}

        {orgType === 1 ? (
          <>
            <TextField
              name="custom:organization"
              variant="filled"
              label="Organization Name"
              fullWidth
              onBlur={(e) => validateField(e, { maxLen: 255 })}
              error={fieldErrors.has("custom:organization")}
              helperText={fieldErrors.get("custom:organization")}
            />
            <TextField
                  name={config.cognitoTaxIdField}
                  variant="filled"
                  label="Tax ID / EIN"
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  onBlur={(e) => validateField(e, { matches: "^(\\D*\\d){9}$" })}
                  error={fieldErrors.has(config.cognitoTaxIdField)}
                  helperText={fieldErrors.has(config.cognitoTaxIdField) ? "Tax ID / EIN must contain exactly 9 numbers" : null}
                />
          </>
        ) : (
					<input type="hidden" name="orgType" value={orgType}/>
        )}

        <TextField
          name="given_name"
          variant="filled"
          label="First Name"
          fullWidth
          required
          onBlur={(e) => validateField(e, { required: true, maxLen: 255 })}
          error={fieldErrors.has("given_name")}
          helperText={fieldErrors.get("given_name")}
          defaultValue={!!invite ? invite.first : null}
        />
        <TextField
          name="family_name"
          variant="filled"
          label="Last Name"
          fullWidth
          required
          onBlur={(e) => validateField(e, { required: true, maxLen: 255 })}
          error={fieldErrors.has("family_name")}
          helperText={fieldErrors.get("family_name")}
          defaultValue={!!invite ? invite.last : null}
        />
        <Box>
          <Stack direction="row" spacing={1}>
            <Autocomplete
              id="country-select-demo"
              sx={{width: 350}}
              options={CountryCodes}
              autoHighlight
              disableClearable
              getOptionLabel={(option) => option.name}
              defaultValue={{abbr: 'US', name: 'United States', code: '+1', suggested: true}}
              renderOption={(props, option) => (
                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                  <Flag width={20} abbr={option.abbr}/>
                  {option.name} {option.code}
                </Box>
              )}
              renderInput={(params) => {
                let countryCode = getCountryCode(params.inputProps.value as string);
                let abbr = countryCode?.abbr || '';
                let code = countryCode?.code || '';

                return (
                  <>
                    <TextField
                      {...params}
                      variant="filled"
                      label="Country Code"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: params.inputProps.value && (
                          <InputAdornment sx={{margin: "0 15px 0 5px !important"}} position="start">
                            <Flag width={30} abbr={abbr}/>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <input type="hidden" name="country_code" id="country_code" value={code}/>
                  </>
                )
              }}
            />

            <TextField
              name="phone_number"
              variant="filled"
              label="Mobile Number"
              fullWidth
              required
              onBlur={(e) => validateField(e, { required: true, matches: "^(\\D*\\d){7,15}$" })}
              error={fieldErrors.has("phone_number")}
              helperText={fieldErrors.get("phone_number")}
              autoComplete="new-password"
            />
          </Stack>
          <Box sx={{fontSize: "14px"}}>To make sure this number is yours, we will send you an SMS message with a 6-digit
            verification code. Standard rates apply.</Box>
        </Box>
        <Box>
          <TextField
            name="email"
            variant="filled"
            label="Email"
            fullWidth
            required
            onBlur={(e) => validateField(e, { required: true, matches: "[^\\s]+@[^\\s]+\\.[^\\s]+" })}
            error={fieldErrors.has("email")}
            helperText={fieldErrors.get("email")}
            defaultValue={invite?.address}
            disabled={!!invite}
          />
          <Box sx={{fontSize: "14px"}}>To make sure this email is yours, we will send you an email with a 6-digit
            verification code.</Box>
        </Box>
        <TextField
          type={showPassword ? "text" : "password"}
          name="password"
          variant="filled"
          label="Password"
          fullWidth
          required
          onChange={validatePassword}
          onBlur={validatePassword}
          error={!!passwordError}
          helperText={!!passwordError ? <PasswordError/> : null}
          InputProps={{
            endAdornment: <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOutlinedIcon fontSize="small"/> : <VisibilityOffOutlinedIcon fontSize="small"/> }
            </IconButton>
          }}
        />
        <TextField
          type={showConfirmPassword ? "text" : "password"}
          name="confirm_password"
          variant="filled"
          label="Confirm Password"
          fullWidth
          required
          onChange={validateConfirmPassword}
          onBlur={validateConfirmPassword}
          error={!!passwordConfirmError}
          helperText={passwordConfirmError}
          InputProps={{
            endAdornment: <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {showConfirmPassword ? <VisibilityOutlinedIcon fontSize="small"/> : <VisibilityOffOutlinedIcon fontSize="small"/> }
            </IconButton>
          }}
        />

      </Stack>
      <Dialog
        open={showHelpDialog}
        onClose={() => setShowHelpDialog(false)}
        maxWidth="md">
        <DialogContent>
          <OrgTypeHelpDialog/>
        </DialogContent>
        <DialogActions sx={{justifyContent: "center"}}>
          <Button variant="contained" onClick={() => setShowHelpDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SignUp;