const TermsOfUse = () => (
  <div className="modal-body">
    <h2><b>Terms of Use</b></h2>
    <p><b>Last Updated: December 14, 2022</b></p>
    <p><b>BY ACCESSING AND USING THIS SITE PROVIDED BY FOUNDATION SOURCE PHILANTHROPIC SERVICES INC. (“FOUNDATION
      SOURCE”) (THE “SITE”), YOU ARE AGREEING TO BE LEGALLY BOUND BY THESE TERMS OF USE. AS USED HEREIN, THE TERM “YOU”
      MEANS AN ENTITY OR INDIVIDUAL ACCESSING THIS SITE. IF YOU ARE USING THE SITE ON BEHALF OF A COMPANY OR OTHER LEGAL
      ENTITY, SUCH ENTITY MAY HAVE A SEPARATE AGREEMENT WITH US, BUT YOU ARE NEVERTHELESS INDIVIDUALLY BOUND BY THESE
      TERMS OF USE.IF YOU HAVE ENTERED INTO A CONTRACT FOR SERVICES WITH FOUNDATION SOURCE (“SERVICES AGREEMENT”), THEN
      YOUR SERVICES AGREEMENT, TOGETHER WITH THE TERMS OF ACCESS SET FORTH ON THE ONLINE PLATFORM PROVIDED BY FOUNDATION
      SOURCE TO ITS CLIENTS AND NOT TO THE GENERAL PUBLIC, CONTROL YOUR USE OF THE SITE, NOT THESE TERMS OF USE, EXCEPT
      FOR THE PROVISIONS IN THESE TERMS OF USE SPECIFICALLY REFERENCING GRANTSAFE®.</b></p>
    <ol>

      <li><p><b className="terms-subtitle">General</b></p>
        <p>Foundation Source may change any term in these Terms of Use at any time. The changes will appear in these
          Terms of Use, and your use of the Site after any changes have been posted will constitute your agreement to
          the modified Terms of Use and all of the changes. THE DATE THESE TERMS OF USE WERE LAST UPDATED IS STATED AT
          THE END OF THIS DOCUMENT. Therefore, you should check when these Terms of Use were last updated each time you
          access the Site, and before you begin using the Site. You may print a copy of these Terms of Use for your
          reference. You may not use the Site for any unlawful purpose or any purpose not approved by Foundation
          Source.</p>

      </li>
      <li><p><b className="terms-subtitle">Limitations on Use and Content</b></p>
        <p>“Foundation Source®,” “GrantSafe®,” and “Foundation Source GrantCertificatesSM ” are trademarks of Foundation
          Source Philanthropic Services Inc. Other trademarks that appear on the Site are the property of their
          respective owners.
          Our trademarks are protected by U.S. trademark laws and international laws and treaties. No license or right
          to use any of Foundation Source’s trademarks, service marks, trade names or logos is granted to you as a
          result of your visit to our Site or agreement to these Terms. You agree not to use Foundation Source’s
          trademarks or other intellectual property in any manner without our express prior written authorization. Any
          unauthorized use of our trademarks or other intellectual property is strictly forbidden and may result in
          legal liability.
          The services provided through the Site (the “Services”), including GrantSafe®, Foundation Source Applications
          (formerly Foundation Source Requests) and Foundation Source Outcomes (formerly Foundation Source Results),
          which are accessed via Foundation Source Giving Hub, and Foundation Source Grant CertificatesSM contain text,
          files, images, photos, video, sounds, musical works, works of authorship, applications, and other materials of
          Foundation Source and its licensors (collectively, the “Content”). The Content is protected by copyright,
          trademark, patent, trade secret and other laws, and Foundation Source and its licensors own and retain all
          rights in the Content and the Services. Foundation Source hereby grants you a limited, revocable,
          nonsublicensable license to reproduce and display the Content (excluding any software code) solely for your
          personal use in connection with viewing the Site and using the Services. You agree not to change or delete any
          copyright or proprietary notice from materials downloaded from this Site.
        </p>
      </li>
      <li>
        <p><b className="terms-subtitle">Service Eligibility</b></p>
        <p>To be eligible to use the Services, you must meet the following criteria and represent and warrant that you:
          (1) are eighteen (18) years of age or, if you are a user of Foundation Source GrantSafe® and are under 18, are
          being supervised by a legal guardian or adult who is at least 18; (2) are not currently prohibited from using
          the Services; (3) are not a competitor of Foundation Source and are not using the Services for reasons that
          are in competition with Foundation Source, unless otherwise explicitly authorized by Foundation Source; (4)
          can enter into these Terms of Use without violating any other agreement to which you are a party; (5) will not
          violate any rights of Foundation Source, including intellectual property rights such as copyright or trademark
          rights; and (6) agree to provide at your cost all equipment, software, and Internet access necessary to use
          the Services.
          In addition to the foregoing criteria, to be eligible to use GrantSafe®, you hereby certify that you are an
          officer, director, trustee, employee or other authorized representative of a private foundation that is
          classified as such under Section 509(a) of the Internal Revenue Code of 1986, as amended (the “Code”), and
          that you will use GrantSafe® solely to determine the federal tax exempt status of grantee organizations for
          purposes of Code Sections 4942 and 4945 and for no other purpose.
          FOUNDATION SOURCE DOES NOT HAVE ANY OBLIGATION TO MONITOR THE USE OF GRANTSAFE® BY USERS OR TO VERIFY THE
          IDENTITY OF THE USERS OF GRANTSAFE®.</p>
      </li>
      <li>
        <p><b className="terms-subtitle">Security</b></p>
        <p>You agree to refrain from charging anyone for access to GrantSafe®, or any information therein. Foundation
          Source cannot and will not be liable for any loss or damage arising from your failure to comply with this
          Section 4. You hereby grant Foundation Source and all other persons and entities involved in the operation of
          the Site and the Services the right to transmit, monitor, retrieve, store and use your information in
          connection with the operation of the Site and the Services.</p>
      </li>
      <li>
        <p><b className="terms-subtitle">Privacy Policy</b></p>
        <p>Data you provide and certain other information about you is subject to our Privacy Policy. For more
          information, see our full privacy policy at https://foundationsource.com/privacy-notice.</p>
      </li>
      <li>
        <p><b className="terms-subtitle">Notifications and Service Messages</b></p>
        <p>For purposes of service messages and notices about the Services to you, where applicable, notice shall
          consist of an email from Foundation Source to an email address you have provided to Foundation Source in
          connection with your use of the Services. You also agree that Foundation Source may communicate with you
          through email or through other means including mobile number, telephone or delivery services including the US
          Postal Service about Foundation Source or the Services. You acknowledge and agree that (a) you shall be solely
          responsible for any charges from your mobile phone provider for such use, we shall have no liability with
          respect to such charges, and (b) we shall have no liability associated with or arising from your failure to
          maintain accurate contact or other information.</p>
      </li>
      <li>
        <p><b className="terms-subtitle">Intended Use of GrantSafe®</b></p>
        <p>All information and certificates provided through GrantSafe® are based solely on the IRS Business Master File
          Extract in compliance with Section 4 of Revenue Procedure 2011-33 and are intended for use only by private
          foundations that are classified as such under Section 509(a) of the Code solely to determine the federal tax
          exempt status of grantee organizations for purposes of Code Sections 4942 and 4945. GrantSafe® is not intended
          for any other use, including any use for purposes of determining deductibility of contributions to entities
          exempt under Section 501(c)(3) of the Code.</p>
      </li>
      <li>
        <p><b className="terms-subtitle">Disclaimer of Warranties for Services other than GrantSafe®</b></p>
        <p>Due to the number of sources from which the Content is obtained, and the inherent hazards of electronic
          distribution, there may be delays, omissions or inaccuracies in such Content and the Site. DO NOT RELY ON THE
          SITE, OR ANY SUCH CONTENT THEREIN, OR ITS CONTINUATION. SUCH CONTENT AND THE SITE ARE PROVIDED “AS IS” AND “AS
          AVAILABLE”, WITHOUT ANY WARRANTIES, EXPRESS OR IMPLIED. FOUNDATION SOURCE AND ITS AFFILIATES, AGENTS AND
          LICENSORS CANNOT AND DO NOT MAKE ANY REPRESENTATION WITH RESPECT TO OR WARRANT THE ACCURACY, COMPLETENESS,
          CURRENTNESS, TIMELINESS, NONINFRINGEMENT, TITLE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF SUCH
          CONTENT, OR THE SITE ITSELF, AND FOUNDATION SOURCE HEREBY DISCLAIMS ANY SUCH EXPRESS OR IMPLIED WARRANTIES.
          FOUNDATION SOURCE IS NOT RESPONSIBLE, AND MAKES NO REPRESENTATIONS OR WARRANTIES FOR THE DELIVERY OF ANY
          MESSAGES (SUCH AS EMAILS, POSTING OR ANSWERS OR TRANSMISSION OF ANY OTHER USER GENERATED CONTENT) SENT THROUGH
          THE SITE OR THE SERVICES TO ANYONE.
          FOUNDATION SOURCE DOES NOT HAVE ANY OBLIGATION TO VERIFY THE IDENTITY OF THE PERSONS SUBSCRIBING TO ITS
          SERVICES, NOR DOES IT HAVE ANY OBLIGATION TO MONITOR THE USE OF ITS SERVICES BY OTHER USERS OF THE COMMUNITY;
          THEREFORE, FOUNDATION SOURCE DISCLAIMS ALL LIABILITY FOR IDENTITY THEFT OR OTHER MISUSE OF YOUR IDENTITY OR
          INFORMATION.
          FOUNDATION SOURCE MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE TAX STATUS OF THOSE ORGANIZATIONS WHO LIST
          THEMSELVES AS CHARITIES ON THE SITE, AND IT IS THE SOLE RESPONSIBILITY OF THE PERSON ACCESSING THE SITE TO
          CONFIRM THAT THE CHARITIES LISTED ON THE SITE ARE EXEMPT ORGANIZATIONS UNDER THE RELEVANT SECTION(S) OF THE
          INTERNAL REVENUE CODE OF 1986, AS AMENDED.</p>
      </li>
      <li>
        <p><b className="terms-subtitle">Limitation of Liability</b></p>
        <p>FOUNDATION SOURCE DOES NOT GUARANTEE THAT THE SERVICES IT PROVIDES WILL FUNCTION WITHOUT INTERRUPTION OR
          ERRORS IN FUNCTIONING. IN PARTICULAR, THE OPERATION OF THE SERVICES MAY BE INTERRUPTED DUE TO MAINTENANCE,
          UPDATES OR SYSTEM OR NETWORK FAILURES. FOUNDATION SOURCE DISCLAIMS ALL LIABILITY FOR DAMAGES CAUSED BY ANY
          INTERRUPTION OR ERRORS IN FUNCTIONING. FURTHERMORE, FOUNDATION SOURCE DISCLAIMS ALL LIABILITY FOR ANY
          MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR POOR USE CONDITIONS OF THE FOUNDATION SOURCE SITE DUE TO
          INAPPROPRIATE EQUIPMENT, DISTURBANCES RELATED TO INTERNET SERVICE PROVIDERS, TO THE SATURATION OF THE INTERNET
          NETWORK, AND FOR ANY OTHER REASON.
          NEITHER FOUNDATION SOURCE NOR ANY OF ITS AFFILIATES, AGENTS OR LICENSORS WILL BE LIABLE TO YOU OR ANYONE ELSE
          FOR ANY LOSS OR INJURY, OTHER THAN DEATH OR PERSONAL INJURY RESULTING DIRECTLY FROM USE OF THE SITE, CAUSED IN
          WHOLE OR PART BY ITS NEGLIGENCE OR CONTINGENCIES BEYOND ITS CONTROL IN PROCURING, COMPILING, INTERPRETING,
          REPORTING OR DELIVERING THE SITE AND ANY CONTENT PRESENTED ON THE SITE.
          IN NO EVENT WILL FOUNDATION SOURCE, ITS AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR
          ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH CONTENT OR THE SITE, INCLUDING WITHOUT
          LIMITATION, ANY DECISION MADE OR ACTION TAKEN BASED ON ANY USE OF GRANTSAFE®.
          FOUNDATION SOURCE AND ITS AFFILIATES, AGENTS AND LICENSORS SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
          DAMAGES (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT OR SIMILAR DAMAGES),
          OTHER THAN DIRECT DAMAGES (NOT TO EXCEED US $1,000.00), EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          ANY CLAIMS ARISING IN CONNECTION WITH YOUR USE OF THE SITE MUST BE BROUGHT WITHIN ONE (1) YEAR OF THE DATE OF
          THE EVENT GIVING RISE TO SUCH ACTION OCCURRED. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION
          OR LIMITATION OF LIABILITY FOR DAMAGES OR THE EXCLUSION OF CERTAIN TYPES OF WARRANTIES, PARTS OR ALL OF THE
          ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
      </li>
      <li>
        <p><b className="terms-subtitle">Links to Third Party Sites</b></p>
        <p>This Site may contain hyperlinks to sites operated by persons other than Foundation Source. Such hyperlinks
          are provided for your reference and convenience only and do not mean that Foundation Source endorses the
          content on or the operators of these sites. You agree not to hold Foundation Source responsible for the
          content or operation of such sites. You are solely responsible for determining the extent to which you may use
          any content at any other sites to which you might link from this Site.</p>
      </li>
      <li>
        <p><b className="terms-subtitle">Community Areas/User Comments/Uploading</b></p>
        <p>Foundation Source offers some community features on this Site and may elect to offer others, such as message
          or bulletin boards, forums, and chat rooms. Foundation Source will not assume any obligation to monitor,
          filter, censor, edit, or regulate information or content provided by you or third parties on this Site,
          although Foundation Source reserves the right to do so in its sole discretion. FOUNDATION SOURCE DOES NOT
          ENDORSE, WARRANT THE ACCURACY OR RELIABILITY OF, OR ASSUME ANY LIABILITY IN CONNECTION WITH ANY SUCH
          INFORMATION OR CONTENT PROVIDED BY USERS OR THIRD PARTIES. You hereby grant to Foundation Source a worldwide,
          royalty-free, perpetual, transferable, sublicenseable, non-exclusive right and license to copy, use, modify
          and make derivative works of and from any information or content you upload or post in any form and for any
          purposes whatsoever and you represent and warrant that you have the right and authority to grant such license.
          Foundation Source shall have no obligation or duty to give attribution to you for any content or information
          you upload or post.
          As to any information or content that you upload or post to this Site, you represent and warrant to Foundation
          Source that you have the right and authorization to do so without the consent of any third party and that
          Foundation Source has and will have the right to allow others to use, access, copy, store, modify such
          information and content. You also agree not to upload or post to this Site: (a) infringing, defamatory,
          obscene, pornographic, threatening, abusive, violent, illegal, rude, harassing, or otherwise improper content,
          (b) viruses or other harmful items, or (c) content with a commercial purpose (such as offers to sell products
          or services or, except for information posted on Foundation Source Applications via Foundation Source Giving
          Hub, attempts to solicit funds or advertise products or services).
          AS TO ANY INFORMATION OR CONTENT THAT YOU UPLOAD TO THIS SITE, FOUNDATION SOURCE MAKES NO REPRESENTATIONS OR
          WARRANTIES THAT SUCH INFORMATION OR CONTENT WILL BE KEPT CONFIDENTIAL. IF YOU ARE CONCERNED ABOUT KEEPING SUCH
          INFORMATION OR CONTENT CONFIDENTIAL, DO NOT UPLOAD IT.
          Foundation Source reserves the right, in its sole discretion, to adopt additional rules in any community area
          and to condition access to any such community features by any individual or group in accordance with age,
          geographic, or other criteria, to deny or restrict access by any individual or group who fails to meet that
          criteria or by anyone who fails to comply with our criteria or rules at any time, and to change or modify the
          criteria or rules at any time. Foundation Source also reserves the right (but does not assume any obligation),
          in its sole discretion, (a) to modify or delete any postings on this Site for any reason in its sole
          discretion including without limitation those that violate these Terms of Use, and (b) to reveal your identity
          or whatever information we know about you, in the event of a complaint or legal action arising from any
          message posted, or information uploaded, by you.
        </p>
      </li>
      <li>
        <p><b className="terms-subtitle">Notice of Infringing Material</b></p>
        <p>Foundation Source responds to notices of alleged copyright infringement and terminate accounts of repeat
          infringers according to the process set out in the U.S. Digital Millennium Copyright Act. If you believe in
          good faith that any Content on this Site violates or infringes upon your copyright rights pursuant to Title
          17, United States Code, Section 512(c)(2), you may file a DMCA Notice of Alleged Infringement with Foundation
          Source’s designated copyright agent with all specifics necessary for Foundation Source to consider and respond
          to your complaint, as set forth below. You may be asked to provide additional information and follow
          additional procedures for Foundation Source to act on your complaint. Please note that Foundation Source may
          not process your complaint if it is not properly filled out or is incomplete.
          You (or your agent) may send Foundation Source a written notice that includes the following information.</p>
        <ul>
          <li>A clear identification of the copyrighted work you claim is being infringed.</li>
          <li>A clear identification of the material on the Site that you claim is infringing, such as a link to the
            infringing material.
          </li>
          <li>Your address, email address and telephone number.</li>
          <li>A statement that you have a “good faith belief that the material that is claimed as copyright infringement
            is not authorized by the copyright owner, its agent, or the law.”
          </li>
          <li>A statement that “the information in the notification is accurate, and under penalty of perjury, the
            complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly being
            infringed.”
          </li>
          <li>A signature by you or the person authorized to act on behalf of the copyrighted work that you claim is
            being infringed.
          </li>
        </ul>
        <p>Please send your notice to the following address:
          Foundation Source Philanthropic Services Inc.
          55 Walls Drive
          Fairfield, CT 06824
          Attention: Chief Executive Officer
          With copies sent to the following address:
          Foundation Source Philanthropic Services Inc.
          Tax & Legal Affairs
          3333 New Hyde Park Road, Suite 406
          New Hyde Park, NY 11042
          Attention: Chief Legal Officer
          and with email copies of the above to:
          legal@foundationsource.com
          finance@foundationsource.com</p>
      </li>
      <li>
        <p><b className="terms-subtitle">US Government End-Users</b></p>
        <p>The software and documentation available on this Site are “commercial items,” as that term is defined in 48
          C.F.R. 2.101 (October 1995), consisting of “commercial computer software” and “commercial computer software
          documentation,” as such terms are used in 48 C.F.R. 12.212 (September 1995). Consistent with 48 C.F.R. 12.212
          and 48 C.F.R. 227-7202-1 through 227.7202-4 (June 1995), all U.S. government end users acquire the software
          and documentation with only those rights set forth herein.
        </p>
      </li>
      <li>
        <p><b className="terms-subtitle">Termination</b></p>
        <p>You agree that Foundation Source, in its sole discretion, may terminate your use of the Services for any
          reason, including without limitation, if Foundation Source believes that you have violated or acted
          inconsistently with the letter or spirit of these Terms of Use. Foundation Source may also in its sole
          discretion and at any time discontinue providing services under the Site, or any part thereof, with or without
          notice. You agree that any termination of your access to the Site under any provision of these Terms of Use
          may be effected without prior notice, and acknowledge. Further, you agree that Foundation Source shall not be
          liable to you or any third party for any termination of your access to the Site.
        </p>
      </li>
      <li>
        <p><b className="terms-subtitle">Indemnification</b></p>
        <p>You agree to indemnify and hold harmless each of Foundation Source, its affiliates, officers, directors and
          employees, from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or
          arising out of your use of this Site, the violation of these Terms of Use by you, or the infringement by you
          of any intellectual property or other right of any person or entity.
        </p>
      </li>
      <li>
        <p><b className="terms-subtitle">Governing Law and Jurisdiction; No Class Action</b></p>
        <p>This Site is owned and operated by Foundation Source from its offices in Connecticut. Your use of this Site,
          and any related legal action, shall be governed by the laws of the State of Connecticut, without regard to
          conflict of laws principles. The sole jurisdiction and venue for any litigation arising out of your use of or
          inquiries to the Site shall be an appropriate federal or state court located in the State of Connecticut.
          YOU AGREE THAT ANY PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL
          BASIS, AND THAT YOU WILL NOT SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION, A REPRESENTATIVE ACTION, A
          COLLECTIVE ACTION, A PRIVATE ATTORNEY-GENERAL ACTION, OR IN ANY PROCEEDING IN WHICH YOU ACT OR PROPOSE TO ACT
          IN A REPRESENTATIVE CAPACITY. YOU FURTHER AGREE THAT NO PROCEEDING WILL BE JOINED, CONSOLIDATED, OR COMBINED
          WITH ANOTHER PROCEEDING WITHOUT THE PRIOR WRITTEN CONSENT OF FOUNDATION SOURCE.
        </p>
      </li>
      <li>
        <p><b className="terms-subtitle">Additional Legal Terms</b></p>
        <p>No waiver of any of these Terms of Use shall be deemed a further or continuing waiver of such term or
          condition or any other term or condition.
          If any provision in these Terms of Use is invalid or unenforceable under applicable law, the remaining
          provisions will continue in full force and effect, and the invalid or unenforceable provision will be deemed
          superseded by a valid, enforceable provision that most closely matches the intent of the original provision.
          All rights not expressly granted herein are hereby reserved. These Terms of Use, the Privacy Statement, the
          Privacy Notice, the Terms of Access and your Services Agreement, if applicable, are the entire and final
          agreement regarding this Site and its Content, and supersede any prior or contemporaneous communications
          between Foundation Source and you with respect to the use of the Site. In the event of any conflict between
          the terms and conditions of these Terms of Use, the Privacy Statement, the Privacy Notice, the Terms of
          Access, and your Services Agreement, the terms and conditions of your Services Agreement shall control.
          © 2012-2022 Foundation Source Philanthropic Services Inc. All Rights Reserved.</p>
      </li>

    </ol>

  </div>
)

export default TermsOfUse;