import React, { MouseEventHandler, Dispatch, SetStateAction, useState } from "react"
import styled from "@emotion/styled"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
	IconButton,
	ListItem,
	ListItemText,
	Box,
	OutlinedInput,
	ListItemButton,
	Typography,
	TextField,
	Tooltip
} from "@mui/material"
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

const StyledForm = styled('form')({
	width: '100%'
})

const StyledIconButton = styled(IconButton)({
	backgroundColor: '#222faf',
	color: '#fff',
	borderRadius: '3px',
	marginLeft: '10px',
	'&:hover': {
		backgroundColor: "#17207a"
	},
	'&.Mui-disabled': {
		opacity: 1,
		backgroundColor: '#d8d8d8'
	}

})

const StyledListItemButton = styled(ListItemButton)({
	'&:hover': {
		backgroundColor: '#edf0f1',
		cursor: 'pointer'
	},
	'&.Mui-disabled': {
		opacity: 1
	}
});

interface UserInfoProps {
	editField?: string | null
	fieldId: string
	label: string
	value: string
	maxLength?: number
	divider?: boolean
	isEditable?: boolean
	required?: boolean
	onCancel?: (e: MouseEventHandler<HTMLDivElement>) => void
	setEditField?: Dispatch<SetStateAction<null | string>>;
	handleSubmit?: (value: string, label: string) => void;
	tooltip?: ReactJSXElement;
}

const UserInfo = (props: UserInfoProps) => {
	const [value, setValue] = useState(props.value);
	const [error, setError] = useState<string | null>(null)

	const handleCancel: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.stopPropagation();
		setValue(props.value);
		setError(null);
		props.setEditField?.(null);

	};

	const handleEditFiled = () => {
		props.setEditField?.(props.fieldId);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		e.stopPropagation();
		let error = null;
		let { value } = e.target;
		if (props.required && !value) {
			error = "Entry is required";
		}
		if (!error && props.fieldId === "taxId") {
			if (!/^(\D*\d){9}$/.test(value)) {
				error = "Tax ID / EIN must contain exactly 9 numbers";
			}
		}
		if (!error && props.fieldId === "phoneNumber") {
			if (!/^(\D*\d){10}$/.test(value) && !/^\+(\D*\d){7,15}$/.test(value)) { // numbers exactly 10 digits without the "+" will be assumed to be US/CA/MX (+1). Otherwise, it will require the "+" sybmol
				error = "Invalid phone number.  If your country code is not +1, add it to the beginning.";
			}
		}

		setError(error)
		setValue(e.currentTarget.value);
	}

	const onsubmit: MouseEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		props.handleSubmit?.(value, props.fieldId);
		props.setEditField?.(null);
	};


	return (
		<StyledListItemButton disabled={!props.isEditable || false} divider={props.divider || false} disableGutters={true} onClick={handleEditFiled}>
			<ListItem
				secondaryAction={(props.editField !== props.fieldId && props.isEditable) && <KeyboardArrowRightIcon />}
			>
				<StyledForm onSubmit={onsubmit}>
					<ListItemText
						primary={<Box sx={{ fontWeight: 'bold', fontSize: '18px', color: '#000' }}>{props.label}</Box>}
						secondary={props.editField === props.fieldId ?
							<Box display='flex' alignItems='flex-start'>
								<Tooltip title={props.tooltip} arrow>
									<TextField
										error={!!error}
										required={props.required}
										size='small'
										fullWidth
										inputProps={{ maxLength: props?.maxLength }}
										value={value}
										onChange={(e) => handleInputChange(e)}
										helperText={error}
									/>
								</Tooltip>
								<StyledIconButton size="small" type="submit" disabled={!!error}>
									<CheckIcon />
								</StyledIconButton>
								<StyledIconButton size="small" onClick={handleCancel}>
									<CloseIcon />
								</StyledIconButton>
							</Box> : <Typography noWrap>{props.value}</Typography>

						} />
				</StyledForm>
			</ListItem>
		</StyledListItemButton>
	)
};

export default UserInfo;