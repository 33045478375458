const CountryCodes = [
  {abbr: "AF", name: "Afghanistan", code: "+93"},
  {abbr: "AL", name: "Albania", code: "+355"},
  {abbr: "DZ", name: "Algeria", code: "+213"},
  {abbr: "AX", name: "Alland Islands", code: "+358"},
  {abbr: "AS", name: "American Samoa", code: "+1-684"},
  {abbr: "AD", name: "Andorra", code: "+376"},
  {abbr: "AO", name: "Angola", code: "+244"},
  {abbr: "AI", name: "Anguilla", code: "+1-264"},
  {abbr: "AQ", name: "Antarctica", code: "+672"},
  {abbr: "AG", name: "Antigua and Barbuda", code: "+1-268"},
  {abbr: "AR", name: "Argentina", code: "+54"},
  {abbr: "AM", name: "Armenia", code: "+374"},
  {abbr: "AW", name: "Aruba", code: "+297"},
  {abbr: "AU", name: "Australia", code: "+61", suggested: true},
  {abbr: "AT", name: "Austria", code: "+43"},
  {abbr: "AZ", name: "Azerbaijan", code: "+994"},
  {abbr: "BS", name: "Bahamas", code: "+1-242"},
  {abbr: "BH", name: "Bahrain", code: "+973"},
  {abbr: "BD", name: "Bangladesh", code: "+880"},
  {abbr: "BB", name: "Barbados", code: "+1-246"},
  {abbr: "BY", name: "Belarus", code: "+375"},
  {abbr: "BE", name: "Belgium", code: "+32"},
  {abbr: "BZ", name: "Belize", code: "+501"},
  {abbr: "BJ", name: "Benin", code: "+229"},
  {abbr: "BM", name: "Bermuda", code: "+1-441"},
  {abbr: "BT", name: "Bhutan", code: "+975"},
  {abbr: "BO", name: "Bolivia", code: "+591"},
  {abbr: "BA", name: "Bosnia and Herzegovina", code: "+387"},
  {abbr: "BW", name: "Botswana", code: "+267"},
  {abbr: "BV", name: "Bouvet Island", code: "+47"},
  {abbr: "BR", name: "Brazil", code: "+55"},
  {abbr: "IO", name: "British Indian Ocean Territory", code: "+246"},
  {abbr: "VG", name: "British Virgin Islands", code: "+1-284"},
  {abbr: "BN", name: "Brunei Darussalam", code: "+673"},
  {abbr: "BG", name: "Bulgaria", code: "+359"},
  {abbr: "BF", name: "Burkina Faso", code: "+226"},
  {abbr: "BI", name: "Burundi", code: "+257"},
  {abbr: "KH", name: "Cambodia", code: "+855"},
  {abbr: "CM", name: "Cameroon", code: "+237"},
  {abbr: "CA", name: "Canada", code: "+1", suggested: true},
  {abbr: "CV", name: "Cape Verde", code: "+238"},
  {abbr: "KY", name: "Cayman Islands", code: "+1-345"},
  {abbr: "CF", name: "Central African Republic", code: "+236"},
  {abbr: "TD", name: "Chad", code: "+235"},
  {abbr: "CL", name: "Chile", code: "+56"},
  {abbr: "CN", name: "China", code: "+86"},
  {abbr: "CX", name: "Christmas Island", code: "+61"},
  {abbr: "CC", name: "Cocos (Keeling) Islands", code: "+61"},
  {abbr: "CO", name: "Colombia", code: "+57"},
  {abbr: "KM", name: "Comoros", code: "+269"},
  {abbr: "CD", name: "Congo, Democratic Republic of the", code: "+243"},
  {abbr: "CG", name: "Congo, Republic of the", code: "+242"},
  {abbr: "CK", name: "Cook Islands", code: "+682"},
  {abbr: "CR", name: "Costa Rica", code: "+506"},
  {abbr: "CI", name: "Cote d'Ivoire", code: "+225"},
  {abbr: "HR", name: "Croatia", code: "+385"},
  {abbr: "CU", name: "Cuba", code: "+53"},
  {abbr: "CW", name: "Curacao", code: "+599"},
  {abbr: "CY", name: "Cyprus", code: "+357"},
  {abbr: "CZ", name: "Czech Republic", code: "+420"},
  {abbr: "DK", name: "Denmark", code: "+45"},
  {abbr: "DJ", name: "Djibouti", code: "+253"},
  {abbr: "DM", name: "Dominica", code: "+1-767"},
  {abbr: "DO", name: "Dominican Republic", code: "+1-809"},
  {abbr: "EC", name: "Ecuador", code: "+593"},
  {abbr: "EG", name: "Egypt", code: "+20"},
  {abbr: "SV", name: "El Salvador", code: "+503"},
  {abbr: "GQ", name: "Equatorial Guinea", code: "+240"},
  {abbr: "ER", name: "Eritrea", code: "+291"},
  {abbr: "EE", name: "Estonia", code: "+372"},
  {abbr: "ET", name: "Ethiopia", code: "+251"},
  {abbr: "FK", name: "Falkland Islands (Malvinas)", code: "+500"},
  {abbr: "FO", name: "Faroe Islands", code: "+298"},
  {abbr: "FJ", name: "Fiji", code: "+679"},
  {abbr: "FI", name: "Finland", code: "+358"},
  {abbr: "FR", name: "France", code: "+33", suggested: true},
  {abbr: "GF", name: "French Guiana", code: "+594"},
  {abbr: "PF", name: "French Polynesia", code: "+689"},
  {abbr: "TF", name: "French Southern Territories", code: "+262"},
  {abbr: "GA", name: "Gabon", code: "+241"},
  {abbr: "GM", name: "Gambia", code: "+220"},
  {abbr: "GE", name: "Georgia", code: "+995"},
  {abbr: "DE", name: "Germany", code: "+49", suggested: true},
  {abbr: "GH", name: "Ghana", code: "+233"},
  {abbr: "GI", name: "Gibraltar", code: "+350"},
  {abbr: "GR", name: "Greece", code: "+30"},
  {abbr: "GL", name: "Greenland", code: "+299"},
  {abbr: "GD", name: "Grenada", code: "+1-473"},
  {abbr: "GP", name: "Guadeloupe", code: "+590"},
  {abbr: "GU", name: "Guam", code: "+1-671"},
  {abbr: "GT", name: "Guatemala", code: "+502"},
  {abbr: "GG", name: "Guernsey", code: "+44"},
  {abbr: "GN", name: "Guinea", code: "+224"},
  {abbr: "GW", name: "Guinea-Bissau", code: "+245"},
  {abbr: "GY", name: "Guyana", code: "+592"},
  {abbr: "HT", name: "Haiti", code: "+509"},
  {abbr: "HM", name: "Heard Island and McDonald Islands", code: "+672"},
  {abbr: "VA", name: "Holy See (Vatican City State)", code: "+379"},
  {abbr: "HN", name: "Honduras", code: "+504"},
  {abbr: "HK", name: "Hong Kong", code: "+852"},
  {abbr: "HU", name: "Hungary", code: "+36"},
  {abbr: "IS", name: "Iceland", code: "+354"},
  {abbr: "IN", name: "India", code: "+91"},
  {abbr: "ID", name: "Indonesia", code: "+62"},
  {abbr: "IR", name: "Iran, Islamic Republic of", code: "+98"},
  {abbr: "IQ", name: "Iraq", code: "+964"},
  {abbr: "IE", name: "Ireland", code: "+353"},
  {abbr: "IM", name: "Isle of Man", code: "+44"},
  {abbr: "IL", name: "Israel", code: "+972"},
  {abbr: "IT", name: "Italy", code: "+39"},
  {abbr: "JM", name: "Jamaica", code: "+1-876"},
  {abbr: "JP", name: "Japan", code: "+81", suggested: true},
  {abbr: "JE", name: "Jersey", code: "+44"},
  {abbr: "JO", name: "Jordan", code: "+962"},
  {abbr: "KZ", name: "Kazakhstan", code: "+7"},
  {abbr: "KE", name: "Kenya", code: "+254"},
  {abbr: "KI", name: "Kiribati", code: "+686"},
  {abbr: "KP", name: "Korea, Democratic Peoples Republic of", code: "+850"},
  {abbr: "KR", name: "Korea, Republic of", code: "+82"},
  {abbr: "XK", name: "Kosovo", code: "+383"},
  {abbr: "KW", name: "Kuwait", code: "+965"},
  {abbr: "KG", name: "Kyrgyzstan", code: "+996"},
  {abbr: "LA", name: "Lao Peoples Democratic Republic", code: "+856"},
  {abbr: "LV", name: "Latvia", code: "+371"},
  {abbr: "LB", name: "Lebanon", code: "+961"},
  {abbr: "LS", name: "Lesotho", code: "+266"},
  {abbr: "LR", name: "Liberia", code: "+231"},
  {abbr: "LY", name: "Libya", code: "+218"},
  {abbr: "LI", name: "Liechtenstein", code: "+423"},
  {abbr: "LT", name: "Lithuania", code: "+370"},
  {abbr: "LU", name: "Luxembourg", code: "+352"},
  {abbr: "MO", name: "Macao", code: "+853"},
  {abbr: "MK", name: "Macedonia, the Former Yugoslav Republic of", code: "+389"},
  {abbr: "MG", name: "Madagascar", code: "+261"},
  {abbr: "MW", name: "Malawi", code: "+265"},
  {abbr: "MY", name: "Malaysia", code: "+60"},
  {abbr: "MV", name: "Maldives", code: "+960"},
  {abbr: "ML", name: "Mali", code: "+223"},
  {abbr: "MT", name: "Malta", code: "+356"},
  {abbr: "MH", name: "Marshall Islands", code: "+692"},
  {abbr: "MQ", name: "Martinique", code: "+596"},
  {abbr: "MR", name: "Mauritania", code: "+222"},
  {abbr: "MU", name: "Mauritius", code: "+230"},
  {abbr: "YT", name: "Mayotte", code: "+262"},
  {abbr: "MX", name: "Mexico", code: "+52"},
  {abbr: "FM", name: "Micronesia, Federated States of", code: "+691"},
  {abbr: "MD", name: "Moldova, Republic of", code: "+373"},
  {abbr: "MC", name: "Monaco", code: "+377"},
  {abbr: "MN", name: "Mongolia", code: "+976"},
  {abbr: "ME", name: "Montenegro", code: "+382"},
  {abbr: "MS", name: "Montserrat", code: "+1-664"},
  {abbr: "MA", name: "Morocco", code: "+212"},
  {abbr: "MZ", name: "Mozambique", code: "+258"},
  {abbr: "MM", name: "Myanmar", code: "+95"},
  {abbr: "NA", name: "Namibia", code: "+264"},
  {abbr: "NR", name: "Nauru", code: "+674"},
  {abbr: "NP", name: "Nepal", code: "+977"},
  {abbr: "NL", name: "Netherlands", code: "+31"},
  {abbr: "NC", name: "New Caledonia", code: "+687"},
  {abbr: "NZ", name: "New Zealand", code: "+64"},
  {abbr: "NI", name: "Nicaragua", code: "+505"},
  {abbr: "NE", name: "Niger", code: "+227"},
  {abbr: "NG", name: "Nigeria", code: "+234"},
  {abbr: "NU", name: "Niue", code: "+683"},
  {abbr: "NF", name: "Norfolk Island", code: "+672"},
  {abbr: "MP", name: "Northern Mariana Islands", code: "+1-670"},
  {abbr: "NO", name: "Norway", code: "+47"},
  {abbr: "OM", name: "Oman", code: "+968"},
  {abbr: "PK", name: "Pakistan", code: "+92"},
  {abbr: "PW", name: "Palau", code: "+680"},
  {abbr: "PS", name: "Palestine, State of", code: "+970"},
  {abbr: "PA", name: "Panama", code: "+507"},
  {abbr: "PG", name: "Papua New Guinea", code: "+675"},
  {abbr: "PY", name: "Paraguay", code: "+595"},
  {abbr: "PE", name: "Peru", code: "+51"},
  {abbr: "PH", name: "Philippines", code: "+63"},
  {abbr: "PN", name: "Pitcairn", code: "+870"},
  {abbr: "PL", name: "Poland", code: "+48"},
  {abbr: "PT", name: "Portugal", code: "+351"},
  {abbr: "PR", name: "Puerto Rico", code: "+1"},
  {abbr: "QA", name: "Qatar", code: "+974"},
  {abbr: "RE", name: "Reunion", code: "+262"},
  {abbr: "RO", name: "Romania", code: "+40"},
  {abbr: "RU", name: "Russian Federation", code: "+7"},
  {abbr: "RW", name: "Rwanda", code: "+250"},
  {abbr: "BL", name: "Saint Barthelemy", code: "+590"},
  {abbr: "SH", name: "Saint Helena", code: "+290"},
  {abbr: "KN", name: "Saint Kitts and Nevis", code: "+1-869"},
  {abbr: "LC", name: "Saint Lucia", code: "+1-758"},
  {abbr: "MF", name: "Saint Martin (French part)", code: "+590"},
  {abbr: "PM", name: "Saint Pierre and Miquelon", code: "+508"},
  {abbr: "VC", name: "Saint Vincent and the Grenadines", code: "+1-784"},
  {abbr: "WS", name: "Samoa", code: "+685"},
  {abbr: "SM", name: "San Marino", code: "+378"},
  {abbr: "ST", name: "Sao Tome and Principe", code: "+239"},
  {abbr: "SA", name: "Saudi Arabia", code: "+966"},
  {abbr: "SN", name: "Senegal", code: "+221"},
  {abbr: "RS", name: "Serbia", code: "+381"},
  {abbr: "SC", name: "Seychelles", code: "+248"},
  {abbr: "SL", name: "Sierra Leone", code: "+232"},
  {abbr: "SG", name: "Singapore", code: "+65"},
  {abbr: "SX", name: "Sint Maarten (Dutch part)", code: "+1-721"},
  {abbr: "SK", name: "Slovakia", code: "+421"},
  {abbr: "SI", name: "Slovenia", code: "+386"},
  {abbr: "SB", name: "Solomon Islands", code: "+677"},
  {abbr: "SO", name: "Somalia", code: "+252"},
  {abbr: "ZA", name: "South Africa", code: "+27"},
  {abbr: "GS", name: "South Georgia and the South Sandwich Islands", code: "+500"},
  {abbr: "SS", name: "South Sudan", code: "+211"},
  {abbr: "ES", name: "Spain", code: "+34"},
  {abbr: "LK", name: "Sri Lanka", code: "+94"},
  {abbr: "SD", name: "Sudan", code: "+249"},
  {abbr: "SR", name: "Suriname", code: "+597"},
  {abbr: "SJ", name: "Svalbard and Jan Mayen", code: "+47"},
  {abbr: "SZ", name: "Swaziland", code: "+268"},
  {abbr: "SE", name: "Sweden", code: "+46"},
  {abbr: "CH", name: "Switzerland", code: "+41"},
  {abbr: "SY", name: "Syrian Arab Republic", code: "+963"},
  {abbr: "TW", name: "Taiwan, Republic of China", code: "+886"},
  {abbr: "TJ", name: "Tajikistan", code: "+992"},
  {abbr: "TH", name: "Thailand", code: "+66"},
  {abbr: "TL", name: "Timor-Leste", code: "+670"},
  {abbr: "TG", name: "Togo", code: "+228"},
  {abbr: "TK", name: "Tokelau", code: "+690"},
  {abbr: "TO", name: "Tonga", code: "+676"},
  {abbr: "TT", name: "Trinidad and Tobago", code: "+1-868"},
  {abbr: "TN", name: "Tunisia", code: "+216"},
  {abbr: "TR", name: "Turkey", code: "+90"},
  {abbr: "TM", name: "Turkmenistan", code: "+993"},
  {abbr: "TC", name: "Turks and Caicos Islands", code: "+1-649"},
  {abbr: "TV", name: "Tuvalu", code: "+688"},
  {abbr: "UG", name: "Uganda", code: "+256"},
  {abbr: "UA", name: "Ukraine", code: "+380"},
  {abbr: "AE", name: "United Arab Emirates", code: "+971"},
  {abbr: "GB", name: "United Kingdom", code: "+44"},
  {abbr: "TZ", name: "United Republic of Tanzania", code: "+255"},
  {abbr: "US", name: "United States", code: "+1", suggested: true},
  {abbr: "UY", name: "Uruguay", code: "+598"},
  {abbr: "VI", name: "US Virgin Islands", code: "+1-340"},
  {abbr: "UZ", name: "Uzbekistan", code: "+998"},
  {abbr: "VU", name: "Vanuatu", code: "+678"},
  {abbr: "VE", name: "Venezuela", code: "+58"},
  {abbr: "VN", name: "Vietnam", code: "+84"},
  {abbr: "WF", name: "Wallis and Futuna", code: "+681"},
  {abbr: "EH", name: "Western Sahara", code: "+212"},
  {abbr: "YE", name: "Yemen", code: "+967"},
  {abbr: "ZM", name: "Zambia", code: "+260"},
  {abbr: "ZW", name: "Zimbabwe", code: "+263"},
];

export default CountryCodes;
