const PrivacyStatement = () => (
  <div className="privacy-statement">

    <h2>Foundation Source Privacy Statement</h2>
    <p>Last updated and effective as of March 17, 2023</p>

    <p className="privacy-indent">This Privacy Statement (the “Privacy Statement”) applies to users of the online
      portals Foundation Source Online®, Impactfully, Foundation Source Applications, Foundation Source Outcomes, and
      Foundation Source Giving Hub (each a “Portal” and, together, the “Portals”) provided by Foundation Source
      Philanthropic Services Inc. The Portals contain certain materials, databases, management tools and reporting
      tools, and, as applicable, associated media, printed materials, and online or electronic documentation, each of
      which contains text, files, images, photos, video, works of authorship, applications, and other materials of
      Foundation Source and its licensors (collectively, the “Features”). This Privacy Statement is to inform you of our
      information and dissemination practices and policies. This Privacy Statement also contains terms of use for
      Foundation Source Applications and Foundation Source Outcomes users via Foundation Source Giving Hub. If you are a
      user of Foundation Source Online®, your access and use of the Portals is also governed by that certain Services
      Agreement between Foundation Source and the foundation on whose behalf you are accessing the Website. Some of the
      provisions of this Privacy Statement may not apply to your use of a particular Portal. If you are a user of
      Impactfully, your access to and use of the Portal is also subject to the Impactfully Services Agreement and the
      Impactfully Terms of Access. If you are a user of Foundation Source Online®, your access to and use of the Portal
      is also subject to the Foundation Source Online® Terms of Access.</p>

    <p>By using the Portals, you are accepting the practices and policies described in this Privacy Statement.
      Foundation Source may change any part of this Privacy Statement at any time. The changes will appear in this
      Privacy Statement and will be effective immediately. Your continued use of the Portals after such changes are
      posted or made will constitute your acceptance of these changes. Therefore, you should read this Privacy Statement
      each time you access the Portals, before you begin using the Portals. You may print a copy of this Privacy
      Statement for your reference.</p>

    <p>This Privacy Statement does not apply to the practices of entities that Foundation Source does not own or
      control, or to individuals not under Foundation Source's supervisory control.</p>

    <h3 style={{fontSize: "22px"}}>Contents:</h3>

    <ul>
      <li><a href="#collect">How do we collect information?</a></li>
      <li><a href="#use">How do we use the information?</a></li>
      <li><a href="#disclose">With whom do we share or disclose information?</a></li>
      <li><a href="#control">How can I control the use of my information?</a></li>
      <li><a href="#view">How can I view and correct my information?</a></li>
      <li><a href="#child">Children's Privacy/Note to Parents</a></li>
      <li><a href="#retention">Data Retention</a></li>
      <li><a href="#foreign">Use of the Portals from Outside the United States</a></li>
      <ul>
        <li><a href="#california">California Online Privacy Protection Act (OPPA)</a></li>
      </ul>
      <li><a href="#ccpa">CCPA</a></li>
      <li><a href="#ccpa">CPRA</a></li>
      <li><a href="#eea">EEA, Switzerland and UK users</a></li>
      <li><a href="#apps">Foundation Source Applications, Foundation Source Outcomes, and Foundation Source Giving Hub
        Users</a></li>
    </ul>

    <div id="collect" className="anchor m-t-lg">
      <h3>How do we collect information?</h3>
      <h4>Personally Identifiable Information</h4>
      <p>Any information that can be used to uniquely identify, contact, or locate a single individual, or can be used
        with other sources to uniquely identify a single individual, including, but not limited to:
        <ul>
          <li>first and last name</li>
          <li>social security number</li>
          <li>street addresses</li>
          <li>email addresses</li>
          <li>phone numbers</li>
          <li>account numbers</li>
          <li>user ID used to access the Portals</li>
        </ul>
        (together, “Personally Identifiable Information”) is provided to us voluntarily by you when you:
        <ul>
          <li>Submit paper or electronic applications for service to Foundation Source or one of its affiliates</li>
          <li>Register as a user of the Portals or log into your online account</li>
          <li>Upload documents and/or input data to the Features</li>
          <li>Communicate with us by email or other medium</li>
          <li>Fill out and submit online forms</li>
        </ul>
      </p>

      <h4>Non-Personally Identifiable Information</h4>
      <p>When you use the Portals or interact with us electronically, information that does not identify and cannot be
        used to identify a unique individual or group of individuals directly, including information that may be derived
        from Personally Identifiable Information as long as the Personally Identifiable Information is completely
        removed (“Non-personally Identifiable Information”) about you or the device used to access the Portals is
        collected by the Portals' servers and/or through the use of small files that are stored on the computer or
        device you use to access the Portals that enable the Portals to identify you for purposes of customizing the
        features of the Portals, automating data entry functions, or regulating access to particular features
        (“Cookies”) that are transmitted to the device by the Portals. This may include recording information such as:
        <ul>
          <li>The time and date you accessed the Portals and the duration of your use of the Portals</li>
          <li>Your computer's or mobile device's operating system (e.g., Windows, Macintosh, or Android), browser type
            (e.g., Safari or Internet Explorer), and IP Address
          </li>
          <li>The series of letters and numbers separated by periods, for example <em>cable.company.com</em>, that is
            used to identify a computer network on the Internet (the “Domain Name”) from which you accessed the Portals
          </li>
          <li>The unique number used to identify a device connected to the Internet (“IP Address”) from which you
            visited the Portals
          </li>
          <li>The web pages you visit on the Portals or information you search for</li>
          <li>The content you may view or download from the Portals</li>
          <li>Your responses to online surveys or questionnaires that are billed as "anonymous"</li>
        </ul>

        Foundation Source may aggregate and/or de-identify any Personally Identifiable Information that we collect, such
        that the information no longer identifies any specific individual. We may use, disclose and otherwise process
        such information for our own legitimate business purposes – including historical and statistical analysis and
        business planning – without restriction. For example, in an ongoing effort to better understand and serve users
        of the Portals, Foundation Source may conduct research on the demographics, interests, and behavior of visitors
        to the Portals based on the Personally Identifiable Information and other information provided to us. This
        research may be compiled and analyzed on an aggregated basis, and Foundation Source may share this aggregated
        data with its affiliates, agents, and partners. Foundation Source may also disclose aggregated user statistics
        in order to describe its products, services, and initiatives to current and prospective partners, and to other
        third parties for other lawful purposes.
      </p>
    </div>

    <div id="use" className="anchor m-t-lg">
      <h3>How do we use the information?</h3>
      <p>Information that we collect, or that you provide to us, may be used in the following manner. To see what
        options you have in the use of your information, see the section "How can I control the use of my
        information."</p>
      <h4>Personally Identifiable Information</h4>

      <p>Personally Identifiable Information may be used in the following manner:
        <ul>
          <li>Advertising or marketing our products and services to you. For example: notifying you of new features or
            products on the Portals via email or direct mail
          </li>
          <li>For administrative purposes, such as preparing forms for your benefit</li>
          <li>Where applicable, to perform services that we are required to perform under a services agreement with a
            foundation
          </li>
          <li>Where applicable, to fulfill Foundation Source’s obligations under the Terms of Access</li>
          <li>Communicating with you via email, direct mail, or phone for the express purpose of providing Foundation
            Source Online® users with Foundation Source services and providing Impactfully users with the Features or
            support in connection with the Features
          </li>
          <li>Providing feedback or confirmations via email on transactions performed on the Portals</li>
        </ul>
      </p>

      <h4>Non-Personally Identifiable Information</h4>

      <p>Non-Personally Identifiable Information stored in our servers, or in Cookies stored on your computer or device,
        may be used to:
        <ul>
          <li>
            Deliver products and services associated with the Portals, for example:
            <ul>
              <li>Recognize you when you return to the Portals in order to provide customized content</li>
              <li>For Foundation Source Online® users, process transactions, such as granting on your behalf, when
                instructed by you to do so
              </li>
              <li>Display our ads or those of our partners when you visit other sites on the Internet.</li>
              <li>Provide customer services</li>
            </ul>
          </li>
          <li>
            Conduct data analysis using tools for analytics. For example, Non-Personally Identifiable Information is
            aggregated and used to:
            <ul>
              <li>Better understand the Portals' usage by our customers for the purposes of improving the Portals</li>
              <li>Produce analytical reports containing only Non-Personally Identifiable Information that we may share
                with our business partners. (see "With whom do we share information" below)
              </li>
            </ul>
          </li>
        </ul>
      </p>
    </div>

    <div id="disclose" className="anchor m-t-lg">
      <h3>With whom do we share information?</h3>
      <p>Foundation Source will not sell, rent, trade, or otherwise disclose any Personally Identifiable Information to
        anyone outside of Foundation Source, its affiliates, subsidiaries, agents, and including without limitation,
        contractors providing services to us and only to the extent necessary for our contractors to provide those
        services, except as set forth below:

        <ul>
          <li>
            We may share Personally Identifiable Information in the following circumstances:
            <ul>
              <li>Where we believe that doing so is necessary or appropriate to satisfy any applicable law, regulation,
                legal process or governmental request;
              </li>
              <li>To detect, prevent or otherwise address fraud, security, or technical issues;</li>
              <li>To protect our rights and safety and the rights and safety of our users or others.</li>
              <li>In connection with communications with your bank or other financial institution</li>
              <li>In connection with communications with a government agency (including, without limitation, the
                Internal Revenue Service and state regulatory authorities)
              </li>
              <li>In connection with Foundation Source's participation in mergers and acquisitions. As such, it is
                possible that another entity or person may acquire Foundation Source or the business assets comprising
                Foundation Source. In such case, Personally Identifiable Information may be shared with the potential
                acquirer and/or other reputable parties in connection with such corporate transactions
              </li>
            </ul>
          </li>
          <li>
            We may share or otherwise use aggregated or non-aggregated Non-Personally Identifiable Information:
            <ul>
              <li>With our affiliates, subsidiaries, and partners</li>
              <li>As part of statistical or analytical reports published in various media, in electronic or print
                format
              </li>
            </ul>
          </li>
        </ul>

        We will not share, sell, rent, swap, or authorize any third party to use your email address for commercial
        purposes without your permission. User information may be shared with third-party vendors to the extent
        necessary to provide and improve web services or other communications to users. For example, we use third
        parties to generate reports on site usage, web traffic, user behavior, and user interests in order to optimize
        the Portals for our users. We also use geographic, demographic, and interest-based reports of users of the
        Portals to create custom audience and remarketing lists. We prohibit any third parties who receive user
        information for this purpose from using or sharing user information for any purpose other than providing
        services for the benefit of our users.
      </p>
    </div>

    <div id="control" className="anchor m-t-lg">
      <h3>How can I control the use of my information?</h3>
      <p>You can control the following uses of your information.</p>

      <h4>Controlling the use of Personally Identifiable Information</h4>

      <p>
        <ul>
          <li>
            Use of Email Address(es)
            <ul>
              <li>Marketing or promotional emails sent by Foundation Source will contain an "unsubscribe" link in the
                body of the email. Once you click on the link, we will remove the associated email address from future
                emails within 30 days, as required by law, or sooner if possible.
              </li>
              <li>Confirmation emails may be sent to you to confirm or complete a transaction initiated by you on the
                Portals. These emails cannot be turned off as they comprise part of the Portals' service delivery and
                security (such as sending a password reset).
              </li>
            </ul>
          </li>
        </ul>
      </p>

      <h4>Controlling the use of Non-Personally Identifiable Information</h4>

      <p>
        <ul>
          <li>
            Disabling the use of Cookies in your web browser.
            <ul>
              <li>If you would rather not receive Cookies, you may adjust your web browser to either alert you to the
                use of Cookies or refuse to accept Cookies entirely. Please be advised that disabling Cookies may cause
                some parts of the Portals not to function properly or permit you access. Please consult your web
                browser's help documentation for instructions on managing Cookies.
              </li>
            </ul>
          </li>
          <li>Use of aggregated or non-aggregated, Non-Personally Identifiable Information can be limited by you by
            emailing <a href="mailto:privacy@foundationsource.com">privacy@foundationsource.com</a>.
          </li>
          <li>You may opt out of Google's use of certain Cookies by visiting the Google advertising opt out page at <a
            href="http://www.google.com/privacy/ads/" target="_blank">www.google.com/privacy/ads/</a>.
          </li>
          <li>Alternatively, you may opt out of many vendors' (including Google) use of Cookies by visiting the Network
            Advertising Initiative opt out page at <a href="http://www.networkadvertising.org/managing/opt_out.asp"
                                                      target="_blank">www.networkadvertising.org/managing/opt_out.asp</a>.
          </li>
        </ul>
      </p>

      <h4>Links to third-party websites</h4>
      <p>
        <ul>
          <li>The Portals or the Features may contain hyperlinks to sites operated by persons other than Foundation
            Source. Such hyperlinks are provided for your reference and convenience only and do not mean that Foundation
            Source endorses the content on or the operators of these sites ("Linked Websites"). Foundation Source is not
            responsible for the content, privacy policies, or information collection practices of any Linked Websites.
          </li>
          <li>Please note that the privacy policies and other policies of the Linked Websites may differ from those of
            Foundation Source. Should you decide to access such Linked Websites, you do so at your own risk. We
            encourage you to review and understand their privacy practices and all terms and conditions of agreement
            before providing them with any information. Foundation Source is not responsible for any such provisions,
            and expressly disclaims any and all liability related to such provisions made on the Linked Websites.
          </li>
        </ul>
      </p>

      <h4>How We Attempt to Safeguard Personally Identifiable Information; No Liability for third-party actions</h4>
      <p>We maintain – and ask that any third-party partners who may store information on our behalf maintain –
        administrative, technical and physical safeguards for the Portals and the Features designed to protect your
        Personally Identifiable Information against theft, loss, misuse or unauthorized access or disclosure and also
        use reasonable efforts to safeguard the confidentiality of Personally Identifiable Information. Foundation
        Source will have no liability for disclosure of Personally Identifiable Information due to errors in
        transmission or unauthorized acts of third parties, including interception of email sent/transmitted to
        Foundation Source by users via the internet.</p>
    </div>

    <div id="view" className="anchor m-t-lg">
      <h3>How can I view and correct my information?</h3>
      <p>You can have your account information updated at any time, if you are a Foundation Source Online® user, by
        contacting Client Services or your Private Client Advisor, if you have one, at 800-839-0054. If you are an
        Impactfully user, you can update your own information.</p>
      <p>Note: If you ask that your account be deleted, you will not receive any promotional materials from Foundation
        Source or any third party as described by this Privacy Statement based on information you have provided to
        Foundation Source through the Portals. This will take place within thirty (30) days, as required by law, or
        sooner if possible.</p>
    </div>

    <div id="child" className="anchor m-t-lg">
      <h3>Children's Privacy/Note To Parents</h3>
      <p>Foundation Source is a commercial business and the Portals are neither designed nor intended to collect
        personal information from children under the age of sixteen. If you are the parent or guardian of a child under
        sixteen years of age and believe that such child may have disclosed Personally Identifiable Information to us,
        please contact us at <a href="mailto:privacy@foundationsource.com">privacy@foundationsource.com</a>.</p>
    </div>

    <div id="retention" className="anchor m-t-lg">
      <h3>Data Retention</h3>
      <p>We will retain your information for as long as needed, or otherwise as is reasonable and customary given the
        nature of the relationship you have with Foundation Source.</p>
    </div>

    <div id="foreign" className="anchor m-t-lg">
      <h3>Use of the Portals from Outside the United States</h3>
      <p>We do not represent or warrant that the Portals, or any part thereof, are appropriate or available for use in
        any particular geographic location. If you choose to access the Portals, you do so on your own initiative and at
        your own risk, and are responsible for complying with all local laws, rules, and regulations applicable in your
        jurisdiction. The Portals are hosted in the United States and are governed by United States law. If you are
        visiting the Portals from outside the United States, your information will be transferred to, stored and
        processed in the United States where our servers are located and our central database is operated. The data
        protection and other laws of the United States and other countries might not be as comprehensive as those in
        your country. By using the Portals, you consent to your information being transferred to our facilities and to
        the facilities of those third parties with whom we share it as described in this Privacy Statement.</p>
    </div>

    <div id="california" className="anchor m-t-lg">
      <h3>California Online Privacy Protection Act (OPPA)</h3>
      <p>In accordance with the California OPPA provisions, this online Privacy Statement:
        <ul>
          <li>Is conspicuously positioned on the Portals</li>
          <li>Lists the categories of Personally Identifiable Information that are collected</li>
          <li>Lists with whom we share collected Personally Identifiable Information</li>
          <li>Describes how Personally Identifiable Information can be reviewed or modified</li>
          <li>Describes how we protect Personally Identifiable Information</li>
          <li>Clearly states when this statement was last modified and when it went into effect</li>
        </ul>
      </p>
    </div>

    <div id="ccpa" className="anchor">
      <h3>California Privacy Legislation</h3>
      <p>The California Consumer Privacy Act (“CCPA”) and the California Privacy Rights Act (CPRA) provide certain
        California residents who are natural persons (“California Residents”) with the additional rights described in
        this section (CCPA and CPRA referred to collectively as “California’s Privacy Legislation”).</p>

      <p>For the purposes of this section, Personally Identifiable Information shall include the categories of
        information included in the definition of “personal information” under the California Privacy Legislation.</p>

      <p>Under the California Privacy Legislation, twice per year, California Residents have the right to know and see
        what Personally Identifiable Information Foundation Source has collected about them over the past 12 months,
        including:

        <ul>
          <li>The categories of Personally Identifiable Information collected;</li>
          <li>The categories of sources from which the Personally Identifiable Information has been collected;</li>
          <li>The business or commercial purpose for collecting their Personally Identifiable Information;</li>
          <li>The categories of third parties with whom Foundation Source has shared their Personally Identifiable
            Information; and
          </li>
          <li>The specific pieces of Personally Identifiable Information Foundation Source has collected about them.
          </li>
        </ul>
      </p>

      <p>Under the California Privacy Legislation, California Residents also have the right to:</p>
      <ol>
        <li>Request Foundation Source delete their Personally Identifiable Information and to cause Foundation Source to
          direct its service providers to do the same; and
        </li>
        <li>View and make correction to the Personally Identifiable Information we hold, and</li>
        <li>Limit the sensitive personal information we collect; and</li>
        <li>Access the Personally Identifiable Information we collect and choose to opt-out from this collection; and
        </li>
        <li>Request that we provide you a copy of the Personally Identifiable Information we have on you; and</li>
        <li>Request that we disclose what Personally Identifiable Information is disclosed for a business purpose, or
          sold or shared, and to whom; and
        </li>
        <li>In regard to your Personally Identifiable Information, opt-out from the sale or sharing with third
          parties.
        </li>
      </ol>

      <p>Please note that in regard to the right of “data deletion”, there are a number of exceptions, however, that
        include, without limitation, when the Personally Identifiable Information is retained by Foundation Source or a
        third party to:
        <ul>
          <li>Complete transactions with the California Resident;</li>
          <li>Provide the California Resident with services;</li>
          <li>Perform under a contract with the California Resident;</li>
          <li>Protect against security incidents, or malicious, deceptive, fraudulent or illegal activity;</li>
          <li>Debug or fix errors in its systems, website or software;</li>
          <li>Comply with a legal obligation;</li>
          <li>Use for internal purposes that are reasonably aligned with the expectations of the California Resident
            based on the California Resident’s relationship with Foundation Source; or
          </li>
          <li>Use internally in a lawful manner that is compatible with the context in which the California Resident
            provided the information.
          </li>
        </ul>
      </p>

      <p>During the past 12 months, Foundation Source may have collected the following types of Personally Identifiable
        Information about California Residents:</p>

      <table className="m-t-lg table-grey-border">
        <thead>
        <tr>
          <th><b>Category</b></th>
          <th><b>Types of Information Collected from California Residents:</b></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Identifiers</td>
          <td>name, postal address, email address, social security number</td>
        </tr>

        <tr>
          <td>Categories of personal information listed in the California Customer Records statute (Cal. Civ. Code §
            1798.80(e))
          </td>
          <td>name, signature, social security number, address, telephone number and occupation</td>
        </tr>

        <tr>
          <td>Commercial information</td>
          <td>records of services purchased, obtained, or considered</td>
        </tr>

        <tr>
          <td>Internet or other similar network activity</td>
          <td>browsing history, search history, and information on California Residents’ interaction with the Portals.
          </td>
        </tr>

        <tr>
          <td>Geolocation data</td>
          <td>physical location</td>
        </tr>

        <tr>
          <td>Professional or employment-related information</td>
          <td>occupation</td>
        </tr>
        </tbody>
      </table>

      <p className="m-t-lg">California Residents have the right not to be discriminated against for exercising any of
        their rights under California’s Privacy Legislation.</p>

      <p>If a California Resident requests erasure of such person’s Personally Identifiable Information, Foundation
        Source may not be able to perform its obligations under a services agreement with a foundation, if you have
        one.</p>

      <p>Foundation Source expects to respond to California Residents’ requests to see what Personally Identifiable
        Information Foundation Source collects about them and to delete their Personally Identifiable Information within
        45 days of receiving the request. If Foundation Source requires more time, it will inform the requesting
        California Resident of the reason and extension period in writing.</p>

      <p>To exercise their rights under the California Privacy Legislation, California Residents can submit a request by
        completing and submitting this <a href="https://info.foundationsource.com/ccpa"
                                          className="terms-subtitle">form</a>.</p>
    </div>

    <div id="eea" className="anchor m-t-lg">
      <h3>EEA, Switzerland and UK Users</h3>
      <p>The following provisions apply only to users residing in the European Economic Area (“EEA”), Switzerland and
        the United Kingdom (“UK”), and provides information to your rights pursuant to General Data Protection
        Regulation (“GDPR”). Pursuant to GDPR, we are required to provide those residing in the EEA, Switzerland and the
        UK (hereinafter, the “EU Users”) additional information and rights related to the Personally Identifiable
        Information we collect about them. We summarize such information and rights below.</p>

      <h4>How to Contact Us</h4>

      <p>Foundation Source (the Data Controller) is the owner and operator of the Portals. If you need to contact
        Foundation Source for any reason related to this Privacy Statement or your use of the Portals, please notify
        Foundation Source at: <a href="mailto:privacy@foundationsource.com">privacy@foundationsource.com</a>.</p>

      <h4>Legal Grounds for Processing Personal Data</h4>

      <p>Foundation Source will process Personally Identifiable Information that we receive from EU Users to perform our
        obligations under the Services Agreement that is in place between Foundation Source and the foundation on whose
        behalf you are accessing the Portals, if you have one.</p>

      <h4>Your Information and Your Choices; Withdrawal of Consent</h4>

      <p>GDPR provides EU Users with additional rights in respect to the Personally Identifiable Information we hold
        about you, including the right i) to be informed about the Personally Identifiable Information we have
        collected, ii) to request a copy of the Personally Identifiable Information, iii) to have your Personally
        Identifiable Information deleted, and iv) to lodge a complaint about our processing of your Personally
        Identifiable Information with your local supervisory authority. Upon appropriate written request we will update
        or amend your Personally Identifiable Information, but we reserve the right to use any information previously
        obtained to verify your identity or take other actions that we believe are appropriate and lawful. You may
        access, correct, or request deletion of your Personally Identifiable Information – or request that we stop any
        processing of your Personally Identifiable Information - by contacting us at the email addresses listed above.
        We will respond to your request within a reasonable timeframe.</p>

      <p>Please note we may need to retain certain information for record keeping purposes, and there may also be
        residual information that will remain within our databases and other records, which will not be removed from
        such locations. Additionally, there may be circumstances in which we are not legally required to comply with
        your request because of i) the laws in your jurisdiction, ii) obligations related to the laws, regulations or
        rules of the United States or entities/governing bodies of the United States, or iii) the laws of the United
        States because of exemptions provided in data protection legislation in general, and by GDPR in particular.
        Finally, please note that we are not responsible for removing or deleting information from the databases of
        third parties (such as service providers) with whom we have shared information about you, but we will make the
        request on your behalf for such removal/deletion.</p>
    </div>

    <div id="apps" className="anchor m-t-lg">
      <h3>Foundation Source Applications, Foundation Source Outcomes, and Foundation Source Giving Hub Users</h3>

      <h4>Grant of License</h4>

      <p>You hereby grant to Foundation Source a worldwide, royalty-free, perpetual, transferable, sublicenseable,
        non-exclusive right and license to use, copy, store and modify any information or content you upload in any
        form, and to give such information to other users, including the applicable foundation, which users shall also
        have the right to use, copy, store, and modify such information and content. You represent and warrant that you
        have the right and authority to upload or post content to the Portals and to grant Foundation Source the
        foregoing license without the consent of any third party.</p>

      <h4>Security</h4>

      <p className="terms-subtitle"><em>If you been provided with a password to access the Portals, the following terms
        apply:</em></p>

      <p>By using the Portals, you are representing that you have been authorized to access and use the Portals by a
        client of Foundation Source.</p>

      <p>Your password to access the Portals is the property of Foundation Source and for security reasons must not be
        disclosed to any other person. You are responsible for maintaining the confidentiality of your password and for
        all activities that occur under your password or your profile (“User Profile”) (including the activities of any
        other person under your account). You acknowledge and agree that uploading any false, misleading, or
        misrepresentative data about you, your organization, or any person affiliated with your organization is a
        violation of the Privacy Statement, which shall give Foundation Source the right to terminate your account.</p>

      <p>You agree to: (a) immediately notify Foundation Source of (i) any unauthorized use of your password or your
        User Profile, (ii) any unauthorized use of the User Profile or password of any other person affiliated with your
        organization of which you become aware, or (iii) any other breach of security; and (b) ensure that you exit from
        your account at the end of each session. Foundation Source cannot and will not be liable for any loss or damage
        arising from your failure to comply with this requirement.</p>

      <p>Foundation Source may terminate your account or place your account on hold in order to protect you, Foundation
        Source or other users of the Portals from identity theft or other fraudulent activity.</p>

      <h4>Usage Rules and Limitations</h4>

      <p>Except as explicitly authorized herein, you may not, either directly or through the use of any device,
        software, internet site, web-based service, or other means copy, record, download, stream capture, archive,
        reproduce, distribute, upload, modify, display, translate, broadcast, perform, publish, license, create
        derivative works from, offer for sale or use, transmit or retransmit, the Portals or the Features. You also
        agree not to (and agree not to permit others to) circumvent, remove, alter, deactivate, degrade or thwart any of
        the content protections on the Portals; use any robot, spider, scraper or other automated means to access the
        Portals; decompile, reverse engineer or disassemble any software or other products or processes accessible
        through the Portals; insert any code or product or manipulate the content of the Portals in any way; or use any
        data mining, data gathering or extraction method. In addition, you agree not to upload, post, e-mail or
        otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of any
        computer software or hardware or telecommunications equipment associated with the Portals, including any
        software viruses or any other computer code, files or programs. Foundation Source may terminate your use of the
        Portals if you violate this Privacy Statement or are engaged in illegal or fraudulent use of the Portals. You
        also agree not to upload or post to the Portals: (a) infringing, defamatory, obscene, pornographic, threatening,
        abusive, violent, illegal, rude, harassing, or otherwise improper content, (b) viruses or other harmful items,
        or (c) content with a commercial purpose (such as offers to sell products or services).</p>

      <p>Any unauthorized commercial use of the Portals, Foundation Source’s servers or Internet infrastructure by you
        or by persons affiliated with your organization is expressly prohibited. You will not take any action that
        imposes an unreasonable or disproportionately large load on Foundation Source’s infrastructure as determined by
        Foundation Source in its sole discretion.</p>

      <p>Foundation Source owns and retains all rights to the Portals and the Features are owned, controlled or licensed
        by Foundation Source. The Portals and the Features are also protected by copyright, trademark and other
        intellectual property laws. You may not either directly or through the use of any device, software, internet
        site, web-based service, or other means remove, change, delete, alter, bypass, avoid, interfere with, or
        circumvent any copyright, trademark, or other proprietary notices marked on the Features.</p>

      <p>You agree to indemnify and hold harmless each of Foundation Source, its affiliates, officers, directors and
        employees, from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or
        arising out of your use of the Portals, the violation of this Section by you, or the infringement by you of any
        intellectual property or other right of any person or entity.</p>

      <h4>Limitation of Liability</h4>

      <p>Foundation Source is not responsible for any of your liabilities related to the use of the Portal, including to
        your vendors, consultants or other third parties.</p>

      <p>Due to the number of sources from which the Features are developed, and the inherent hazards of electronic
        distribution, there may be delays, omissions or inaccuracies in the Portals and Features. The Portals and the
        Features are provided "as is" and "as available," without any warranties, express or implied. Foundation Source
        and its affiliates, agents and licensors cannot and do not make any representation with respect to or warrant
        the accuracy, completeness, currentness, timeliness, noninfringement, title, merchantability or fitness for a
        particular purpose of the Portals or the Features, and Foundation Source hereby disclaims any such express or
        implied warranties. FOUNDATION SOURCE DISCLAIMS ALL LIABILITY FOR DAMAGES CAUSED BY ANY INTERRUPTION OR ERRORS
        IN FUNCTIONING AND MAKES NO GUARANTIES THAT SUCH INTERRUPTION OR ERRORS WILL BE CORRECTED.</p>

      <p>You expressly consent to Foundation Source’s storage of information and data, which will involve transmission
        over the Internet, and over various networks, only part of which may be owned and/or operated by Foundation
        Source. To the extent permitted by law, Foundation Source is not responsible for any information or data of
        yours or of your organization which is delayed, lost, altered, intercepted or stored during the transmission of
        any information or data whatsoever across public networks not owned or operated by Foundation Source, including,
        the Internet, third party websites, and the Foundation’s local network, if any. To the extent permitted by law,
        the Foundation agrees that Foundation Source is not in any way responsible for any interference with the
        Foundation’s use of or access to the Portals or security breaches arising from or attributable to the Internet,
        and the Foundation waives any and all claims against Foundation Source in connection therewith. FOUNDATION
        SOURCE DOES NOT CONTROL THE DEVICES OR COMPUTER OR THE INTERNET OVER WHICH YOU MAY CHOOSE TO ENTER CONFIDENTIAL
        OR PERSONAL INFORMATION AND CANNOT, THEREFORE, PREVENT INTERCEPTIONS OR COMPROMISES TO YOUR INFORMATION WHILE IN
        TRANSIT. FOUNDATION SOURCE MAKES NO GUARANTEE AS TO THE SECURITY, INTEGRITY, OR CONFIDENTIALITY OF ANY
        INFORMATION TRANSMITTED TO OR FROM THE PORTAL. IF YOU ARE CONCERNED ABOUT KEEPING SUCH INFORMATION OR CONTENT
        CONFIDENTIAL, DO NOT UPLOAD IT.</p>

      <p>FOUNDATION SOURCE SHALL HAVE NO LIABILITY FOR ANYTHING THAT YOU CREATE, TRANSMIT OR DISPLAY WHILE USING THE
        PORTAL OR THE FEATURES, AND FOR THE CONSEQUENCES OF YOUR ACTIONS (INCLUDING ANY LOSS OR DAMAGE THAT THE
        FOUNDATION MAY SUFFER) BY DOING SO. FOUNDATION SOURCE SHALL NOT BE LIABLE FOR ANYTHING YOU GENERATE USING THE
        FEATURES AND MAKES NO GUARANTIES AS TO THE COMPLETENESS, CORRECTNESS, RELIABILITY AND/OR ACCURACY OF ANYTHING
        YOU GENERATE USING THE PORTAL OR THE FEATURES.</p>

      <h4>Governing Law</h4>

      <p>The Portals are owned and operated by Foundation Source from its offices in Connecticut. Your use of the
        Portals, and any related legal action, shall be governed by the laws of the State of Delaware, without regard to
        conflict of laws principles. The sole jurisdiction and venue for any litigation arising out of your use of or
        inquiries to the Portals shall be an appropriate federal or state court located in the State of Connecticut.</p>

      <p>These are provisions that could be applicable to these users, but that we did not include in the interest of
        brevity:</p>

      <p>In no event will Foundation Source be liable for any indirect, incidental, special, exemplary, or consequential
        damages (including loss of profits), even if it has been advised of the possibility of such damages.</p>

      <p>Foundation Source may change, suspend, or discontinue - temporarily or permanently - some or all of the
        Features, with respect to any or all users, at any time without notice. Foundation Source reserves the right to
        substitute any Feature on the Portal with another of equal or better quality. You agree that Foundation Source
        will not be liable to you for any modification, suspension, or discontinuance of the Features.</p>

      <p>Foundation Source has the right in its sole discretion to determine the appearance, design, form,
        functionality, and all other aspects of the Portal and/or the Features, including by redesigning, modifying,
        removing, or restricting access to any of them. You agree that the form and substance of the Portal and the
        Features provided to you may change from time to time without prior notice to you. In addition, you acknowledge
        and agree that the Portal may not be available from time to time due to maintenance or service or network
        disruptions, and by your use of the Portal, you agree to hold harmless Foundation Source for any such service
        interruptions.</p>
    </div>

    <div className="m-t-lg">&copy; {new Date().getFullYear()} All Rights Reserved. Foundation Source Philanthropic Services Inc.
      Foundation Source is a registered trademark of Foundation Source Philanthropic Services Inc.
    </div>

  </div>
)

export default PrivacyStatement;