import { AxiosError } from 'axios';
import {clearStoredSession} from "../../util/storage";
import config from "../../config";

const responseError = (error: AxiosError) => {
	if (error.response?.status === 401) {
		// server returned an Unauthorized status.  The session is most likely expired.
		clearStoredSession();
		window.location.href = `${config.baseUrl}/login`;
	}

	return Promise.reject(error.response?.data);
};

export default responseError;
