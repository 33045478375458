import LoginComponent from '../../components/Login'
import slashOverlay from '../../assets/slash-pattern-overlay.svg';
import CheckMark from "../../assets/fs_checkmark.png";

import React from "react";
import {Box, Chip, Grid, Link, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useAuthenticator} from "@aws-amplify/ui-react";

const Login = () => {
  const { route } = useAuthenticator();

  const StyledBox = styled(Box)({
    fontSize: "18px",
    letterSpacing: "0.17px",
    color: "#ffffff"
  });

  const Item = (props: { children: React.ReactNode }) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box><img src={CheckMark}/></Box>
        <StyledBox>{props.children}</StyledBox>
      </Stack>
    )
  };

  // moved this to the login component (components/Login/index.tsx)
  /*useEffect(() => {
    console.debug("location in login view: ", location, { i, k, defId });
    // if there's a currently active session, go straight to home
    if (!isRedirecting) {
      setRedirecting(true);
      if (route === 'authenticated') {
        let queryParams = (i && k) ? `?i=${i}&k=${k}` : (defId ? `?defId=${defId}` : '')
        // if (!sess.authId) {
        //   navigate(`/register${queryParams}`);
        // } else
        if (queryParams) {
          console.debug('navigating to form from login view')
          navigate(`/form${queryParams}`);
        } else {
          console.debug('navigating to home from login view')
          navigate('/home');
        }
        setRedirecting(false);
      }
    }
  }, [])*/

  return (
    <Grid container height='100vh'>
      <Grid item md={7} xs={12} p={5} sx={{display: "flex", justifyContent: "center"}}>
        <LoginComponent/>
      </Grid>
      <Grid item md={5} xs={12} sx={{background: `#222faf URL(${slashOverlay}) no-repeat 58vw 100% fixed`, display: "flex", justifyContent: "center"}}>
        {route === 'signUp' ? (
        <Box mt={5} maxWidth={400} color="#ffffff" flexGrow={1}>
          <h2>Join thousands of non profits who are using the GivingHub to:</h2>
          <Stack spacing={4}>
            <Item>Complete Applications and Outcome Forms</Item>
            <Item>View and Track the Status of your Applications</Item>
            <Item>Track Payment Status and Amount</Item>
            <Item>Download Completed Documents</Item>
            <Item>Collaborate on application completion</Item>
            <Item>Learn best practices</Item>
            <Item>Communicate with the funder <Chip size="small" sx={{backgroundColor: "#fff1dd", color: "#805d2c"}} label="COMING SOON"/></Item>
          </Stack>
          <Box mt="90px" fontSize="16px">Not sure if the GivingHub is right for you?
            <Link color="#ffffff" underline="always" sx={{cursor: "pointer"}} onClick={(e) => {
              window.open("https://foundationsource.com/platform/non-profit-support/givinghub-applications-outcomes", "_blank");
            }}> Learn more.</Link>
          </Box>
        </Box>
        ) : ''}
      </Grid>
    </Grid>
  );
}

export default Login;