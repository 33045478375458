/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-west-2",
  "aws_cognito_region": "us-west-2",
  "aws_user_pools_id": "us-west-2_YVvvnJJao",
  "aws_user_pools_web_client_id": "5bc53bf1k352gun1ek5j8l0l1j",
  "oauth": {
    "domain": "givinghub.auth.us-west-2.amazoncognito.com",
    "scope": [
      "email",
      "openid",
      "phone"
    ],
    "redirectSignIn": "https://givinghub-api.foundationsource.com/login/oauth2/code/cognito",
    "redirectSignOut": "https://givinghub.foundationsource.com/login",//,https://online-staging.foundationsource.com/granteeportal/login,https://online.qa1.fs.local/granteeportal/login,https://online.release.fs.local/granteeportal/login",
    "responseType": "code"
    //        "redirectSignIn": "http://localhost:8083/login/oauth2/code/cognito,https://givinghub-api-staging.foundationsource.com/login/oauth2/code/cognito,https://granteeportal-qa1-lb.foundationsource.com/login/oauth2/code/cognito,https://granteeportal-release-lb.foundationsource.com/login/oauth2/code/cognito",
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": [
    "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL",
    "PHONE_NUMBER"
  ],
  "aws_cognito_mfa_configuration": "ON",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS"
    ]
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ]
};


export default awsmobile;