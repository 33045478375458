import React from 'react';
import { Typography, IconButton, styled, Grid, Link, Button, Container, Box } from '@mui/material';
import CreateAccountBg from '../../../assets/create_account_bg.png';
import BestPracticesBg from '../../../assets/best_practices_bg.png';
import ApplicationStartBg from '../../../assets/application_start_bg.png';
import CreateAccountIcon from '../../../util/icons/createAccountIcon';
import BestPracticesIcon from '../../../util/icons/bestPracticesIcon';
import ApplicationStartIcon from '../../../util/icons/ApplicationStartIcon';

const Tips = [
    {
        id: 'CreateAccount',
        image: CreateAccountBg,
        icon: CreateAccountIcon,
        description: 'How to create an account for shared use within an organization',
        link: 'https://givinghub.zendesk.com/hc/en-us/articles/20015628128283-How-to-create-an-account-for-shared-use-within-an-organization',
    },
    {
        id: 'BestPractices',
        image: BestPracticesBg,
        icon: BestPracticesIcon,
        description: 'GivingHub Best Practices',
        link: 'https://givinghub.zendesk.com/hc/en-us/articles/22883507125787-GivingHub-Best-Practices',
    },
    {
        id: 'ApplicationStart',
        image: ApplicationStartBg,
        icon: ApplicationStartIcon,
        description: 'How do I start an application',
        link: 'https://givinghub.zendesk.com/hc/en-us/articles/17261765536795-How-do-I-start-an-application',
    },
];

const ZoomCard = styled('div')({
    position: 'relative',
    width: '100%',
    height: 250,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'transform 0.3s',
    borderRadius: 8, // Adding border radius
    '&:hover': {
        transform: 'scale(1.1)',
    },
});

const IconBtn = styled(IconButton)({
    position: 'absolute',
    top: 25,
    left: 25,
    color: 'white',
});

const Content = styled('div')({
    position: 'absolute',
    bottom: 0,
    padding: '32px',
    color: 'white',
});

const HelpAndTips = () => {
    return (
        <Container>
            <Box sx={{ marginBottom: '25px' }}>
					<Typography variant='h3' fontWeight={600}> Help & Tips to get the most of GivingHub</Typography>
				</Box>
            <Grid container spacing={3}>
                {Tips.map((tip) => (
                    <Grid item xs={12} sm={6} md={4} key={tip.id}>
                        <Link href={tip.link} target="_blank" rel="noopener noreferrer">
                            <ZoomCard style={{ backgroundImage: `url(${tip.image})` }}>
                                <IconBtn>
                                    <tip.icon />
                                </IconBtn>
                                <Content>
                                    <Typography variant="body2">
                                        {tip.description}
                                    </Typography>
                                </Content>
                            </ZoomCard>
                        </Link>
                    </Grid>
                ))}
            </Grid>
            <Grid container justifyContent='center' sx={{ my: 5 }}>
                <Button variant='contained' href="https://givinghub.zendesk.com/hc/en-us" target="_blank" >View All Help Articles</Button>
            </Grid>
        </Container>
    );
};

export default HelpAndTips;
