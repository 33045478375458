import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import useQueryParams from "../../hooks/useQuery";
import { useNavigate } from 'react-router-dom';
import Session from "../../models/data/Session";
import { clearStoredSession } from "../../util/storage";

const Sso = () => {
	const { key, i, k, defId } = useQueryParams();
	const navigate = useNavigate();
	const { getSession } = useContext(AuthContext);
	const [isAuthenticated, setAuthenticated] = useState(false);
	const { search } = useLocation();

	const checkAuthenticated = async () => {
		let sess = await getSession();
		setAuthenticated(sess.id !== null)
		if (!sess.id) {
			clearStoredSession();
			navigate(`/login${search}`);
		}
	}

	useEffect(() => {
		(async () => {
			try {
				await checkAuthenticated();
				const sess: Session = await getSession();
				let queryParams = (i && k) ? `?i=${i}&k=${k}` : (defId ? `?defId=${defId}` : '')
				if (!sess.authId) {
					navigate(`/register${queryParams}`);
				} else if (queryParams !== '') {
					navigate(`/form${queryParams}`);
				} else {
					navigate('/home');
				}
			} catch (error) {
				console.log(error)
			}
		})()
	}, []);

	return <></>
};

Sso.propTypes = {};

Sso.defaultProps = {};

export default Sso;
