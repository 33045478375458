const loginTheme = {

  name: 'Auth Theme',
  tokens: {
    button: {
      _hover: false,
	    link: {
		    backgroundColor: { value: "#ff0" },
		    color: { value: "#0ff"},
				_hover: {
					backgroundColor: { value: "#f00" },
					color: { value: "#0f0"}
				}
	    }
    },
    colors: {
      brand: {
        primary: {
          '10': '#222FAF',
          '20': '#222FAF',
          '40': '#222FAF',
          '60': '#222FAF',
          '80': '#222FAF',
          '90': '#222FAF',
          '100': '#222FAF',
        },
      },
    },
  },
  components: {
	  button: {
		  _hover: false,
		  link: {
			  backgroundColor: {value: "#ff0"},
			  color: {value: "#0ff"},
			  _hover: {
				  backgroundColor: {value: "#f00"},
				  color: {value: "#0f0"}
			  }
		  }
	  },
    authenticator: {
      container: {
        width: {
          max: 1000
        }
      }
    }
  }
};

export default loginTheme;