import globals from '../globals';
import MuiButton from './MuiButton';
import MuiFormLabel from './MuiFormLabel';
/*
import MuiAlert from './MuiAlert';
import MuiChip from './MuiChip';
import MuiDialogTitle from './MuiDialogTitle';
import MuiDialogActions from './MuiDialogActions';
import MuiLink from './MuiLink';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiTooltip from './MuiTooltip';
*/

const components = {
    MuiCssBaseline: { ...globals },
		MuiButton,
		MuiFormLabel,
    /*MuiAlert,
    MuiChip,
    MuiDialogTitle,
    MuiDialogActions,
    MuiLink,
    MuiOutlinedInput,
    MuiTooltip*/
}

export default components;