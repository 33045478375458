import React, { FC, useState } from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box
} from "@mui/material";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from "@tanstack/react-table";
import ProgressSpinner from "../ProgressSpinner";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { StyledTableCell, StyledTableHead } from "./styled";
import NotFound from "../../views/Main/Applications/notFound";

interface TableProps {
  data: any[];
  columns: ColumnDef<any>[];
  size?: string,
  loading?: boolean
}

const Table: FC<TableProps> = ({ data, columns, loading, size = '100%' }) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const { getHeaderGroups, getRowModel } = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });
  return (
    <React.Fragment>
      {loading ? <Box sx={{height: '250px'}}><ProgressSpinner /> </Box>:
        <MuiTable sx={{ minWidth: size }}>
          <StyledTableHead>
            {getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell key={header.id}>
                    {header.isPlaceholder
                      ? null
                      :
                      (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <ArrowDropUpIcon sx={{ verticalAlign: 'bottom' }} />,
                            desc: <ArrowDropDownIcon sx={{ verticalAlign: 'bottom' }} />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </StyledTableHead>
          <TableBody>
            {
              
              data.length ?
              getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <StyledTableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </StyledTableCell>
                  ))}
                </TableRow>
              )):
              <TableRow>
                <TableCell colSpan={columns.length} sx={{border: "none"}}>
                  <NotFound />
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </MuiTable>
      }
    </React.Fragment>
  );
};

export default Table;