import useQueryParams from "../../../hooks/useQuery";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import config from "../../../config";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack} from "@mui/material";
import api from "../../../api/api";
import GenericLoading from "../../../components/GenericLoading";

const Form = () => {
	const { i, k, defId } = useQueryParams();
	const navigate = useNavigate();
	const [noAccessWarning, setNoAccessWarning] = useState(false);
	const [showPleaseWait, setShowPleaseWait] = useState(true);

	const createNewFormInstance = () => {
		let iFrame = document.getElementById("fsolformview") as HTMLIFrameElement;

		if (defId) {
			let link = `${config.rtlHost}/forms/view?defId=${defId}`;
			iFrame.src = link;
		} else if (i && k) {
			iFrame.src = `${config.rtlHost}/forms/view?i=${i}&k=${k}`;
		}
	}

	const addUserFormRelationship = async (instId:number) => {
		let data = { 'instance-id': instId };

		try {
			let res = await api.post('forminstances', data);
			if (res?.status >= 400) setNoAccessWarning(true);
		} catch (reason) {
			setNoAccessWarning(true);
		}
	}

	useEffect(() => {
		createNewFormInstance();

		// todo: this was copied from Ember and hastily tweaked to compile without error, but it does not work.
		const iframeListener = (event: MessageEvent) => {
			// todo: is this necessary if the server is using the X-Frame-Options SAMEORIGIN directive?
			// if (event.origin !== `${process.env.REACT_APP_RTL_HOST}`) return; // only accept events from fsol domain

			let message = event.data;
			if (typeof message === 'object') return;
				if (message.startsWith('instId=')) {
					let instanceId = message.replace('instId=', '');

					addUserFormRelationship(parseInt(instanceId));

				} else if (message.startsWith('submittedInstId=')) {
					let instanceId = message.replace('submittedInstId=', '');
					navigate({ pathname: '/confirmation', search: `?instId=${instanceId}` });

				} else if (message === 'canceled') {
					navigate('/applications');
				}
			// }
		}

		window.addEventListener('message', iframeListener, false);
		return () => {
			window.removeEventListener('message', iframeListener);
		}

	}, []);

	const handleNavToApplication = () => {
		navigate('/applications');
	}

	const handleNavToOutcomes = () => {
		navigate('/outcomes');
	}

	const handleNavToHome = () => {
		navigate('/home');
	}

	return (
		<>
			<div style={{position: 'relative'}}>
				{showPleaseWait ? (
					<GenericLoading/>
				) : (
					<></>
				)}
				<iframe id="fsolformview" onLoad={() => setShowPleaseWait(true)}></iframe>
			</div>
			<Dialog
				open={noAccessWarning}
				onClose={handleNavToHome}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"No Access"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						It looks like the form you're attempting to view belongs to another user. Please verify that your access link is correct, and try again.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleNavToApplication}>View My Applications</Button>
					<Button onClick={handleNavToOutcomes}>View My Outcomes</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default Form;