import {Box, Grid, Modal} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PersonIcon from '@mui/icons-material/Person';

const OrgTypeHelpDialog = () => {
  const Item = styled(Grid)(() => ({
    padding: "16px",
    fontSize: "14px",
    color: "#1f2733",
    border: "1px solid #00000010",
    borderLeft: "none",
    borderRight: "none"
  }));

  const HeadItem = styled(Item)(() => ({
    backgroundColor: "rgba(52, 85, 153, 0.1)",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "5px"
    }
  }));


  return (
    <Box>
      <h2 style={{marginTop: "5px"}}>When Should I Choose An Organization Or Myself?</h2>
      <Box mb={2}>
        <strong>An organization</strong> is a legal entity that is organized and operated for public or social benefit.
        Grant requests from an organization are spent to further their social benefit work. They receive tax-exempt status
        from the federal government. Registering as an organization allows you to include information such as your EIN
        number. In addition to including general organization information, specific employee contact information can be
        included as well. Organizations generally require many different staff members to assist in writing, managing,
        and reporting on the applications and outcomes forms. Creating an organization profile allows for multiple members
        of your organization to collaborate in the GivingHub application portal.
      </Box>
      <Box mb={2}>
        <strong>An individual applicant (Myself)</strong> is a single person or family requesting grant funds for personal
        use.  When applying as an individual you are not required to enter in an EIN number. All information entered will
        pertain just to you and you will be the key administrator of your GivingHub applicant account.
      </Box>
      <Grid container>
        <HeadItem item xs={6}>
          <AccountBalanceOutlinedIcon/> Organization
        </HeadItem>
        <HeadItem item xs={6}>
          <PersonIcon/> Myself
        </HeadItem>

        <Item item xs={6}>Has EIN Number</Item>
        <Item item xs={6}>No EIN Number</Item>

        <Item item xs={6} sx={{backgroundColor: "#edf0f1"}}>Grant requests for social benefit work</Item>
        <Item item xs={6} sx={{backgroundColor: "#edf0f1"}}>Grant requests for individual need</Item>

        <Item item xs={6}>General organization information including: Address, Phone Number, Email</Item>
        <Item item xs={6}>Only individual information including: Personal Address, Phone Number, Address and Email</Item>

        <Item item xs={6} sx={{backgroundColor: "#edf0f1"}}>Multiple contacts</Item>
        <Item item xs={6} sx={{backgroundColor: "#edf0f1"}}>Single User</Item>
      </Grid>

    </Box>
  )
}

export default OrgTypeHelpDialog;