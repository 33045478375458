import request from './request';
import response from './response';
import responseError from './responseError';

const interceptors = {
    request,
    response,
    responseError
}

export default interceptors;