import { styled } from '@mui/material/styles';
import { Card, Typography } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    boxShadow: 'none',
    borderRadius: '8px'
}));

export const StyledCardHeader = styled(Typography)({
    display: 'flex',
    fontWeight: '600',
    color: '#000',
    fontSize: '25px',
    alignItems: 'center'
});