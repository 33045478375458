import React from "react";
import api from '../../../../../api/api';
import { Button, Typography, Popover, Box } from '@mui/material'
import Table from "../../../../../components/Table";
import { grantColumns } from "./columns";
import ProgressSpinner from "../../../../../components/ProgressSpinner";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface IGrantStatusProps {
    children?: React.ReactNode;
    props?: any;
    onClick?: any;
    grantId?: any;
}

const GrantsCell: React.FC<IGrantStatusProps> = ({ onClick, grantId, children, ...props }) => {
    const [isLoading, setLoading] = React.useState<Boolean>(true);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [grants, setGrants] = React.useState<any[]>([]);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        fetchGrants(grantId);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const fetchGrants = async (requestId: number) => {
        try {
            setLoading(true);
            const { data } = await api.get(`applications/grant`, {
                params: {
                    'request-id': requestId
                },
                data: {}
            });
            setGrants(data);
        }
        catch (err) {
            console.log({ err });
        }
        finally {
			setLoading(false);
		}
    };

    return (
        <>
            <Button variant="text" onClick={handleClick} endIcon={open ? <ArrowDropUpIcon sx={{marginLeft: '0px'}} /> : <ArrowDropDownIcon sx={{marginLeft: '0px'}} />} {...props}>
                {children}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
            >
                <Box sx={{minWidth: '500px', minHeight: '75px', display: 'flex', alignItems: 'center'}}>
                {isLoading ? (<ProgressSpinner />) : (
                    grants.length > 0 ? (
                        <Table data={grants} columns={grantColumns} />
                    ) : (
                        <Typography sx={{ p: 2 }}>No grants found for this application.</Typography>
                    )
                )}
                </Box>
            </Popover>
        </>
    );
};

export default GrantsCell;
