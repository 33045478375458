import useQueryParams from "../../hooks/useQuery";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import config from "../../config";
import GenericLoading from "../../components/GenericLoading";

const Eq = () => {
	const { siteId } = useQueryParams();
	const navigate = useNavigate();
  const { foundationId, url } = useParams();
	const [showPleaseWait, setShowPleaseWait] = useState(true);

	let iframeSrc = `${config.rtlHost}/eligibility-quiz?siteId=${siteId}`;

	useEffect(() => {
		let iFrame = document.getElementById("fsolformview") as HTMLIFrameElement;
		iFrame.src = iframeSrc;

		// capture messages from fsol iframe
		const iframeListener = (event:MessageEvent) => {
			// todo: is this necessary if the server is using the X-Frame-Options SAMEORIGIN directive?
			// if (event.origin !== process.env.REACT_APP_RTL_HOST) return; // only accept events from fsol domain

			let message = event.data;
			if (typeof message === 'object') return;
			if (message === 'cancel') {
				navigate(`/${foundationId}/${url}`);

			} else if (message.startsWith('defId=')) {
				let defId = message.replace('defId=', '');
				navigate(`/login?defId=${defId}`);
			}
		}
		window.addEventListener('message', iframeListener, false);
	});

	return (
		<div style={{position: 'relative'}}>
			{showPleaseWait ? (
				<GenericLoading/>
			) : (
				<></>
			)}
			<iframe id="fsolformview" onLoad={() => setShowPleaseWait(true)}></iframe>
		</div>
	)
}

export default Eq;