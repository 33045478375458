import { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';

/*
  The response must be a proper Json API format for this transform.

  A single JsonAPI object looks like this
		{
		  data: {
		    id: 123456,
		    type: "model-name"
		    attributes: {
		      property-one: "xxxxx",
		      property-two: "yyyyy",
		    }
		  }
		}

  It will transform to
		{
		  id: 123456,
		  type: "model-name",
		  propertyOne: "xxxxxx",
		  propertyTwo: "yyyyyy"
	  }

	JsonAPI collection responses look like this:
	  {
		  data: [
		    {
			    id: 123456,
			    type: "model-name"
			    attributes: {
			      property-one: "xxxxx",
			      property-two: "yyyyy",
			    }
		    },{
			    id: 7890,
			    type: "model-name"
			    attributes: {
			      property-one: "aaaaa",
			      property-two: "bbbbb",
			    }
		    }
	    ]}
		}

  It will transform to
		[{
		  id: 123456,
		  type: "model-name",
		  propertyOne: "xxxxxx",
		  propertyTwo: "yyyyyy"
	  },{
		  id: 7890,
		  type: "model-name",
		  propertyOne: "aaaaaa",
		  propertyTwo: "bbbbbb"
	  }]

 */

const transformJsonAPI = (data:any) => {
	if (data.id && data.type) {
		let attributes = data.attributes || {};
		attributes = camelcaseKeys(attributes)

    // check for an id and type property inside the attributes, and rename them to attributesId and attributesType
    // since the primary id and type properties should take precedence.
    if (attributes.id) {
      attributes.attributesId = attributes.id;
      delete attributes.id;
    }
    if (attributes.type) {
      attributes.attributesType = attributes.type;
      delete attributes.type;
    }

		return {
			id: data.id,
			type: data.type,
			...attributes
		}
	} else {
		return data;
	}
}

const response = (response: AxiosResponse) => {
	if (response.data?.data && response.data?.data?.type && response.data?.data?.attributes) { // if the response doesn't have "data: { type, attributes }" at the top layer, it's not really json api.
		let { data } = response.data;
		if (Array.isArray(data)) {
			data = data.map((data) => transformJsonAPI(data))
		} else {
			data = transformJsonAPI(data);
		}
		response.data = data;
	}

	return response;
}

export default response;