import { ColumnDef } from "@tanstack/react-table";
import ActionCell from "./actionCell";
import { Tooltip, Typography } from "@mui/material";


type MemberIfo = {
  id: string
  type: string
  firstname: string
  lastname: string
  inviteDate: string
  email: string
  status: string
}

interface Props {
  fetchMembers: () => Promise<void>
  setOpenConfirmDialog: (status: boolean) => void
  setUserStatus: (status: string) => void
  setAddress: (email: string | undefined) => void
  username?: string | null
  setUser: (firstName: string | undefined) => void
}

const getColumns = (props: Props): ColumnDef<MemberIfo>[] => ([
  {
    id: "firstname",
    header: "First Name",
    cell: ({ row }) => (<Tooltip sx={{fontSize: '14px'}} title={row.original.firstname} arrow>
      <Typography noWrap maxWidth={225}>{row.original.firstname}</Typography>
    </Tooltip>
    )
  },
  {
    id: "Lastname",
    header: "Last Name",
    cell: ({ row }) => (<Tooltip sx={{fontSize: '14px'}} title={row.original.lastname} arrow>
      <Typography noWrap maxWidth={225}>{row.original.lastname}</Typography>
    </Tooltip>
    )

  },
  {
    id: "email",
    header: "Email",
    cell: ({ row }) => (<Tooltip sx={{fontSize: '14px'}} title={row.original.email} arrow>
      <Typography noWrap maxWidth={225}>{row.original.email}</Typography>
    </Tooltip>
    )

  },
  {
    id: "status",
    header: "Status",
    accessorFn: (originalRow) => originalRow.status

  },
  {
    id: "inviteDate",
    header: "Invite Date",
    accessorFn: (originalRow) => {
      if (!!originalRow.inviteDate) {

        if(originalRow.inviteDate == "N/A"){
          return originalRow.inviteDate;
        }

        let d = new Date(Date.parse(originalRow.inviteDate));
        return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`;
      }
    }

  },
  {
    id: "actions",
    header: "Actions",
    cell: ({ row }) => (<ActionCell address={row.original.id} status={row.original.status} onFetch={props.fetchMembers} setOpenConfirmDialog={props.setOpenConfirmDialog} setUserStatus={props.setUserStatus} setAddress={props.setAddress} username={props.username} setUser={props.setUser} user={row.original} />)
  }
])

export default getColumns;
