import React, {useContext, useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';
import {clearStoredSession} from "../util/storage";

const ProtectedRoute = (props: React.PropsWithChildren) => {
	const { getSession } = useContext(AuthContext);
	const navigate = useNavigate();
	const { search } = useLocation();

	const [isAuthenticated, setAuthenticated] = useState(false);

	const checkAuthenticated = async () => {
		let sess = await getSession();
		setAuthenticated(sess.id !== null)
		if (!sess.id) {
			clearStoredSession();
			navigate(`/login${search}`);
		}
	}

	useEffect(() => {
		checkAuthenticated();
	}, [])

	return <>{isAuthenticated ? props.children : null}</>;
}

export default ProtectedRoute