import { RouteObject } from 'react-router-dom';

// login pages
import Login from "./views/Login";
import Sso from "./views/Sso";
import Invite from "./views/Invite";
// primary pages
import Main from './views/Main';
import Home from './views/Main/Home';
import Applications from './views/Main/Applications';
import Outcomes from './views/Main/Outcomes';
import Profile from './views/Main/Profile';
import Register from './views/Register';
import AuthSandbox from "./views/Main/AuthSandbox";
// Site (welcome page) and form (application) pages
import Welcome from "./views/Welcome";
import Eq from "./views/Eq";
import Form from './views/Main/Form';
import Confirmation from './views/Main/Confirmation';
// other public pages
import Error from './views/Error';
import Sandbox from './views/Sandbox';


const routes: RouteObject[] = [
	{
		path: 'login',
		element: <Login/>
	},
	{
		path: 'sso',
		element: <Sso/>
	},
	{
		path: ':foundationId/:url',
		element: <Welcome/>
	},
	{
		path: ':foundationId/:url/eq',
		element: <Eq/>
	},
	{
		path: '/invite',
		element: <Invite/>
	},
	{
		element: <Main/>,
		children: [
			{
				path: '/home',
				element: <Home/>
			},
			{
				path: '/applications',
				element: <Applications/>
			},
			{
				path: '/outcomes',
				element: <Outcomes/>
			},
			{
				path: '/profile',
				element: <Profile/>
			},
			{
				path: '/form',
				element: <Form/>
			},
			{
				path: '/confirmation',
				element: <Confirmation/>
			},
			{
				path: '/authsandbox',
				element: <AuthSandbox/>
			}
		]
	},
  {
    path: '/sandbox',
    element: <Sandbox/>
  },
	{
		path: '/register',
		element: <Register/>
	},
	{
		path: '/error',
		element: <Error/>
	},
	{
		path: '*',
		element: <Error/>
	}
];

export default routes;
