import { ColumnDef, flexRender,  } from "@tanstack/react-table";
import ActionCell from "../Applications/incomplete/actionCell";
import ApplicationStatusCell from "./applicationStatusCell";
import { OutcomeStatuses } from "./outcomeStatuses";

export const columns: ColumnDef<any, any>[] = [
  {
    id: "foundationName",
    header: "Grantor",
    accessorFn: (originalRow:any) => originalRow.foundationName

  },
  {
    id: "formName",
    header: "Outcome Report Title",
    accessorFn: (originalRow:any) => originalRow.formName
  },
  {
    id: "requestId",
    header: "Outcome Id",
    accessorFn: (originalRow:any) => originalRow.requestId
  },
  {
    id: "paymentDate",
    header: "Payment Date",
    accessorFn: (originalRow:any) => originalRow.paymentDate
  },
  {
    id: "requestStatus",
    accessorKey: "requestStatus",
    header: "Outcome Report Status",
    cell: ({row}) => flexRender(<ApplicationStatusCell status={row.original.requestStatus}>{OutcomeStatuses[row.original.requestStatus]}</ApplicationStatusCell>, {row})
  },
  {
    id: "dueDate",
    header: "Due Date",
    accessorFn: (originalRow:any) => originalRow.dueDate
  },
  {
    header: "Actions",
    cell: ({row}) =>
	    row.original.requestStatus !== 13 ? flexRender(<ActionCell accessUrl={row.original.accessUrl}> Open Outcome </ActionCell>, {row}) : null
  }
];