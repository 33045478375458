import { Container, Typography } from '@mui/material';

const NotFound = () => {

    return (

        <Container sx={{height:"200px", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Typography sx={{display: 'block', fontSize: '20px'}}>No data to display</Typography>
        </Container>
    );
}

export default NotFound;
