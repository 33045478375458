import React from "react";
import {Typography} from '@mui/material'
import fileExclamation from "../../../../../assets/file-exclamation.svg";
import CheckIcon from "@mui/icons-material/Check";

interface IFormStatusProps {
  status: number
}

const FormStatusCell = (props: IFormStatusProps) => {
  return (
    <Typography sx={{display: 'flex', alignItems: 'center'}}>
      {props.status === 1 ? (
        <>
          <CheckIcon fontSize="small" sx={{ color: 'green', mr: 1 }} />
          Submitted
        </>
      ) : (
        <>
          <img src={fileExclamation} style={{height: '20px', marginRight: '8px'}}/>
          Incomplete
        </>
      )}
    </Typography>
  )
}

export default FormStatusCell;