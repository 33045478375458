import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import config from './config';
import {createTheme, ThemeProvider} from "@mui/material";
import theme from "./theme";
import { Amplify } from 'aws-amplify';
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  //<React.StrictMode>
  <Authenticator.Provider>
    <ThemeProvider theme={createTheme(theme)}>
      <BrowserRouter basename={config.baseUrl}>
        {/*<AuthProvider>*/}
          <App />
        {/*</AuthProvider>*/}
      </BrowserRouter>
    </ThemeProvider>
  </Authenticator.Provider>
  //</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
