import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import { AuthContext } from '../../providers/AuthProvider';
import Session from "../../models/data/Session";
import {useLocation} from "react-router";

const Error = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate('/login');
    }
  }, []);

	return <div>
		<h1>Error</h1>
		Invalid URL
		<br/>
		Try going to <Link to={'/login'}>/login</Link><br/>
	</div>
};

Error.propTypes = {};

Error.defaultProps = {};

export default Error;
