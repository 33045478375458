import React, { Fragment } from 'react';
import { Grid, Typography, Container } from "@mui/material";
import { Link as LinkRoute } from "react-router-dom";
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PageTitle from '../../../components/PageTitle';
import ApplicationsIcon from '../../../assets/home/view-apps.png'
import OutcomesIcon from '../../../assets/home/view-outcomes.png'
import { getStoredSession } from "../../../util/storage";
import Session from "../../../models/data/Session";

import HelpAndTips from './HelpandTips';

const Item = styled(LinkRoute)({
	paddingBottom: '35px',
	paddingTop: '35px',
	display: 'block',
	maxWidth: '100%',
	maxHeight: '100%',
	backgroundColor: '#e4e6f4',
	textAlign: 'center',
	borderRadius: '8px',
	boxShadow: "0 0 10px 0 rgba(0,0,0,.3)",
	transition: ".2s borderColor",
	border: "1px solid #e4e6f4",
	textDecoration: "none",
	'&:hover': {
		border: '1px solid #a7acdf;',
	}
});

const Home = () => {
	const session: Session = getStoredSession();

	return (
		<Fragment>
			<Container>
				<PageTitle>Hello, {session.firstName}.</PageTitle>
				<Grid container spacing={5} direction="row" justifyContent="center" alignItems="center" sx={{ paddingBottom: '72px' }}>
					<Grid item xs={12} md={6}>
						<Item to='/applications'>
							<img alt='View Applications' src={ApplicationsIcon} height="174px" />
							<Typography mt={2} display='flex' justifyContent="center" alignItems="center" variant='h5' fontWeight={600} color="#2e3b4d" fontSize='24px'>
								View Applications <ArrowForwardIcon fontSize="large" />
							</Typography>
						</Item>
					</Grid>
					<Grid item xs={12} md={6}>
						<Item to='/outcomes'>
							<img alt="View Outcomes" src={OutcomesIcon} height="174px" />
							<Typography mt={2} display='flex' justifyContent="center" alignItems="center" variant='h5' fontWeight={600} color="#2e3b4d" fontSize='24px'>
								View Outcomes <ArrowForwardIcon fontSize="large" />
							</Typography>
						</Item>
					</Grid>
				</Grid>
			</Container>
			<Container maxWidth={false} sx={{ backgroundColor: '#e4e6f4', paddingY: '50px' }}>
				<HelpAndTips />
			</Container>
		</Fragment>
	)
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
