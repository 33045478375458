import Session, { defaultSession } from '../models/data/Session';
import api from "../api/api";
import {Auth} from "aws-amplify";

export const getStoredSession = () => {
	const currentSession = sessionStorage.getItem('ghsession');
	if (currentSession) {
		const parsed = JSON.parse(currentSession);
		if (!!parsed.id) {
			return parsed;
		}
		return defaultSession;
	}

	return defaultSession;
};

export const getStoredSessionKey = () => {
  return sessionStorage.getItem('ghsession-id');
};

export const setStoredSession = (session: Session) => {
	sessionStorage.setItem('ghsession', JSON.stringify(session));
	sessionStorage.setItem('ghsession-id', session.id || 'invalid_session');
};

export const clearStoredSession = (preserveKey:boolean = false) => {
	if (preserveKey) {
		sessionStorage.removeItem('ghsession');
	} else {
		sessionStorage.clear();
	}
};

export const refreshSession = async (reload?: boolean) => {
  let sub;
  try {
    let cognitoSession = await Auth.currentSession();
    /*
    // @ts-ignore
    const now = Math.floor(new Date() / 1000) - cognitoSession.getClockDrift();

    console.debug("current cognito expiry: ", {
      accessToken: now - cognitoSession.getAccessToken().getExpiration(),
      idToken: now - cognitoSession.getIdToken().getExpiration()
    });
    */

    if (!cognitoSession.isValid()) {
      console.error("Session is not valid.  Signing out: ");
      await Auth.signOut();
    }
    let user = await Auth.currentAuthenticatedUser();
    sub = user.attributes.sub;
  } catch (reason) {
    sub = 'invalid';
  }

  if (reload) {
    clearStoredSession();
  }

  let storedSess = getStoredSession();
  if (storedSess.id !== sub) { // we want to make sure the session belongs to the current logged in user
    try {
      let loadSess = await api.get('session');
      let newSess = {
        id: sub,
        ...loadSess.data
      }
      setStoredSession(newSess);
      return newSess
    } catch (reason) {
      return defaultSession;
    }
  } else {
    return storedSess;
  }
}
