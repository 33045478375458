import ProgressSpinner from "./ProgressSpinner";
import {Stack} from "@mui/material";

const GenericLoading = () => (
	<Stack alignItems="center">
		<div style={{
			color: '#666',
			fontSize: '30px',
			fontWeight: 600,
			fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif',
			marginTop: '110px',
			marginBottom: '10px'
		}}>
			Please Wait...
		</div>
		<ProgressSpinner />
		<div style={{fontSize: '14px', marginTop: '5px'}}>Loading</div>
	</Stack>
)

export default GenericLoading;