import React from "react";
import {Box, Link} from "@mui/material";
import {CheckboxField, Text, useAuthenticator, useTheme, View} from "@aws-amplify/ui-react";
import Logo from "../../assets/gp-logo.png";
import SignUp from "./signUp";

export const loginComponents = {
  Header() {
    return (
      <Box textAlign="left" mb={2}>
        <img src={Logo} style={{maxHeight: 80}}/>
      </Box>
    );
  },
  Footer() {
    const {tokens} = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.white}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
  SignIn: {
    Header() {
      return <Box mx={5} lineHeight="40px">
        <h1>Welcome back. Sign in to view your applications and outcomes.</h1>
      </Box>
    },
    Footer() {
      const { toSignUp, toResetPassword } = useAuthenticator();
      return (
        <Box mx={4} mb={2} fontSize="14px" fontWeight={600}>
          <div style={{display: "inline-block", float: "right"}}>
            <Link onClick={toResetPassword} ml={1}>Forgot Password</Link></div>
        </Box>
      )
    }
  },
  SignUp: {
    Header() {
      const { toSignIn } = useAuthenticator()
      return (
        <Box mx={5} lineHeight="40px">
          <h1>Register for a free account to start your application</h1>
        </Box>
      )
    },
    Footer() {
      return <Box mx={5}>
        Need help signing in? <Link sx={{cursor: "pointer"}} onClick={(e) => {
        window.open("https://givinghub.zendesk.com", "_blank");
      }}>Visit the GivingHub Help Center</Link>
      </Box>
    },
    FormFields() {
      return <SignUp/>
    }
  },
  ConfirmSignUp: {
    Header() {
      return <h2 style={{fontFamily: "Inter, Helvetica", fontWeight: 600}}>Your code is on the way</h2>
    }
  },
  /*ResetPassword: {
    Header() {
      return <h2>Forgot your password?  Enter your email to reset it.</h2>
    }
  }*/
	ConfirmSignIn: {
		Footer() {
			const [checked, setChecked] = React.useState(false);
			return <div>
				<CheckboxField
					id="remember"
					name="remember"
					value="true"
					checked={checked}
					onChange={(e) => setChecked(e.target.checked)}
					label="Don't ask me again on this device."
				/>
				<div style={{fontSize: "12px", marginLeft: "25px"}}>(Not recommended for public or shared devices)</div>
			</div>
		}
	}
}

export default loginComponents;