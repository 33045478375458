import {useAuthenticator} from "@aws-amplify/ui-react";
import { Auth } from 'aws-amplify';
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";

const AuthSandbox = () => {
	const [devices, setDevices] = useState<string[]>([]);

	const remember = async () => {
		try {
			let response = await Auth.rememberDevice();
			_fetchDevices();
		} catch (err) {
			console.debug("Problem Remembering: ", err);
		}
	}

	const forget = async () => {
		try {
			let response = await Auth.forgetDevice();
			_fetchDevices();
		} catch (err) {
			console.debug("Problem Forgetting: ", err);
		}
	}

	const _fetchDevices = async () => {
		let devices = await Auth.fetchDevices();
		setDevices(devices.map((dev) => dev.name));
	}

	useEffect(() => {
		_fetchDevices();
	}, []);

	return (
		<>
			<Button onClick={remember}>Remember this device</Button>
			<Button onClick={forget}>Forget this device</Button>

			<ul>
				{devices.map((device) => (
					<li>{device}</li>
				))}
			</ul>
		</>
	)

}

export default AuthSandbox;