import { Box, Button, Card, Container, Stack } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import useQueryParams from "../../../hooks/useQuery";
import api from "../../../api/api";

interface IForm {
	type: 'application' | 'outcome',
	foundationName: string,
	formName: string,
	requestAmount: string,
	formDateSubmitted: string,
	paymentDate: string
}

const Confirmation = () => {
	const navigate = useNavigate();
	const { instId } = useQueryParams();

	const [form, setForm] = useState<IForm>()

	const fetchFormDetails = async (instId: string) => {
		let formDetails = await api.get(`forminstances/${instId}`);
		setForm({
			type: !!formDetails.data.paymentId ? 'outcome' : 'application',
			foundationName: formDetails.data.foundationName,
			formName: formDetails.data.formName,
			requestAmount: formDetails.data?.requestAmount,
			formDateSubmitted: formDetails.data?.formDateSubmitted,
			paymentDate: formDetails.data?.paymentDate
		} as IForm);
	}

	useEffect(() => {
		fetchFormDetails(instId);
	}, []);

	return (
		<Container>
			<Box textAlign="center" mt={4}>
				{form ? (
					<>
						<Box height={225} position="relative">
							<CircleIcon sx={{ fontSize: '225px', color: '#d2f2f0', position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
							<CheckRoundedIcon sx={{ fontSize: '150px', color: '#1dbfb3', position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
						</Box>
						<h1>Congratulations! Your {form.type} has been submitted.</h1>
						<Card sx={{ backgroundColor: '#e4e6f4', textAlign: 'left', p: 4 }}>
							{form.type === 'application' ? (
								<Stack spacing={3}>
									<Box><strong>Foundation:</strong> {form.foundationName}</Box>
									<Box><strong>Application Name:</strong> {form.formName}</Box>
									<Box><strong>Requested Amount:</strong> ${form.requestAmount}</Box>
									<Box><strong>Date Submitted:</strong> {form.formDateSubmitted}</Box>
								</Stack>
							) : (
								<Stack spacing={3}>
									<Box><strong>Foundation:</strong> {form.foundationName}</Box>
									<Box><strong>Outcome Report Name:</strong> {form.formName}</Box>
									<Box><strong>Payment Date:</strong> {form.paymentDate}</Box>
								</Stack>
							)}
						</Card>
						<Stack direction="row" spacing={2} justifyContent="center" m={4}>
							{form.type === 'application' ? (
								<Button variant="contained" onClick={() => navigate('/applications')}>View Applications</Button>
							) : (
								<Button variant="contained" onClick={() => navigate('/outcomes')}>View Outcomes</Button>
							)}
							<Button variant="contained" onClick={() => navigate('/home')}>Home</Button>
						</Stack>
					</>
				) : ''}
			</Box>
		</Container>
	)
}

export default Confirmation;