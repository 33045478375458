import {Link} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import {useNavigate} from "react-router-dom";

const OpenLink = (props: { id: number, accessUrl: string }) => {
  const navigate = useNavigate();

  return (
    <Link
      underline="none"
      component="button"
      variant="body2"
      onClick={() => {
        navigate({ pathname: '/form', search: props.accessUrl.substring(props.accessUrl.indexOf('?'))})
      }}
      sx={{display: 'flex', alignItems: 'center'}}
    >
      <EditIcon fontSize="small" sx={{mr:1}} />
      <span>Open Application</span>
    </Link>
  );
}

export default OpenLink;