import logo from '../../assets/gp-logo-light.png'
import { NavLink, useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home'
import DescriptionIcon from '@mui/icons-material/Description'
import TaskIcon from '@mui/icons-material/Task'
import AvatarInitials from "../AvatarInitials";
import React, { useState } from "react";
import {Button, Link, Popover} from "@mui/material";
import { clearStoredSession, getStoredSession } from "../../util/storage";
import { useAuthenticator } from "@aws-amplify/ui-react";
import HelpIcon from '@mui/icons-material/Help';

const Header = () => {
	const [dropdownOpen, setDropdownOpen] = useState<HTMLElement | null>(null);
	const session = getStoredSession();
	const navigate = useNavigate();
	const auth = useAuthenticator();

	const manageClickHandler = () => {
		setDropdownOpen(null);
		navigate('/profile');
	}

	const dropdownClickHandler = (event: React.MouseEvent<HTMLElement>) => {
		setDropdownOpen(event.currentTarget);
	};

	const dropdown = () => {
		const handleSignOut = () => {
			clearStoredSession(); // removes session from browser
			auth.signOut();
			// window.location.replace(config.apiHost + '/home'); // invalidates cognito session, which will also redirect back to login page.
		}

		return (
			<div className='profile-dropdown'>
				<strong><AvatarInitials showName={true} size={56} stacked={true} /></strong>
				<div style={{ opacity: .7 }}>{session?.username}</div>
				<Button variant='text' onClick={manageClickHandler}>Manage Account</Button>
				<Button variant='contained' color='secondary' onClick={handleSignOut}>Sign Out</Button>
			</div>
		)
	}

	return (
		<div className='header'>
			<img src={logo} alt={"Giving Hub"} style={{ height: 60 }} />
			<div className={'header-buttons'}>
				<NavLink to='/home'>
					<HomeIcon />
					<div>Home</div>
				</NavLink>
				<NavLink to='/applications'>
					<DescriptionIcon />
					<div>Applications</div>
				</NavLink>
				<NavLink to='/outcomes'>
					<TaskIcon />
					<div>Outcomes</div>
				</NavLink>
			</div>
			
			<div className={'header-profile'}>
				<Link href="https://givinghub.zendesk.com/hc/en-us" target="_blank" rel="noreferrer" display='inline-block' mr={3} sx={{verticalAlign: 'middle'}}>
					<HelpIcon sx={{color: '#fff'}} />
				</Link>

				<div className='profile-trigger' aria-describedby="trigger" onClick={dropdownClickHandler}>
					<AvatarInitials size={32} showName={true} />
				</div>

				<Popover
					open={!!dropdownOpen}
					id="trigger"
					anchorEl={dropdownOpen}
					onClose={() => setDropdownOpen(null)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
				>
					{dropdown()}
				</Popover>
			</div>
		</div>
	);
}
Header.propTypes = {};

Header.defaultProps = {};

export default Header;
