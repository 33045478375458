import { ColumnDef, flexRender } from "@tanstack/react-table";
import ApplicationStatusCell from "../submitted/applicationStatusCell";
import ActionCell from "./actionCell";
import {GrantReqStatuses} from "../grantRequestStatuses";

export const columns: ColumnDef<any, any>[] = [
  {
    id: "foundationName",
    header: "Grantor",
    accessorFn: (originalRow:any) => originalRow.foundationName
  },
  {
    id: "requestTitle",
    header: "Application Title",
    accessorFn: (originalRow:any) => originalRow.requestTitle
  },
  {
    id: "requestId",
    header: "Application Id",
    accessorFn: (originalRow:any) => originalRow.requestId
  },
  {
    id: "requestDate",
    header: "Date Started",
    accessorFn: (originalRow:any) => originalRow.requestDate
  },
	{
		id: "requestStatus",
		accessorKey: "requestStatus",
		header: "Application Status",
		cell: ({row}) => flexRender(<ApplicationStatusCell status={row.original.requestStatus}>{GrantReqStatuses[row.original.requestStatus]}</ApplicationStatusCell>, {row})
	},
  {
    header: "Actions",
    cell: ({row}) => flexRender(<ActionCell accessUrl={row.original.accessUrl}> Open Application </ActionCell>, {row})
  }
];