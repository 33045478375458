import React, { Fragment } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerIcon from '@mui/icons-material/Power';
import { Link } from '@mui/material'

type MemberIfo = {
    id: string
    type: string
    firstname: string
    lastname: string
    inviteDate: string
    email: string
    status: string
  }
interface Props {
    address: string
    status: string
    username?: string | null
    onFetch: () => Promise<void>
    setOpenConfirmDialog: (status: boolean) => void
    setUserStatus: (status: string) => void
    setAddress: (email: string | undefined) => void
    setUser: (firstName: string | undefined) => void
    user: MemberIfo
}

const ActionCell = (props: Props) => {
    const handleClick = () => {
        const name = `${props.user.firstname} ${props.user.lastname}`;
        props.setOpenConfirmDialog(true);
        props.setUserStatus(props.status);
        props.setAddress(props.user.email)
        props.setUser(name);
    }

    const renderStatusText = () => {
        switch (props.status) {
            case 'Active':
                return (
                    <Fragment>
                        <PowerOffIcon fontSize="small" sx={{ mr: 1 }} />
                        <span>Deactivate Member</span>
                    </Fragment>);
            case 'Invited':
                return (<Fragment><DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                    <span>Delete Member</span></Fragment>)
            case 'Inactive':
                return (<Fragment><PowerIcon fontSize="small" sx={{ mr: 1 }} />
                    <span>Reactivate Member</span></Fragment>)
            default:
                return '';

        }
    }

    return (
        <Fragment>
          {props.user.email !== props.username ? (
                <Link
                    underline="none"
                    component="button"
                    variant="body2"
                    onClick={() => handleClick()}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    {renderStatusText()}
                </Link>
          ) : ''}
        </Fragment>
    );
};

export default ActionCell;