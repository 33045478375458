// components/Login.js
import React, {useEffect, useState} from "react";

import '@aws-amplify/ui-react/styles.css';
import './overrides.scss';
import { I18n } from 'aws-amplify';

import { useNavigate, useLocation } from 'react-router';
import loginComponents from "./loginComponents";
import loginFormFields from "./loginFormFields";
import loginServices from "./loginServices";
import loginTheme from "./loginTheme";
import {
  Authenticator,
  ThemeProvider,
  useAuthenticator,
  View
} from "@aws-amplify/ui-react";

const Login = () => {
  const { route, toSignIn, toSignUp } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
	const [initialState, setInitialState] = useState<any>(() => window.localStorage.getItem('initialState') || 'signUp');
	const [goToInitialState, setGoToInitialState] = useState(true);

  I18n.putVocabulariesForLanguage('en', {
		'Your code is on the way. To log in, enter the code we emailed to': 'Your code is on the way. To sign in, enter the code we emailed to',
		'Your code is on the way. To log in, enter the code we texted to': 'Your code is on the way. To sign in, enter the code we texted to',
	  'Your code is on the way. To log in, enter the code we sent you': 'Your code is on the way. To sign in, enter the code we sent you',
	  'Confirm SMS Code': 'Confirm SMS Text Message Code'
    // 'Sign In': 'Sign In', // Tab header
    // 'Sign in': 'Sign In', // Button label
    // 'Sign in to your account': 'Sign in to your account',
    // 'Forgot your password?': 'Forgot your password?',
    // 'Create Account': 'Create Account', // Tab and button
  });

	// if the user is unauthenticated, the location.state.from is the route they attempted to go to.
  let from = location.state?.from?.pathname || '/home';
  let queryParams = location.state?.from?.search || '';

  useEffect(() => {
    if (route === 'authenticated') {
			window.localStorage.setItem('initialState', 'signIn') // next time they go to log in, start at sign in instead of sign up
	    setInitialState('signIn');
			console.debug({location})
      if (queryParams || location.search) {
        navigate(`/form${queryParams ? queryParams : location.search}`);  // if there are any query params, we assume they're going to an application or outcome form
      } else {
        // navigate(from + queryParams); // go to the route attempted before authentication.
	      navigate("/home"); // always go to home after authentication
      }
		} else if (route === 'signIn') {
			// if we've never authenticated, flip to sign up.
			if (goToInitialState) {
				setGoToInitialState(false);
				if (initialState === 'signIn') {
					toSignIn();
				} else {
					toSignUp();
				}
			}
    } else if (route === 'signOut') {
	    location.state = null; // wipe out location state, so when we reauth, we'll go back to home.
    }
  }, [route, navigate, from, queryParams]);

  return (
    <ThemeProvider theme={loginTheme}>
    <View className="auth-wrapper" style={{width: "610px"}}>
      <Authenticator components={loginComponents} formFields={loginFormFields} services={loginServices}></Authenticator>
    </View>
    </ThemeProvider>
  );
}

export default Login;