import { setLocale } from 'yup';

setLocale({
	mixed: {
		required: 'Entry is required',
	},
	string: {
		max: 'Entry cannot exceed ${max} characters',
	},
});

enum Environment {
	Headless = 'headless',
	Local = 'local',
	QA1 = 'qa1',
	QA2 = 'qa2',
	Staging = 'staging',
	Production = 'prod',
}

interface GivingHubConfig {
	apiHost: string;
	rtlHost: string;
	baseUrl: string;
	headlessReturnUrl: string;
	cognitoTaxIdField: string;
}

const DEFAULT = {
	baseUrl: '/',
	headlessReturnUrl: null,
	cognitoTaxIdField: 'custom:tax_id'
}

const env = (process.env.REACT_APP_ENV as Environment) ?? Environment.QA1;

const config: Record<Environment, Partial<GivingHubConfig>> = {
	[Environment.Local]: {
		apiHost: 'http://localhost:8083',
		rtlHost: 'http://localhost:4200',
	},
	[Environment.Headless]: {
    apiHost: 'https://granteeportal-qa1-lb.foundationsource.com',
		rtlHost: 'https://online.qa1.fs.local/myfs',
		// apiHost: 'http://localhost:8083',
		// rtlHost: 'http://localhost:4200',
		headlessReturnUrl: 'http://localhost:3000'
	},
	[Environment.QA1]: {
		apiHost: 'https://granteeportal-qa1-lb.foundationsource.com',
		rtlHost: 'https://online.qa1.fs.local/myfs',
	},
	[Environment.QA2]: {
		apiHost: 'https://givinghub-api-qa2-lb.foundationsource.com',
		rtlHost: 'https://online.qa2.fs.local/myfs',
	},
	[Environment.Staging]: {
		apiHost: 'https://givinghub-api-staging.foundationsource.com/',
		rtlHost: 'https://online-staging.foundationsource.com/myfs'
	},
	[Environment.Production]: {
		apiHost: 'https://givinghub-api.foundationsource.com/',
		rtlHost: 'https://online.foundationsource.com/myfs',
		cognitoTaxIdField: 'custom:tax_identifier'
	}
};

if (!config[env])
	throw Error(
		`An unrecognized environment string was provided to REACT_APP_ENV. The accepted environments are: ${Object.values(
			Environment
		).join(', ')}`
	);

export default {
	...DEFAULT,
	...config[env],
} as GivingHubConfig;
