const MuiButton = {
	defaultProps: {
		disableRipple: true,
	},
	styleOverrides: {
		root: (): any => ({
			borderRadius: 5,
			fontWeight: 600,
			textTransform: 'none',
			fontSize: '16px',
			height: '50px'
			// paddingTop: '15px',
			// paddingBottom: '15px',
		}),
		contained: {
			boxShadow: 'none',
			'&:hover': {
				boxShadow: 'none',
			},
		},
		outlined: {
			backgroundColor: '#fff',
			borderWidth: '2px',
			'&:hover': {
				borderWidth: '2px'
			}
		},
		containedError: {
			'&:hover': {
				backgroundColor: 'darkRed',
			},
		},
	},
};

export default MuiButton;
