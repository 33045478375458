import axios from 'axios';
import config from '../config';
import interceptors from './interceptors';
// import fsolConfig from 'fsol/config/environment';

const api = axios.create({
    baseURL: config.apiHost,
    headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
    },
    timeout: 60000
});

api.interceptors.request.use(interceptors.request);
api.interceptors.response.use(interceptors.response, interceptors.responseError);

export default api;