import React from "react";
import { Link } from '@mui/material'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useNavigate } from "react-router-dom";

interface IActionCellProps {
    children?: React.ReactNode;
    props?: any;
    onClick?: any;
    accessUrl: string;
}

const ActionCell: React.FC<IActionCellProps> = ({ onClick, children, accessUrl, ...props }) => {
  const navigate = useNavigate()

    return (
        <Link
            underline="none"
            component="button"
            variant="body2"
            onClick={() => {
                navigate({ pathname: '/form', search: `${accessUrl.substring(accessUrl.indexOf('?'))}`});
            }}
            sx={{display: 'flex', alignItems: 'center'}}
        >
            <ModeEditIcon fontSize="small" sx={{mr:1}} />
            <span>{children}</span>
        </Link>
    );
};

export default ActionCell;