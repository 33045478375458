import React from "react";
import { Typography } from '@mui/material'
import { renderStatusIcon } from "./grantStatuses";

interface IGrantStatusProps {
    children?: React.ReactNode;
    status: number
}

const GrantStatusCell: React.FC<IGrantStatusProps> = ({ children, status }) => {
    return (
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            {renderStatusIcon(status)}
            {children && children}
        </Typography>
    );
};

export default GrantStatusCell;