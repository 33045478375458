type Session = {
	id: string | null,
	username: string | null,
	phone: string | null,
	authId: number | null,
	userId: number | null,
	userRoleId: number | null,
	accountId: number | null,
	firstName: string | null,
	lastName: string | null,
	userRoleType: 1 | 2 | null,
	accountType: 1 | 2 | null
}

export const defaultSession: Session = {
	id: null,
	username: null,
	authId: null,
	accountId: null,
	accountType: null,
	phone: null,
	firstName: null,
	lastName: null,
	userId: null,
	userRoleId: null,
	userRoleType: null
}

export default Session;