import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import fileExclamation from "../../../../../assets/file-exclamation.svg";
import React from "react";

interface Statuses {
  [key: number]: string
}
export const GrantStatuses: Statuses = {
    1: 'Active',
    2: 'Draft',
    3: 'Grant Processing', // Pending Approval
    4: 'Grant Processing', // Pending Payment
    5: 'Grant Processing', // Pending Complete
    6: 'Pending Hold',
    7: 'Pending Verification', // Pending Rejected
    8: 'Pending Verification', // Pending Research
    10: 'Funds Sent', // Complete
    11: 'Declined', // Declined
    12: 'Declined', // Rejected
    13: 'Declined', // Canceled
    14: 'Error',
    20: 'On Hold',
    21: 'Grant Processing', // In Process
    // 86: 'Deleted'
}

export const renderStatusIcon = (status: number): React.ReactNode => {
  switch (status) {
    case 2:
      return <img src={fileExclamation} style={{height: '20px', marginRight: '8px'}}/>
    case 10:
      return <CheckIcon fontSize="small" sx={{ color: 'green', mr: 1 }} />;
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 21:
      return <HourglassTopIcon fontSize="small" sx={{ color: '#BD9048', mr: 1 }} />;
    case 11:
    case 12:
    case 13:
      return <CloseIcon fontSize="small" sx={{ color: '#BB413B', mr: 1 }} />;
    default:
      return;
  }
}