import { common } from '@mui/material/colors';

const palette = {
		primary: {
			main: '#222faf'
		},
		secondary: {
			main: '#d8dce0'
		},
		neutral: {
			main: '#d8dce0'
		}
};

export default palette;