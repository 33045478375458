const typography = {
	fontFamily: ['Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
	// MUI typography variants
	body1: {
		fontSize: '1em'
	},
	h1: {
		fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
		fontSize: '2.5rem',
		fontWeight: 600
	},
	h2: {
		fontSize: 32,
		fontWeight: 500
	},
	h3: {
		fontSize: 24,
		fontWeight: 500
	},
	h4: {
		fontSize: 20,
		fontWeight: 400
	},
	h5: {
		fontSize: 16,
		fontWeight: 400
	},
	h6: {
		fontSize: 14,
		fontWeight: 400
	},
	// custom typography variants
	caption1: { fontSize: 14 },
	caption2: { fontSize: 13 },
	subHeading: {
		fontSize: 18,
		fontWeight: 500
	},

}

export default typography;