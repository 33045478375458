import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Container } from '@mui/material';
import api from '../../../api/api';
import Table from '../../../components/Table';
import PageTitle from '../../../components/PageTitle';
import { columns } from './columns'
import TabPanel from '../Applications/tabPanel';

interface IntOutcome {
	id: number,
	requestStatus: number
}

interface IntTabs {
	label: string
	id: string
}

const outcomeTabs: Array<IntTabs> = [
	{
		label: 'Submitted Outcomes',
		id: 'submittedOutcomes'
	},
	{
		label: 'Incomplete Outcomes',
		id: 'incompleteOutcomes'
	}
]

const Applications = () => {
	const [isLoading, setLoading] = React.useState<boolean>(true);
	const [value, setValue] = useState<number>(0);

	const [submittedOutcomes, setSubmittedOutcomes] = useState<IntOutcome[]>([]);
	const [incompleteOutcomes, setIncompleteOutcomes] = useState<IntOutcome[]>([]);

	const fetchOutcomes = async () => {
		try {
			setLoading(true);
			const { data } = await api.get('outcomes');

			setSubmittedOutcomes(data.filter((outcome: IntOutcome) => outcome.requestStatus === 12));
			setIncompleteOutcomes(data.filter((outcome: IntOutcome) => outcome.requestStatus !== 12));
		}
		catch (err) {
			console.log({ err });
		}
		finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchOutcomes();
	}, []);

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Container>
			<PageTitle>Outcomes</PageTitle>
			<Tabs value={value} onChange={handleChange} aria-label="Outcomes" sx={{ borderBottom: 1, borderColor: 'divider' }}>
				{outcomeTabs.map((tab) => {
					return <Tab key={tab.id} label={tab.label} id={tab.id} sx={{ textTransform: 'none', fontSize: '16px' }} />
				})}
			</Tabs>
			<TabPanel value={value} index={0}>
				<Table data={submittedOutcomes} columns={columns} loading={isLoading} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Table data={incompleteOutcomes} columns={columns} loading={isLoading} />
			</TabPanel>
		</Container>
	)
}

export default Applications;