import React, { Fragment, useState } from 'react';
import logo from '../../assets/fs-slants.png'
import { Box, Container, Dialog, Divider, Grid, Link, Stack, styled } from "@mui/material";
import PrivacyStatement from "./PrivacyStatement";
import TermsOfUse from "./TermsOfUse";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "react-router-dom";

const Footer = () => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const { pathname } = useLocation();

  const backgroundColor = pathname === '/profile' ? "#edf0f1" : "#fff";

  const PrivacyModal = () => (
    <Dialog sx={{ p: 4 }}
      onClose={() => setShowPrivacy(false)}
      open={showPrivacy}
      fullWidth={true}
      maxWidth="lg"
    >
      <div style={{ position: 'sticky', top: 0, textAlign: 'right', padding: '10px 15px' }}>
        <StyledLink onClick={() => setShowPrivacy(false)}><CloseIcon /></StyledLink>
      </div>
      <Box p={5}>
        <PrivacyStatement />
      </Box>
    </Dialog>
  )

  const TermsModal = () => (
    <Dialog sx={{ p: 4 }}
      onClose={() => setShowTermsOfUse(false)}
      open={showTermsOfUse}
      fullWidth={true}
      maxWidth="lg"
    >
      <div style={{ position: 'sticky', top: 0, textAlign: 'right', padding: '10px 15px' }}>
        <StyledLink onClick={() => setShowTermsOfUse(false)}><CloseIcon /></StyledLink>
      </div>
      <Box p={5}>
        <TermsOfUse />
      </Box>
    </Dialog>
  )

  const StyledLink = styled(Link)(() => ({
    color: '#222faf',
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: 'pointer'
  }));

  return (
    <Fragment>
      <Container>
        <Divider sx={{ mb: 2 }} />
        <Grid display='flex'>
          <Grid item><img src={logo} style={{ height: 54 }} alt={'Foundation Source'} /></Grid>
          <Grid item>
            <Box sx={{ fontSize: '16px' }}>
              <StyledLink href="https://givinghub.zendesk.com/hc/en-us" target="_blank">For technical support issues, contact Foundation Source</StyledLink>
            </Box>
            <Box sx={{ fontSize: '12px', color: '#1f2733', paddingTop: '5px' }}>
              &copy; {new Date().getFullYear()} All Rights Reserved. Foundation Source Philanthropic Services Inc. Foundation Source is a
              registered trademark of Foundation Source Philanthropic Services Inc.&nbsp;&nbsp;
              <StyledLink onClick={() => setShowPrivacy(true)}>Privacy Statement</StyledLink>,&nbsp;
              <StyledLink onClick={() => setShowTermsOfUse(true)}>Terms of Use</StyledLink>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <PrivacyModal />
      <TermsModal />
    </Fragment>
  )
};

export default Footer;
