import React, { Fragment, useState, useEffect, useContext } from 'react'
import {Grid, Box, List, Alert, Snackbar, Tooltip, Card} from '@mui/material';
import api from '../../../api/api';
import PageTitle from "../../../components/PageTitle";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SecurityIcon from '@mui/icons-material/Security';
import ProgressSpinner from '../../../components/ProgressSpinner';
import InvitedMembers from './Members';
import UserInfo from './userInfo';
import { StyledCard, StyledCardHeader } from './styled';
import { IUserResponse } from './model';
import styled from "@emotion/styled";
import { clearStoredSession, refreshSession } from '../../../util/storage';
import { getStoredSession } from "../../../util/storage";

const StyledSnackbar = styled(Snackbar)({
	position: 'absolute',
	marginTop: '50px',
	width: '100%'
})

const Profile = () => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<IUserResponse>();
	const { userId } = getStoredSession();
	const [editField, setEditField] = useState<string | null>(null);
	const [alertMessage, setAlertMessage] = useState<string>('');

	const fetchUserAcountDetails = async () => {
		try {
			setLoading(true);
			const { data } = await api.get(`users/${userId}`, {
				data: {}
			});
			setData(data);
		}
		catch (err) {
			console.log({ err });
		}
		finally {
			setLoading(false);
		}
	};

	const updateUserDetails = async (value: string, fieldId: string) => {
		try {
			switch (fieldId) {
				case 'firstName':
					data!.contact.first = value;
					break;
				case 'lastName':
					data!.contact.last = value;
					break;
				case 'organization':
					data!.organization!.name = value;
					break;
				case 'taxId':
					data!.organization!['tax-id'] = value.replaceAll(/[^0-9]*/g, '');
					break;
				default:
					return data;
			}


			const response = await api.patch('users', data);
			if (response && response.status === 200) {
				await refreshSession(true);
				setAlertMessage('Your account information has been updated.');
			}
		}
		catch (err) {
			console.log({ err });
		}
	};

  const updatePhoneNumber = async (value: string) => {
    value = value.replaceAll(/[^0-9\\+]/g, '');
    if (!value.startsWith('+')) {
      value = `+1${value}`;
    }

    data!.contact.phones[0].number = value;
    try {
      const response= await api.patch('users/phone', {phoneNumber: value});
      if (response && response.status === 200) {
        await refreshSession(true);
	      setAlertMessage('Your account information has been updated.');
      } else {
        console.log("Problem occurred updating phone number. ", response);
      }
    } catch (err) {
      console.log("Could not update phone number. ", err);
    }
  };

	useEffect(() => {
		fetchUserAcountDetails();
	}, [])

	const handleClose = (event?: React.SyntheticEvent | Event) => {
		setAlertMessage('');
	};

	return (
		<Fragment>
			<StyledSnackbar autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!!alertMessage} onClose={handleClose}>
				<Alert
					severity="success"
					onClose={handleClose}
					sx={{ width: '100%', borderRadius: '0px' }}
				>
					{alertMessage}
				</Alert>
			</StyledSnackbar>
			<PageTitle>Manage Account </PageTitle>
			{isLoading ? <Box sx={{ height: '250px' }}><ProgressSpinner /> </Box> :
				<Fragment>
					<Grid container spacing={3}>
						<Grid item xs={12} md={data?.accountType === 1 ? 4 : 6}>
							<StyledCard>
								<StyledCardHeader>
									<AccountBoxIcon fontSize='large' sx={{ marginRight: '5px' }} />
									Personal Info
								</StyledCardHeader>
								<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
									<UserInfo label="First Name" fieldId='firstName' value={data?.contact.first!} divider={true} isEditable={true} required={true} editField={editField} setEditField={setEditField} handleSubmit={updateUserDetails} />
									<UserInfo label="Last Name" fieldId='lastName' value={data?.contact.last!} divider={true} isEditable={true} required={true} editField={editField} setEditField={setEditField} handleSubmit={updateUserDetails} />
									<UserInfo label="Email" fieldId='email' value={data?.contact.emails[0].address!} divider={true} editField={editField} />
									<UserInfo label="Phone Number" fieldId='phoneNumber' value={data?.contact.phones[0].number!} isEditable={true} required={true} editField={editField} setEditField={setEditField} handleSubmit={updatePhoneNumber}
									          tooltip={<Box color="#ffffff" fontSize="16px">If your country code is not +1 (US/CA), please include your country code with your phone number. For example,<br/><strong>+52 5512345678</strong></Box>}
									/>
								</List>
							</StyledCard>
						</Grid>
						{data?.accountType === 1 ? (
						<Grid item xs={12} md={4}>
							<StyledCard>
								<StyledCardHeader>
									<ApartmentIcon fontSize='large' sx={{ marginRight: '5px' }} />
									Organization Info
								</StyledCardHeader>
								<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
									<UserInfo label="Name" fieldId='organization' value={data?.organization ? data?.organization.name : 'n/a'} divider={true} isEditable={true} editField={editField} setEditField={setEditField} handleSubmit={updateUserDetails} />
									<UserInfo label="Tax ID/ EIN" fieldId='taxId' value={data?.organization ? data?.organization['tax-id'] : 'n/a'} maxLength={10} isEditable={true} editField={editField} setEditField={setEditField} handleSubmit={updateUserDetails} />
								</List>
							</StyledCard>
						</Grid>
							) : ''}
						<Grid item xs={12} md={data?.accountType === 1 ? 4 : 6}>
							<StyledCard>
								<StyledCardHeader>
									<SecurityIcon fontSize='large' sx={{ marginRight: '5px' }} />
									Security
								</StyledCardHeader>
								<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
									<UserInfo label="Password" fieldId='password' value="**********" editField={editField} />
								</List>
							</StyledCard>
						</Grid>
					</Grid>

					<StyledCard sx={{ mt: '50px' }}>
						<InvitedMembers setAlertMessage={setAlertMessage} />
					</StyledCard>
				</Fragment>
			}
		</Fragment>
	)
}

export default Profile;