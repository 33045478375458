const loginFormFields = {
  signIn: {
    username: {
      label: 'Email',
      placeholder: 'Enter your email address',
      isRequired: true,
      order: 1,
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password',
      isRequired: true
    }
  },
  /*resetPassword: {
    username: {
      label: 'Email',
      placeholder: 'Enter your email address'
    }
  }*/
}

export default loginFormFields;