import React, {createContext, useContext, useEffect, useState} from "react";
import useQueryParams from "../hooks/useQuery";
import {useNavigate} from "react-router-dom";
import api from "../api/api";
import Session, { defaultSession } from "../models/data/Session";
import {AxiosResponse} from "axios";
import { getStoredSession, getStoredSessionKey, setStoredSession, clearStoredSession} from "../util/storage";
import {Auth} from "aws-amplify";

interface AuthContextInterface {
	session: Session | null,
	getSession: () => Promise<Session>,
	sessionLogOut: () => void
}

export const AuthContext = createContext({} as AuthContextInterface);

const AuthProvider = (props: React.PropsWithChildren) => {
	const [session, setSession] = useState<Session | null>(defaultSession);
	const { key } = useQueryParams();
	const navigate = useNavigate();

	const authorize = async () => {
		// first see if there's a session in the session store.  If present, use it.
		let sess = await getStoredSession();
		if (sess) {
			await setSession(sess);
			return sess;
		} else {
			// Session is not present in store.  It needs to be reloaded.
			// See if a session ID is present in the query parameters (key)
			let sessionKey: string | null = key;
			if (!sessionKey) {
				// No key in URL... see if it's in session storage
				sessionKey = await getStoredSessionKey();
			} else {
				// remove the query string from the url
				// window.history.replaceState('heya', 'sup', document.location.origin + document.location.pathname);
			}

			// If we have a key, try to reauth the session from the server, and put it in the data store
			if (sessionKey) {
				try{
					// const response:AxiosResponse<Session> = await api.get('session', { headers: { key: sessionKey }});
					// let sess = response.data;

          let congitoSess = await Auth.currentSession();
          let { payload } = congitoSess.getIdToken();
          let sess: Session = {
            id: payload.sub,
            username: payload.email,
            phone: payload.phone,
            authId: null,
            userId: payload['custom:user_id'],
            userRoleId: null,
            accountId: null,
            firstName: payload.given_name,
            lastName: payload.family_name,
            userRoleType: 1,
            accountType: 1
          }

          if (sess) {
						setSession(sess);
						setStoredSession(sess);
						return sess;
					} else {
						clearStoredSession();
						navigate('/login');
					}
				} catch(error){
					clearStoredSession()
					navigate('/login');
				}
			}
		}

		// If we got here, there's no valid session to be found, which should be interpreted as "not authorized"
		return {} as Session;
	}

	const getSession = async () => {
		let sess = await authorize();
				return sess ? sess : defaultSession;
	}

	const sessionLogOut = async () => {
		// todo: send API request to log out from cognito.  I don't think the Ember version did this.
		clearStoredSession();
		navigate('/login');
	}

	return (
		<AuthContext.Provider value={{
			session,
			getSession,
			sessionLogOut
		}}>
			{props.children}
		</AuthContext.Provider>
	)
}

export default AuthProvider;