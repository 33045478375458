import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider } from "@mui/material";
import api from "../../../api/api";

type MemberIfo = {
  id: string
  type: string
  firstName: string
  lastName: string
  inviteDate: string
  email: string
  status: string
}
interface Props {
  confirmDialog: boolean
  status: string | undefined
  address: string | undefined
  username?: string | null
  setConfirmDialog: (status: boolean) => void
  onFetch: () => Promise<void>
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>
  user?: string

}

function ConfirmationDialog(props: Props) {
  const handleCancel = () => {
    props.setConfirmDialog(false);
  };

  const handleDelete = async () => {
    try {
      // let apiPrefix;
      let apiCall;
      const payload = { "address": props.address };
      if (props.status === 'Inactive') {
        // apiPrefix = `activate`
        apiCall = api.patch(`users/activate/${props.address}`);
      } else if (props.status === 'Active') {
        // apiPrefix = `activate`
        apiCall = api.patch(`users/deactivate/${props.address}`);
      } else { // Invited
        // apiPrefix = `delete`
        apiCall = api.delete(`users/invite/${props.address}`);
      }

      const response = await apiCall;
      if(response && response.status === 200){
        await props.onFetch();
        handleCancel();
        props.setAlertMessage(`${props.user} has been deleted.`);
      }
      
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {maxHeight: 435 } }}
      maxWidth="xs"
      open={props.confirmDialog}
    >
      <DialogTitle variant="h3">
        {props.status === 'Invited' && `Delete ${props.user}`}
        {props.status === 'Active' && `Deactivate ${props.user}`}
        {props.status === 'Inactive' && `Reactivate ${props.user}`}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {props.status === 'Invited' && <Typography>Deleting a member will no longer give them access to the GivingHub or any of the data associated with the organization. This cannot be undone.</Typography>}
        {props.status === 'Active' && <Typography>Deactivating a user will remove their access to the GivingHub.  Deactivated users may be Reactivated.</Typography>}
        {props.status === 'Inactive' && <Typography>Are you sure you want to reactivate {props.user}?</Typography>}
      </DialogContent>
      <DialogActions sx={{justifyContent: 'flex-start', px:3}}>
        <Button variant="contained" size="small" onClick={handleDelete} sx={{height: 'auto'}}>
          {props.status === 'Invited' && 'Delete Member'}
          {props.status === 'Active' && 'Deactivate Member'}
          {props.status === 'Inactive' && 'Reactivate Member'}
        </Button>
        <Button variant="outlined" size="small" onClick={handleCancel} sx={{height: 'auto'}}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;