import {Box, Container} from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {RequireAuth} from "../../RequireAuth";
import {useEffect, useState} from "react";
import {refreshSession, getStoredSession} from "../../util/storage";
import api from "../../api/api";

const Main = () => {
  const {pathname} = useLocation();
  const backgroundColor = pathname === '/profile' ? "#edf0f1" : "#fff";
	const paddingTop = pathname === '/form' ? '0' : '72px';

  const [busy, setBusy] = useState(true);

	const _initPendo = async () => {
		// @ts-ignore
		if (!window.pendo) {
			return;
		}

		let currentSession = getStoredSession();
		const { data } = await api.get(`users/${currentSession.userId}`, {
			data: {}
		});

		let opts = {
			visitor: {
				...currentSession,
				id: `GH-${currentSession.id}`,
				accountType: currentSession.accountType === 1 ? 'Organization' : 'Individual',
				userRoleType: currentSession.userRoleType === 1 ? 'Admin' : 'Standard'
			},
			account: {
				id: `GH-${currentSession.accountId}`,
				accountType: currentSession.accountType === 1 ? 'Organization' : 'Individual',
				organizationName: data!.organization!.name ? data.organization.name : 'n/a',
				EIN: data!.organization['tax-id'] ? data.organization['tax-id'] : 'n/a'
			}
		};

		// @ts-ignore
		if (window.pendo && window.pendo.getAccountId && window.pendo.getAccountId()) {
			// @ts-ignore
			window.pendo.identify(opts);
		} else {
			// @ts-ignore
			window.pendo.initialize(opts);
		}
	}

  useEffect(() => {
    refreshSession().then(() => {
	    _initPendo();
    }, (reason) => {
			console.log('Error refreshing session: ', reason);
    }).finally(() =>
	    setBusy(false)
    );
  }, [refreshSession]);

  return (
    <RequireAuth>
      {!busy ? (
      <>
        <div id='wormhole'></div>
        <Box className="wrapper" sx={{backgroundColor}}>
          <Header/>
          <Container className='content' maxWidth={false} sx={{paddingTop}} disableGutters={true}>
            <Outlet/>
          </Container>
          <Footer/>
        </Box>
      </>
      ) : ''}
    </RequireAuth>
  );
}

export default Main;
